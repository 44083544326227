import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import { SelectPhotoGalleryPopup } from '../../components/gallery-upload/SelectPhotoGalleryPopup'

import { usePhotoUploader } from './usePhotoUploader'

type UploadableMediaWidgetType = {
  onPhotoUploaded: Function
  children: Function
  disabled?: boolean
  useGallery?: boolean // allow selection from gallery photos
}

export const UploadableMediaWidget = (props: UploadableMediaWidgetType) => {
  // const [galleryVisible, setGalleryVisible] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const afterPhotoUploaded = (photo: any) => {
  }
  const { tmpPhoto, onPhotoSelected, reset } =
    usePhotoUploader(afterPhotoUploaded)

  const generateMediaId = () => {
    return uuidv4()
  }

  const handleDrop = async (event: any) => {
    setAnchorEl(undefined)
    if (event && event.length > 0) {
      const selectedPhoto = {
        ...event[0],
        file: event[0],
        sourceType: 'upload',
        id: generateMediaId()
      }
      const res = await onPhotoSelected(selectedPhoto, (progress: any) => {
      })
      if (res) {
        if (props.onPhotoUploaded) {
          var result = await props.onPhotoUploaded(res)
          if (result === true) {
            reset()
          }
        }
      }
    }
    event.shift()
    handleDrop(event) // we need to handle rest of photos
  }

  const tmpPhotoSrc = tmpPhoto ? URL.createObjectURL(tmpPhoto.file) : undefined
  if (props.useGallery) {
    return (
      <div className='w-full h-full'>
        <div
          onClick={event => setAnchorEl(event.currentTarget)}
          className='w-full h-full'
        >
          {props.children({ tmpPhoto, tmpPhotoSrc })}
        </div>
        <SelectPhotoGalleryPopup
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClosePopup={event => {
            setAnchorEl(undefined)
            event.preventDefault()
          }}
          onPhotoUploaded={handleDrop}
          // valueTabs={valueTabs}
          // changeTabs={ChangeTabs}
          // photoLiblary={photoLiblary}
        />
      </div>
    )
  }
  return (
    <Dropzone onDrop={async (acceptedFiles: any) => handleDrop(acceptedFiles)}>
      {({ getRootProps, getInputProps }: any) => {
        return (
          <div {...getRootProps()} style={{ width: '100%', height: '100%' }}>
            <input {...getInputProps()} />
            {props.children({ tmpPhoto, tmpPhotoSrc })}
          </div>
        )
      }}
    </Dropzone>
  )
}
