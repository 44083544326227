import { CircularProgress } from "@mui/material"
import { Box } from "@mui/system"
import Table from "../../../components/table/Table"
import { useProduct } from "../events/api/useProductApi"
import {
  WakacjeChip,
  WakacjeChipVariant
} from "../../../components/WakacjeChip"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { OptionButton } from "../places/OptionButton"
import { useCurrency } from "../../../hooks/useCurrency"
import { useProductUtils } from "../../../hooks/useProductUtils"
import {useTranslation} from "react-i18next";

const ProductTripsTable = (props: any) => {
  const { t } = useTranslation()
  const { data, loading, refetch } = useProduct(props.row?.row?.id)

  const { selectedCurrency } = useCurrency()
  const { formatPriceInCents } = useProductUtils()

  const { setPopupAddDate, setPopupEditData } = props

  const columns = [
    {
      header: t("ui.camps.term_name","Nazwa terminu"),
      cell: (row) => (
        <div>
          <div>{row.name}</div>
          <div>
            <WakacjeChip label={row.status} showStatus={true} />
          </div>
        </div>
      )
    },
    {
      header: t("ui.camps.dates", "Daty"),
      cell: (row) => (
        <div>
          {row.start_date}-{row.end_date}
        </div>
      )
    },
    {
      header: t("ui.camps.participants", "Uczestnicy"),
      cell: (row) => (
        <div>
          {row.stat_participants_count} / {row.available_spots}
        </div>
      )
    },
    {
      header: t("ui.camps.price_for_one_person", "Cena/ 1 os."),
      cell: (row) => (
        <div>
          {`${formatPriceInCents(
            row.full_price,
            row.currency
          )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`}
        </div>
      )
    },
    {
      header: t("ui.booking.incomes_sum", "Suma wpłat"),
      cell: (row) => (
        <div>
          {`${formatPriceInCents(
            row.stat_payments_total,
            row.currency
          )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`}
        </div>
      )
    },
    {
      header: "",
      cell: (row) => (
        <OptionButton
          row={row}
          handleDelete={() => {}}
          options={["edit", "remove"]}
          rowType="trip"
          setPopupEditData={setPopupEditData}
          setPopupAddDate={setPopupAddDate}
        />
      )
    }
  ]

  return (
    <Box width="100%">
      {loading && <CircularProgress />}
      {!loading && data && (
        <Table
          rows={data.trips}
          columns={columns}
          childrenTable
          rowLinkBuilder={(row: any) => `/wydarzenia/${row.id}`}
        />
      )}
    </Box>
  )
}
export const ProductsRowDetails = ({ row, ...props }: any) => {
  return <Box>{row && <ProductTripsTable row={row} {...props} />}</Box>
}
