import { DescriptionList, InputField } from "../../../components/FormCommons"
import { useIntl } from "react-intl"
import { useCompanyApi } from "../events/api/useCompanyApi"
import { EditableFormSection } from "../../../components/forms/EditableFormSection"
import { Box } from "@mui/system"
import { Checkbox } from "@mui/material"
import { FormControlLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import {useTranslation} from "react-i18next";

export const CompanySectionSuperAdminForm = ({
  register,
  control,
  watch
}: any) => {
  const { t } = useTranslation()
  const admin_approved_at = watch("admin_approved_at")

  return (
    <Box>
      <InputField
        {...register("fc_website_prefix")}
        label={t("superAdminBookings.fc_website_prefix", "Adres strony FC (fajnyczas.pl/nazwa-firmy)")}
        name="fc_website_prefix"
        id={`fc_website_prefix`}
        InputLabelProps={{ shrink: true }}
      />
      <InputField
        {...register("license_type")}
        label={t("packet", "Pakiet")}
        name="license_type"
        id={`license_type`}
        InputLabelProps={{ shrink: true }}
      />
      <InputField
        {...register("license_valid_till")}
        label={t("SuperAdmin.license_valid_until", "Licencja ważna do")}
        name="license_valid_till"
        id={`license_valid_till`}
        InputLabelProps={{ shrink: true }}
      />
      <div className="font-semibold mt-2">{t("superAdminBookings.company_type", "Typ firmy")}</div>
      <Controller
        name="company_type"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label={t("superAdminBookings.transport_company", "Firma transportowa")}
            labelPlacement="end"
            control={
              <Checkbox
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value ?? false}
              />
            }
          />
        )}
      />
      <InputField
        {...register("administrator")}
        InputLabelProps={{ shrink: true }}
        label={t("superAdminBookings.guardian", "Opiekun")}
        id={`administrator`}
      />
      <InputField
        {...register("admin_comment")}
        InputLabelProps={{ shrink: true }}
        label={t("superAdminBookings.admin_notes", "Uwagi Admin")}
        id={`admin_comment`}
        multiline
      />
      <InputField
        {...register("fee")}
        InputLabelProps={{ shrink: true }}
        label={`${t("Booking.commission", "Prowizja")} %`}
        id={`fee`}
        type="number"
        multiline
      />
      <InputField
        {...register("stripe_id")}
        InputLabelProps={{ shrink: true }}
        label="Stripe Id"
        id={`stripe_id`}
        multiline
      />
    </Box>
  )
}

export const CompanySectionSuperAdminPreview = ({ values }: any) => {
  const { t } = useTranslation()
  const intl = useIntl()

  return (
    <div>
      <DescriptionList
        data={{
          [t("superAdminBookings.fc_website_prefix", "Adres strony FC (fajnyczas.pl/nazwa-firmy)")]:
            values.fc_website_prefix,
          [t("packet", "Pakiet")]: values.license_type,
          [t("SuperAdmin.license_valid_until", "Licencja ważna do")]: values.license_valid_till,
          /* todo: backend for company_type and administrator */
          [t("superAdminBookings.company_type", "Typ firmy")]: values.company_type ? t("superAdminBookings.transport_company", "Firma transportowa") : "",
          [t("superAdminBookings.guardian", "Opiekun")]: values.administrator ?? "",
          [t("superAdminBookings.admin_notes", "Uwagi Admin")]: values.admin_comment,
          [t("Booking.commission", "Prowizja")]: `${values.fee} %`,
          "Stripe Id": values.stripe_id
          //   "Pokaż na stronie głównej": values.admin_show_on_home_page
          //     ? "Tak"
          //     : "Nie",
          //   "Promuj na stronie głównej": values.admin_promote_on_home_page
          //     ? "Tak"
          //     : "Nie",
        }}
      />
    </div>
  )
}

export const CompanySectionSuperAdminView = ({ values, refetch }: any) => {
  const { t } = useTranslation()

  const calculateFee = (values: any) => {
    return { ...values, fee: values?.fee ? values.fee / 100 : "-" }
  }

  const [val, setValue] = useState<any>(calculateFee(values))
  const { runCommand } = useCompanyApi()

  const update = (data: object) => {
    setValue((prevData) => ({ ...prevData, ...data }))
  }

  useEffect(() => {
    if (val.id !== values.id) {
      setValue(values)
    }
  }, [values])

  const saveHandler = async (data: any) => {
    const feeForBackend = data.fee ? Math.floor(data.fee * 100) : undefined

    var command: any = {
      type: "Company.AdminUpdate",
      aggregateId: values.id,
      payload: {
        ...data,
        fee: feeForBackend
      }
    }

    return runCommand(command)
  }
  const handleRefresh = () => {
    refetch()
  }
  //   const val

  return (
    <EditableFormSection
      title={t("ui.products.admin_options_company", "Opcje administratora (Firma)")}
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      styles={"shadow rounded-lg"}
      onUpdate={update}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <CompanySectionSuperAdminForm values={val} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <CompanySectionSuperAdminPreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
