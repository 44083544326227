/** @jsxImportSource @emotion/react */
import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import { Box } from "@mui/system"
import useAxios from "axios-hooks"
import { useEffect, useState } from "react"
import { useCompanyContext } from "../../contexts/company-context"
import Typo from "../typography"
import { MojeTextInput } from "../ui/MojeTextInput"
import axios from "axios"
import {useTranslation} from "react-i18next";

const SelectCompanyPopup = ({ isOpen, onClose }: any) => {
  const { t } = useTranslation()

  var url = process.env.REACT_APP_WAKACJE_API_URL + "/v1/companies?limit=1000"
  const [{ data }] = useAxios(url, { useCache: false })
  const [companyData, setCompanyData] = useState(null)
  const [searchText, setSearchText] = useState("")
  const companyContext = useCompanyContext()

  const handleSelectCompany = (company: any) => {
    //here we make sure the company data is updated each time the user chooses different company from the list
    setCompanyData(company)
    // companyContext.setCompany(company)
    onClose()
  }

  useEffect(() => {
    if (companyData?.id && data?.data?.length > 0) {
      const newCompany = data.data.find(el => el.id === companyData.id)
      if (newCompany) {
        companyContext.setCompany(newCompany)
      }

      // const companyUrl = `${process.env.REACT_APP_WAKACJE_API_URL}/v1/companies/${companyData.id}`

      // const fetchCompany = async () => {
      //   var resultCompany = await axios({
      //     method: "GET",
      //     url: companyUrl
      //   })

      // }

      // fetchCompany()
    }

  }, [companyData])
  var companies = data?.data ?? []
  if (searchText && searchText.length > 0) {
    companies = companies.filter((c) =>
      c.name
        ? c.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
        : true
    )
  }
  companies.sort((a, b) => a?.name?.localeCompare(b.name))

  // const setSearchTextDebounced = useCallback(
  //   debounce(async (name) => {
  //     setSearchText(name)
  //   }),
  //   []
  // )

  return (
    <Dialog maxWidth="lg" open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typo.Title32Bold>{t("super_admin_select_company.select_company", "Wybierz firmę")}</Typo.Title32Bold>
      </DialogTitle>
      <DialogContent>
        <div className="w-96 flex flex-col h-[400px]">
          <Box>{t("super_admin_select_company.select_company", "Wybierz firmę")}</Box>
          <div className="w-full mx-4">
            <MojeTextInput
              margin="none"
              onChange={(ev) => setSearchText(ev.target.value)}
            />
          </div>

          <Box flex={1} overflow="auto">
            {companies.map((row: any, index) => (
              <div
                key={index}
                onClick={(ev: any) => handleSelectCompany(row)}
                className="hover:bg-slate-100 cursor-pointer py-1"
                title={"Id:" + row.id}
              >
                {row.name}
              </div>
            ))}
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export const SuperAdminSelectCompany = () => {
  const [isPopup, setPopup] = useState(false)

  const companyContext = useCompanyContext()
  return (
    <>
      <div className="cursor-pointer" onClick={(event: any) => setPopup(true)}>
        <div>{companyContext.company?.name ?? "Admin - zmien firme"}</div>
      </div>
      <SelectCompanyPopup isOpen={isPopup} onClose={() => setPopup(false)} />
    </>
  )
}
