import { LinearProgress, Typography } from "@mui/material";
import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useReducer,
} from "react";
import Table, { TableProps } from "./Table";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { groupBy } from "lodash";
import { useTranslation } from "react-i18next";

export type ApiTableProps = TableProps & {
  listApi?: Function;
  data?: any;
  showExpandColumn?: boolean;
  queryParams?: any;
  nothingFoundComponent?: any;
  rowDetailsRenderer?: any;
  usePagination?: boolean;
  columns: Array<any>;
  pageSize?: number;
  summaryComponent?: any;
  initialSort?: any;
  selectedRow?: any;
  sortAlphabetically?: boolean;
  searchValue?: string;
};

export type ApiTableRefType = {
  refetch: Function;
};

// todo ->  implement other options for sorting
function compareDates(a, b, key = "desc") {
  const dateA = new Date(a.created_at);
  const dateB = new Date(b.created_at);
  if (dateA < dateB) {
    return key === "desc" ? 1 : -1;
  }
  if (dateA > dateB) {
    return key === "desc" ? -1 : 1;
  }
  return 0;
}

const ACTIONS = {
  SET_PER_PAGE: "SET_PER_PAGE",
  SET_PAGE: "SET_PAGE",
  SET_SORT: "SET_SORT",
  SET_QUERY_PARAMS: "SET_QUERY_PARAMS",
};
const reducer = (state, action) => {
  //
  switch (action.type) {
    case ACTIONS.SET_PER_PAGE:
      return { ...state, perPage: action.perPage, page: 0 };
    case ACTIONS.SET_PAGE:
      return { ...state, page: action.page };
    case ACTIONS.SET_SORT:
      return { ...state, page: 0, sort: action.sort };
    case ACTIONS.SET_QUERY_PARAMS:
      return {
        ...state,
        page: 0,
        sort: action.sort ?? state.sort,
        queryParams: action.queryParams,
      };
    default:
      return state;
  }
};

const StatusOrderList = ["ACTIVE", "DRAFT", "ARCHIVED", "SUSPENDED"];

export const ApiTable = forwardRef<ApiTableRefType, ApiTableProps>(
  (pprops: ApiTableProps, ref) => {
    const {
      columns,
      listApi,
      data,
      queryParams,
      nothingFoundComponent,
      usePagination,
      initialSort,
      selectedRow,
      sortAlphabetically,
      ...props
    } = pprops;
    const [lastResult, setLastResult] = useState<any>();
    const [loading, setLoading] = useState(false);
    // const [page, setPage] = useState(0)
    // const [perPage, setPerPage] = useState(pprops.pageSize ?? 50)
    const totalPages = lastResult?.page_info?.total_pages;

    let rows = data ? data : lastResult?.data;
    //

    // if (sortAlphabetically && rows && Array.isArray(rows)) {
    //   const rowsGroup = groupBy(rows, "status")
    //   for (const status in rowsGroup) {
    //     rowsGroup[status].sort((a, b) =>
    //       a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    //     )
    //   }
    //   rows = []
    //   for (const key of StatusOrderList) {
    //     const items = rowsGroup[key] ?? []
    //     rows.push(...items)
    //     delete rowsGroup[key]
    //   }
    //   for (const key in rowsGroup) {
    //     const items = rowsGroup[key] ?? []
    //     rows.push(...items)
    //   }
    // }

    //
    const [state, dispatch] = useReducer(reducer, {
      page: 0,
      perPage: 100,
      sort: initialSort,
    });

    //
    //

    useEffect(() => {
      //
      loadPage();
    }, [state]);

    useEffect(() => {
      //
      dispatch({ type: ACTIONS.SET_QUERY_PARAMS, queryParams });
    }, [queryParams]);

    const loadPage = useCallback(async () => {
      //

      if (!listApi) {
        return;
      }

      setLoading(true);
      try {
        const _queryParams = {
          ...queryParams,
          limit: state.perPage,
        };
        if (state.page > 0) {
          _queryParams.after = state.page * state.perPage;
        }
        if (state.sort) {
          _queryParams.sort_by = state.sort.field;
          _queryParams.sort_direction = state.sort.direction;
        }
        //
        if (!listApi) {
          console.error("No listApi!", columns);
          return;
        }

        let result = await listApi!(_queryParams);
        //

        // if (
        //   result &&
        //   result.data &&
        //   result.data.every(el => el.hasOwnProperty('created_at')) &&
        //   (state.sort?.field === 'created_at' || state.sort === undefined)
        // ) {
        //   result.data = result.data.sort(compareDates)
        // }

        // if (
        //   result &&
        //   result.data &&
        //   pprops.searchValue &&
        //   pprops.searchValue.length > 0
        // ) {
        //   result.data = result.data.filter(
        //     el =>
        //       el.name
        //         ?.toLowerCase()
        //         .includes(pprops.searchValue.toLowerCase()) ||
        //       el.company?.name
        //         ?.toLowerCase()
        //         .includes(pprops.searchValue.toLowerCase()) ||
        //       el.admin_comment
        //         ?.toLowerCase()
        //         .includes(pprops.searchValue.toLowerCase())
        //   )
        // }

        //

        if (result.page_info) {
          setLastResult(result);
        } else {
          setLastResult({ data: result });
        }
        setLoading(false);
      } catch (err) {
        console.error("Erro ", err);
        setLoading(false);
        setLastResult({ data: [] });
      }
    }, [state]);

    useEffect(() => {
      if (listApi) {
        loadPage();
      }
    }, []);

    useImperativeHandle(ref, () => ({
      refetch: () => {
        //
        loadPage();
      },
    }));

    const handleHeaderClick = (column) => {
      //
      if (column.field) {
        var newSort = { field: column.field, direction: "asc" };
        if (state.sort && state.sort?.field === column.field) {
          newSort = {
            field: column.field,
            direction: state.sort.direction === "asc" ? "desc" : "asc",
          };
        }
        dispatch({ type: ACTIONS.SET_SORT, sort: newSort });
      }
    };

    //  Only for Places table
    // Bank - that breaks list for other products - what's the idea. API does filter
    // let filteredRows =
    //   rows &&
    //   rows.filter(function (el) {
    //     return el.type === queryParams?.type
    //   })
    const { t } = useTranslation();
    return (
      <section>
        {loading && (
          <div className="py-4">
            <LinearProgress />
          </div>
        )}

        <div className="flex items-center justify-between">
          <div>
            {props.summaryComponent &&
              props.summaryComponent({ result: lastResult })}
          </div>
        </div>

        <Table
          columns={columns}
          {...props}
          rows={rows}
          onHeaderClicked={handleHeaderClick}
          sort={state.sort}
          selectedRow={selectedRow}
        />

        {rows?.length === 0 && <div>{nothingFoundComponent}</div>}

        <div className="flex justify-between">
          <div className="flex items-center">
            <Typography
              component="label"
              id="table-page-select-label"
              fontSize="body2.fontSize"
              color="grey.500"
            >
              {t("other.show", "Pokaż po")}
            </Typography>
            <Select
              className="ml-4"
              value={state.perPage}
              inputProps={{
                "aria-labelledby": "table-page-select-label",
              }}
              onChange={(e) =>
                dispatch({
                  type: ACTIONS.SET_PER_PAGE,
                  perPage: Number(e.target.value),
                })
              }
            >
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </div>
          <nav className="flex items-center flex-1 min-w-0 gap-4">
            <ul className="flex items-center max-w-full flex-1">
              {Array.from(Array(totalPages).keys())
                .slice(0, Math.min(10, totalPages))
                .map((pageNo) => (
                  <li key={pageNo}>
                    <Button
                      className="rounded-full p-2 min-w-0 w-12 h-12"
                      onClick={() =>
                        dispatch({ type: ACTIONS.SET_PAGE, page: pageNo })
                      }
                      sx={{
                        fontWeight: state.page === pageNo ? "bold" : null,
                      }}
                      color={
                        state.page === pageNo ? "menteAccentDark" : "inherit"
                      }
                    >
                      {pageNo + 1}
                    </Button>
                  </li>
                ))}
            </ul>
            <IconButton
              aria-label="previous"
              disabled={state.page === 0}
              sx={{ color: "#111111" }}
              onClick={() => {
                if (state.page > 0) {
                  dispatch({ type: ACTIONS.SET_PAGE, page: state.page - 1 });
                }
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              aria-label="next"
              disabled={state.page === totalPages - 1}
              sx={{ color: "#111111" }}
              onClick={() => {
                if (state.page < totalPages - 1) {
                  dispatch({ type: ACTIONS.SET_PAGE, page: state.page + 1 });
                }
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </nav>
        </div>
      </section>
    );
  }
);
