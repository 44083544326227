import { Box } from "@mui/system"

import { Routes, Route } from "react-router-dom"
import Toolbar from "../Toolbar/Toolbar"
import { useSearchText } from "../useSearchText"
import { SuperAdminPlacesTable } from "../places/SuperAdminPlacesTable"

export const SuperAdminAccommodations = () => {
  //   const { listTrips } = useProductApi()
  const listAccommodations: any = []
  const { setSearchTextDebounced, queryParams } = useSearchText()

  return (
    <div className="mt-10">
      <Box width="100%">
        <Toolbar
          setSearchTextDebounced={setSearchTextDebounced}
          api={listAccommodations}
        />
        <Routes>
          <Route
            index
            element={
              <SuperAdminPlacesTable
                queryParams={queryParams}
                type="accommodation"
              />
            }
          />
        </Routes>
      </Box>
    </div>
  )
}
