import { Button, CircularProgress } from "@mui/material"
import { Box } from "@mui/system"
import { format } from "date-fns"
import { useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { BlueBackground } from "wakacje-web/src/shared/components/BlueBackground"
import { Heading3 } from "../../../components/FormCommons"
import { UidUtils } from "../../../utils/uid-utils"
import { useProductApi } from "../../organizer/events/api/useProductApi"
import { TripForm } from "./TripForm"
import { useNavigate } from "react-router-dom"
import { getTripSchemaForProductType } from "../../../components/TripDialogs/tripDataSchema"

export const AddTripForm = ({
  product,
  onSaved,
  onSubmit,
  index,
  values
}: {
  product: any
  onSaved?: any
  onSubmit?: any
  index?: number
  values?: any
}) => {
  // let history = useHistory()
  // build schema depending on product type
  const productType = product?.type ? product?.type : product?.product_type
  const schema = getTripSchemaForProductType(productType)
  // const yupValidator = useCallback((data) => yupResolver(schema)(data), [schema])
  const resolver = useCallback(
    async (data) => {
      var res: any
      try {
        const values = await schema.validate(data, {
          abortEarly: false
        })

        res = {
          values,
          errors: {}
        }
      } catch (errors) {
        res = {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        }
      }
      if (onSubmit) {
        onSubmit(data, res.errors)
      }
      return res
    },
    [schema]
  )
  const formMethods = useForm<any>({
    mode: "onBlur",
    resolver: resolver,
    // () => {
    //   return (data, v, options) => {
    //
    //     const validator = yupResolver(schema)
    //     const valid = validator(data, v, options)
    //     if (onSubmit) {
    //       onSubmit(data, valid)
    //     }
    //     return valid
    //   }
    // },
    defaultValues: {
      id: values?.id ?? UidUtils.generateUid("trip_"),
      product_id: product.id,
      product_type: product.type
    }
  })
  const {
    handleSubmit,
    formState: { errors, isValid }
  } = formMethods

  const { t } = useTranslation()
  let navigate = useNavigate()

  const [isSaving, setIsSaving] = useState(false)
  const { createTrip } = useProductApi()
  // Keep those registers so that we get the value back
  formMethods.register("id")
  formMethods.register("product_id")
  formMethods.register("product_type")

  const handleSave = async (trip) => {
    setIsSaving(true)
    try {
      const res = await createTrip(product.id, trip)
      setIsSaving(false)
      if (res.success) {
        onSaved(trip)
      }
    } catch (err) {
      setIsSaving(false)
    }
  }
  async function onFormSubmit(data) {
    var isValid = await formMethods.trigger()
    if (typeof data.start_date === "object") {
      data.start_date = format(data.start_date, "yyyy-MM-dd")
    }
    if (typeof data.end_date === "object") {
      data.end_date = format(data.end_date, "yyyy-MM-dd")
    }
    if (isValid) {
      if (onSubmit) {
        onSubmit(data)
      } else {
        handleSave(data)
      }
    }
  }
  return (
    <Box>
      <div className="mb-[24px]">
        <BlueBackground>
          <Heading3>Dodaj nowy termin</Heading3>
        </BlueBackground>
      </div>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <TripForm
            index={index ?? 0}
            productType={product.type}
            productCurrency={product?.currency}
          />

          <div className="flex flex-row mb-8 mt-6">
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1)
              }}
            >
              {t("cancel", "Anuluj")}
            </Button>
            <Box flex={1} />
            <Button
              color="primary"
              variant="contained"
              type={onSubmit ? "button" : "submit"}
              disabled={isSaving || !isValid}
            >
              {isSaving ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                t("save", "Zapisz")
              )}
            </Button>
          </div>
        </form>
      </FormProvider>
      {/* {JSON.stringify(trip)} */}
    </Box>
  )
}
