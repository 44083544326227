// src/contexts/CurrencyContext.js
import React, { createContext, useState, useEffect } from 'react'

const CURRENCY_LIST = [
  { currency: 'PLN', name: 'Polish Zloty', symbol: 'ZŁ' },
  { currency: 'USD', name: 'US Dollar', symbol: '$' },
  { currency: 'EUR', name: 'Euro', symbol: '€' },
  { currency: 'GBP', name: 'Pound Sterling', symbol: '£' }
  // Add more currencies as needed
]

export const CurrencyContext = createContext(null)

export const CurrencyProvider = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_LIST[0])

  // Load the saved currency from localStorage on component mount
  useEffect(() => {
    const savedCurrency = JSON.parse(localStorage.getItem('selectedCurrency'))
    if (savedCurrency) {
      setSelectedCurrency(savedCurrency)
    }
  }, [])

  // Save the selected currency to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedCurrency', JSON.stringify(selectedCurrency))
  }, [selectedCurrency])

  const changeCurrency = currency => {
    setSelectedCurrency(currency)
  }

  const getCurrencySymbol = (currency) => {
    const currencyData = CURRENCY_LIST.find(item => item.currency === currency)
    return currencyData?.symbol.toLowerCase() ?? "zł"
  }

  return (
    <CurrencyContext.Provider
      value={{ selectedCurrency, changeCurrency, CURRENCY_LIST, getCurrencySymbol }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}
