import { CampFeesDiscount } from './CampFeesDiscount'
import { CampFeesBase } from './CampFeesBase'
import { CampFeesFirstMinute } from './CampFeesFirstMinute'
import { CampFeesLastMinute } from './CampFeesLastMinute'
import { CampFeesCurrency } from './CampFeesCurrency'

export const CampFees = ({ control, currency }) => {
  return (
    <>
      <CampFeesBase control={control} currency={currency} />

      <CampFeesFirstMinute control={control} currency={currency} />

      <CampFeesLastMinute control={control} currency={currency} />

      <CampFeesDiscount control={control} currency={currency} />

      <CampFeesCurrency control={control} />
    </>
  )
}
