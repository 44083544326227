import { Link } from "react-router-dom"
import { Button } from "@mui/material"
import { useCompanyContext } from "../../../contexts/company-context"
import { formatDistance, parse } from "date-fns"
import { pl } from "date-fns/locale"
import Fade from "react-reveal/Fade"
import { getPackageName } from "../packages/PackageUtils"
import { useTranslation } from "react-i18next"

const Intro = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const companyContext = useCompanyContext()
  const { licenseRemainingTimeLabel, licenseRemainingTimeDays } = companyContext


  var myPackageName = getPackageName(companyContext.company?.license_type, lang)
  var isActiveMerchant = companyContext.isActiveMerchant
  return (
    <div className="flex flex-col lg:flex-row ">
      <div className="w-[100%] lg:w-[496px] mb-[50px] lg:mb-0">
        <p>
          <strong>
            {t("home_dashboard.intro_header_1", "W górnym prawym rogu")}
          </strong>
          ,{" "}
          {t(
            "home_dashboard.intro_header_2",
            "znajdziesz szybki dostęp do swojej strony widzianej oczami Klienta."
          )}
        </p>

        <p className="mt-3">
          <strong>
            {t("home_dashboard.intro_header_3", "Po prawej Stronie")}
          </strong>
          ,{" "}
          {t(
            "home_dashboard.intro_header_4",
            "zobaczysz infomacje o tym z jakiego pakietu korzystasz oraz ile dni pozostało do jego wygaśnięcia"
          )}
        </p>

        <p className="mt-3">
          <strong>{t("home_dashboard.intro_header_5", "Poniżej")}</strong>,{" "}
          {t(
            "home_dashboard.intro_header_6",
            "zostały umieszczone kluczowe funkcjonalności, dzięki którym zarządzanie Twoją usługą nigdy nie było prostsze."
          )}
        </p>
      </div>

      <div
        className="mb-[20px] ml-0 
        lg:ml-[28px]
         bg-[#F3F6FD] 
        rounded-lg 
        px-[20px] 
        w-full 
        lg:w-[510px] 
        py-[27px]"
      >
        <div className="flex justify-between mb-[20px]">
          <h3 className="text-2xl font-bold">
            {myPackageName ?? "Brak Pakietu"}
          </h3>
          {myPackageName && (
            <>
              {isActiveMerchant ? (
                <p className="text-[#5CAA0B]">
                  {t("package_status.active", "Aktywny")}
                </p>
              ) : (
                <p className="text-fc-red">
                  {t("package_status.active", "Nieaktywny")}
                </p>
              )}
            </>
          )}
          {!myPackageName && (
            <p
              style={{
                color: "var(--fc-red)"
              }}
            >
              {t("package_status.active", "Nieaktywny")}
            </p>
          )}
        </div>

        <div className="flex justify-between items-end">
          {myPackageName && (
            <div className="">
              {licenseRemainingTimeDays > 0 && (
                <p>
                  <span className="text-[#7e7e7e]">
                    {t("package_status.time_left", "Pozostało:")}
                  </span>{" "}
                  {licenseRemainingTimeLabel}
                </p>
              )}
              {licenseRemainingTimeDays <= 0 && (
                <p>
                  <span className="text-[#7e7e7e]">
                    {t("package_status.expired", "Wygasł:")}{" "}
                  </span>
                  {-licenseRemainingTimeDays}{" "}
                  {t("package_status.ago", "dni temu")}
                </p>
              )}
              <p className="mt-[10px]">
                <span className="text-[#7e7e7e]">
                  {t("package_status.Valid", "Ważny do dnia:")}{" "}
                </span>
                {companyContext.company?.license_valid_till}
              </p>
            </div>
          )}
          <div>
            <Link to={"/about/packets/wybierz"}>
              <Button
                className="mx-20 my-10 rounded-lg "
                variant="contained"
                type="submit"
                sx={{ width: "200px" }}
              >
                {t("package_status.select", "Wybierz pakiet")}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const InfoBox = ({
  title,
  description,
  imgSrc,
  href
}: {
  title: string
  description: string
  imgSrc: string
  href: string
}) => {
  const { t } = useTranslation()
  return (
    <div
      className="flex flex-col justify-between w-[100%] 
      lg:w-[320px] border-[4px] border-[#F3F6FD] 
      rounded-2xl overflow-hidden
      hover:shadow-lg
      hover:-translate-y-1
      cursor-pointer
      group
      transition-all
      "
    >
      <div className="p-[30px] ">
        <div className="flex h-16 mb-4">
          <h4 className="text-2xl font-bold text-center">{title}</h4>
        </div>
        <div className="text-center justify-center w-full flex flex-row items-center mb-4 ">
          <img className="" src={imgSrc} alt="" />
        </div>

        <p className="mt-2">{description}</p>
      </div>
      <Link
        to={href}
        className="block bg-[#F3F6FD] hover:bg-[#E5ECFC] py-[14px] text-xl text-center cursor-pointer
        group-hover:underline"
      >
        {t("other.goto", "Przejdź")}
      </Link>
    </div>
  )
}

const AddressBox = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-[#F4D7D4] p-[36px] rounded-2xl w-[100%] lg:w-[340px]">
      <p className="text-xl">
        {t("other.help", "Jeśli potrzebujesz pomocy skontaktuj się z nami!")}
      </p>
      <div className="mt-8">
        <p className="text-xl font-bold">Marta</p>
        <div className="text-xl flex items-center">
          <span>
            <img src="/assets/svg/phone.svg" alt="" />
          </span>
          <p className="pl-[13px]">786 823 320</p>
        </div>
        <div className="text-xl flex items-center">
          <span>
            <img src="/assets/svg/mail.svg" alt="" />
          </span>
          <p className="pl-[13px]">oferty@gofunlo.com</p>
        </div>
      </div>
    </div>
  )
}

function HomeDashboard() {
  const { t } = useTranslation()
  return (
    <Fade top distance="20px">
      <div className="w-[100%] lg:w-[1068px] m-auto px-8 pb-[50px]">
        <h3 className="text-4xl font-bold my-[50px]">
          {t(
            "welcome",
            "Witaj w panelu GoFunlo.com. Dziękujemy, że z nami jesteś!"
          )}
        </h3>
        <Intro />

        <div className="flex flex-col lg:flex-row gap-6 mt-6">
          <InfoBox
            title={t("Booking.Summer_camps", "Kolonie i Obozy")}
            description={t(
              "Booking.camps_description",
              "Dodawaj oferty letnich lub  zimowych wyjazdów dla dzieci"
            )}
            imgSrc="/assets/illustrations/oferty-camp.svg"
            href="/products/camp"
          />
          <InfoBox
            title={t("Booking.Day_camps", "Półkolonie")}
            description={t(
              "Booking.camps_description",
              "Dodawaj oferty letnich lub  zimowych wyjazdów dla dzieci"
            )}
            imgSrc="/assets/illustrations/oferty-camp.svg"
            href="/products/daycamp"
          />
          <InfoBox
            title={t("Booking.School_trips", "Wycieczki Szkolne")}
            description={t(
              "Booking.camps_description",
              "Organizuj wyjazdy szkolne dla dzieci i młodzieży."
            )}
            imgSrc="/assets/illustrations/wycieczki-szkolne.svg"
            href="/products/schooltrip"
          />
          <AddressBox />
        </div>
      </div>{" "}
    </Fade>
  )
}

export default HomeDashboard
