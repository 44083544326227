export type FCPackage = {
  code: string;
  name: string;
  description: string;
  price: number;
  offerVisibility: string;
  features: Array<string>;
  savings?: number;
  bestChoice?: boolean;
  bgColor?: string;
  active?: boolean;
};

export const usaPromoPackageTypes = [
  {
    code: "MINI_2023_250",
    discount: "41% discount",
    name: "Mini package",
    fullPrice: "100 PLN net",
    discountPrice: "59 PLN",
    duration: "1 MONTH",
    link: "#",
  },
  {
    code: "STANDARD_2022_PROMO",
    discount: "49% discount",
    name: "Standard package",
    fullPrice: "600 PLN net",
    discountPrice: "179 PLN",
    duration: "6 MONTHS",
    link: "#",
  },
  {
    code: "FULL_2022_PROMO",
    discount: "51% discount",
    name: "Full package",
    fullPrice: "800 PLN net",
    discountPrice: "299 PLN",
    duration: "1 YEAR",
    link: "#",
  },
];

export const usaPackageTypes: Array<FCPackage> = [
  {
    code: "MINI_2022",
    name: "Mini Package",
    description:
        "Is your offer expiring soon and you still have available spots?",
    price: 100,
    offerVisibility: "1 MONTH",
    features: [
      "Unlimited number of listings",
      "Visibility for 90 days",
      "Dedicated website",
      "Customer contact panel",
      "Booking management panel",
      "Customer payment processing",
    ],
    bgColor: "#ffffff",
    active: false,
  },
  {
    code: "MINI_2023_250",
    name: "Mini Package",
    description:
        "Is your offer expiring soon and you still have available spots?",
    price: 300,
    offerVisibility: "90 DAYS",
    features: [
      "Unlimited number of listings",
      "Visibility for 90 days",
      "Dedicated website",
      "Customer contact panel",
      "Booking management panel",
      "Customer payment processing",
    ],
    bgColor: "#ffffff",
    active: true,
  },
  {
    code: "STANDARD_2022",
    name: "Standard Package",
    description:
        "Do you operate seasonally, offering attractions only in summer or winter?",
    price: 500,
    offerVisibility: "180 DAYS",
    features: [
      "Unlimited number of listings",
      "Visibility for 180 days",
      "Dedicated website",
      "Customer contact panel",
      "Booking management panel",
      "Customer payment processing",
    ],
    savings: 100,
    bgColor: "#F4F4F4",
    active: true,
  },
  {
    code: "FULL_2022",
    name: "Full Package",
    description:
        "Are you fully engaged in the industry, selling services from January to December?",
    price: 800,
    offerVisibility: "365 DAYS",
    features: [
      "Unlimited number of listings",
      "Visibility for a year",
      "Dedicated website",
      "Customer contact panel",
      "Booking management panel",
      "Customer payment processing",
      "Promotional post on social media",
    ],
    savings: 400,
    bestChoice: true,
    bgColor: "#B6E7F2",
    active: true,
  },
];

export const promoPackageTypes = [
  {
    // code: "MINI_2022_PROMO",
    code: "MINI_2023_250",
    discount: "41% zniżki",
    name: "Pakiet mini",
    en_name: "Mini package",
    fullPrice: "100 zł netto",
    discountPrice: "59 zł",
    duration: "1 MIESIĄC",
    link: "#",
  },
  {
    code: "STANDARD_2022_PROMO",
    discount: "49% zniżki",
    name: "Pakiet standard",
    en_name: "Standard package",
    fullPrice: "600 zł netto ",
    discountPrice: "179 zł",
    duration: "6 MIESIĘCY",
    link: "#",
  },
  {
    code: "FULL_2022_PROMO",
    discount: "51% zniżki",
    name: "Pakiet full",
    fullPrice: "800 zł netto",
    discountPrice: "299 zł",
    duration: "ROK",
    link: "#",
  },
];

export const packageTypes: Array<FCPackage> = [
  {
    // code: "MINI_2022",
    code: "MINI_2022",
    name: "Pakiet mini",
    description:
      "Zbliża się termin Twojej oferty i masz jeszcze wolne miejsca?",
    price: 100,
    offerVisibility: "1MIESIĄCE",
    features: [
      "Nielimitowana ilość ofert",
      "Widoczność przez 90 dni",
      "Dedykowana strona www",
      "Panel do kontaktu z klientami",
      "Panel do zarządzania rezerwacjami",
      "Obsługa płatności od klientów",
    ],
    // savings: 250,
    bgColor: "#ffffff",
    active: false,
  },
  {
    // code: "MINI_2022",
    code: "MINI_2023_250",
    name: "Pakiet mini",
    description:
      "Zbliża się termin Twojej oferty i masz jeszcze wolne miejsca?",
    price: 300,
    offerVisibility: "90 DNI",
    features: [
      "Nielimitowana ilość ofert",
      "Widoczność przez 90 dni",
      "Dedykowana strona www",
      "Panel do kontaktu z klientami",
      "Panel do zarządzania rezerwacjami",
      "Obsługa płatności od klientów",
    ],
    // savings: 250,
    bgColor: "#ffffff",
    active: true,
  },
  {
    code: "STANDARD_2022",
    name: "Pakiet standard",
    description:
      "Działasz sezonowo, organizujesz tylko letnie lub zimowe atrakcje?",
    price: 500,
    offerVisibility: "180 DNI",
    features: [
      "Nielimitowana ilość ofert",
      "Widoczność przez 180 dni",
      "Dedykowana strona www",
      "Panel do kontaktu z klientami",
      "Panel do zarządzania rezerwacjami",
      "Obsługa płatności od klientów",
      // "Panel ocen i opinii od klientów",
    ],
    savings: 100,
    bgColor: "#F4F4F4",
    active: true,
  },
  {
    code: "FULL_2022",
    name: "Pakiet full",
    description:
      "Działasz na całego w branży? Usługi sprzedajesz od stycznia do grudnia?",
    price: 800,
    offerVisibility: "365 DNI",
    features: [
      "Nielimitowana ilość ofert",
      "Widoczność przez rok",
      "Dedykowana strona www",
      "Panel do kontaktu z klientami",
      "Panel do zarządzania rezerwacjami",
      "Obsługa płatności od klientów",
      // "Panel ocen i opinii od klientów",
      "Promocyjny post w mediach społecznościowych",
    ],
    savings: 400,
    bestChoice: true,
    bgColor: "#B6E7F2",
    active: true,
  },
];


export function getPackageName(code: string, lang: string) {
  if (code === "TRIAL") {
    return lang === "en" ? "Demo version" : "Wersja demo";
  }
  if (code === "100_DNI_2022") {
    return lang === "en" ? "TEST Package" : "Pakiet TESTOWY";
  }

  const packageList = lang === "en" ? usaPackageTypes : packageTypes;
  const promoPackageList = lang === "en" ? usaPromoPackageTypes : promoPackageTypes;

  let pk: any = packageList.find((p) => p.code === code);
  if (pk) {
    return pk.name;
  }

  pk = promoPackageList.find((p) => p.code === code);
  if (pk) {
    return pk.name + " PROMO";
  }

  return code;
}
