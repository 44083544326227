import { useState } from "react"
import imageCompression from "browser-image-compression"

export const usePhotoUploader = (afterPhotoUploaded: Function) => {
  const [tmpPhoto, setTmpPhoto] = useState<any>(undefined)

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    try {
      const compressedFile = await imageCompression(file, options)

      return compressedFile
    } catch (error) {
      console.error("Error during image compression: ", error)

      return file
    }
  }

  const uploadToCloudinary = async (file: File, progressHandler: Function) => {
    return new Promise((resolve) => {
      const cloudName = "www-fajnyczas-pl"
      const unsignedUploadPreset = "ml_default"
      var url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`
      var xhr = new XMLHttpRequest()
      var fd = new FormData()
      xhr.open("POST", url, true)
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")

      xhr.upload.addEventListener("progress", function (e) {
        console.log(`fileuploadprogress data.loaded: ${e.loaded},
  data.total: ${e.total}`)

        const percentLoaded = Math.round((100 * e.loaded) / e.total)
        if (progressHandler) {
          progressHandler(percentLoaded)
        }
      })

      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File uploaded successfully
          var response = JSON.parse(xhr.responseText)
          // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg
          var url = response.secure_url
          // Create a thumbnail of the uploaded image, with 150px width
          var tokens = url.split("/")
          tokens.splice(-2, 0, "w_150,c_scale")
          const thumb150Url = tokens.join("/")

          resolve({
            url: response.url,
            thumb150Url,
            width: response.width,
            height: response.height,
            providerData: {
              cloudinaryEtag: response.etag,
              cloudinarySignature: response.signature,
              cloudinaryPublicId: response.public_id
            }
          })
        }
      }

      fd.append("upload_preset", unsignedUploadPreset)
      fd.append("tags", "browser_upload") // Optional - add tag for image admin in Cloudinary
      fd.append("file", file)
      xhr.send(fd)
    })
  }

  const uploadAndStoreFile = async (
    photo: any,
    data: any,
    progressHandler: Function
  ) => {

    const compressedFile = await compressImage(photo.file)
    photo.file = compressedFile

    const uploadedPhoto = await uploadToCloudinary(photo.file, progressHandler)
    return uploadedPhoto
  }

  const loadPhoto = async (inphoto: any, pProgressHandler: any) => {

    const reader = new window.FileReader()

    return new Promise(async (resolve) => {
      reader.onload = async (event: any) => {
        if (event.type === "load") {
          const data = event.target.result
          let photo = {
            ...inphoto,
            loadStatus: "uploading",
            data
          }
          if (pProgressHandler) {
            pProgressHandler(inphoto.photoId, { data })
          }

          setTmpPhoto(photo)
          const progressHandler = (percentUploaded: any) => {
            const photo = {
              ...inphoto,
              loadStatus: "uploading",
              data,
              percentUploaded
            }
            if (pProgressHandler) {
              pProgressHandler(inphoto.photoId, { progress: percentUploaded })
            }
            setTmpPhoto(photo)
            //   const photos = [...this.state.photos]
            //   photos[photoIdx] = photo
            //   this.setStateAsync({ photos })
          }
          const res: any = await uploadAndStoreFile(
            photo,
            data,
            progressHandler
          ) // reader.result)
          // photo = { ...photo, loadStatus: 'uploaded' }

          const pphoto: any = {
            id: photo.id,
            ...res,
            sourceUrl: res.url,
            sourceType: "cloudinary_url",
            loadStatus: "uploaded"
          }
          resolve(pphoto)
        }
      }
      const photo = { ...inphoto, loadStatus: "loading" }
      reader.readAsArrayBuffer(inphoto.file)
    })
  }

  const handleNewPhotoSelected = async (photo: any, progressHandler: any) => {
    setTmpPhoto(photo)
    const res = await loadPhoto(photo, progressHandler)
    return res
  }
  const handleReset = () => {
    setTmpPhoto(undefined)
  }
  return {
    tmpPhoto,
    onPhotoSelected: handleNewPhotoSelected,
    reset: handleReset
  }
}
