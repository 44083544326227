import {
  Navigate,
  Route,
  Routes,
  resolvePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { BookingsTable } from "./BookingsTable";
import { PageTitle } from "../../../components/typography";
import { useTranslation } from "react-i18next";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import BookingsToolbar from "./BookingsToolbar";
import axios from "axios";
import { useCompanyContext } from "../../../contexts/company-context";
import {
  ProductStatus,
  ProductUtils,
} from "wakacje-web/src/utils/ProductUtils";
import SnippetFolderOutlinedIcon from "@mui/icons-material/SnippetFolderOutlined";
import { useCurrency } from "../../../hooks/useCurrency";
import { useProductUtils } from "../../../hooks/useProductUtils";

type TabData = {
  path: string;
  label?: string;
  icon?: React.ReactNode;
};

const BookingsIndexTable = ({ type }: { type: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const companyContext = useCompanyContext();
  const [selectedProduct, setSelectedProduct] = useState("all");
  const [selectedType, setSelectedType] = useState("all");
  const [queryPar, setQueryPar] = useState({});
  const [sums, setSums] = useState(null);
  const { pathname } = useLocation();
  const [query, setQuery] = useState("");

  const { selectedCurrency } = useCurrency();
  const { formatPriceInCents } = useProductUtils();

  const tabs: TabData[] = [
    {
      path: "products",
      label: t("ui.booking.booking_list", "Lista rezerwacji"),
      icon: <MapOutlinedIcon />,
    },
    {
      path: "archive",
      label: t("ui.booking.archived", "Archiwum"),
      icon: <SnippetFolderOutlinedIcon />,
    },
  ];

  const tabIndex = tabs.findIndex(
    (tabData: TabData) => tabData["path"] === type
  );

  useEffect(() => {
    const queryParams: any = {};

    queryParams.company_id = companyContext.company?.id;
    if (selectedProduct && selectedProduct !== "all") {
      queryParams.product_id = selectedProduct;
    }
    if (selectedType && selectedType !== "all") {
      queryParams.product_type = selectedType;
    }

    if (query) {
      queryParams.query = query;
    }

    if (tabIndex === 0) {
      queryParams.liveness_status = ProductStatus.ACTIVE;
    } else if (tabIndex === 1) {
      queryParams.liveness_status = ProductStatus.ARCHIVED;
    }

    const getSums = async () => {
      const sums = await getBookingData(queryParams, true);
      setSums(sums);
    };
    getSums();
    setQueryPar(queryParams);
  }, [selectedType, selectedProduct, query, companyContext, tabIndex]);

  const getBookingData = async (query: any = {}, showPaidSum?: boolean) => {
    query.company_id = companyContext.company?.id;
    query.view = "company";
    let url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL +
      `/bookings/v2.0/bff/merchant/bookings`;
    if (query) {
      url += "?" + new URLSearchParams(query).toString();
    }

    const res = await axios.get(url);

    if (showPaidSum) {
      return res?.data?.sums;
    }
    return res?.data?.data;
  };

  //TODO: na tym ekranie trzeba dogadać jak te sumy wpłat wyświetlamy między innymi

  return (
    <div className="p-8">
      <div className="my-4">
        <div className="px-8 flex flex-col md:flex-row justify-between">
          <PageTitle gutterBottom>
            <div className="">{t("ui.menu.booking", "Rezerwacje")}</div>
          </PageTitle>
          {sums && (
            <div className="self-end py-4 px-8  rounded flex flex-col bg-[#F3F6FD] ">
              <span className="m-auto text-fc-accent-3 font-bold">
                {ProductUtils.formatPriceInCents(
                  sums.paid_amount_sum,
                  false,
                  selectedCurrency.symbol
                )}{" "}
                /{" "}
                {ProductUtils.formatPriceInCents(
                  sums.total_price_sum,
                  false,
                  selectedCurrency.symbol
                )}
              </span>
              <span className="m-auto">
                {t("Booking.total_payments", "Suma wpłat")}
              </span>
            </div>
          )}
        </div>
        <p className="text-base mt-2 px-8">
          {t(
            "Booking.reservations",
            "Sprawdź nowe rezerwacje. Bądź w bezpośrednim kontakcie z Klientem."
          )}
        </p>
      </div>
      <div className="p-8">
        <Tabs
          value={tabIndex}
          aria-label="organizatorze tab"
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              onClick={() =>
                navigate(resolvePath(`../${tab["path"]}`, pathname))
              }
              label={
                <div className="flex justify-center items-center">
                  <div className="mr-1">{tab["icon"]}</div>
                  {tab["label"] ?? tab["path"]}
                </div>
              }
              key={tab["path"]}
            />
          ))}
        </Tabs>
        {tabIndex === 0 && (
          <>
            <BookingsToolbar
              setSearchTextDebounced={setQuery}
              api={getBookingData}
              queryParams={queryPar}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
            />
            <div className="-mt-2">
              <BookingsTable
                type={ProductStatus.ACTIVE}
                queryParams={queryPar}
              />
            </div>
          </>
        )}
        {tabIndex === 1 && (
          <>
            <BookingsToolbar
              setSearchTextDebounced={setQuery}
              api={getBookingData}
              queryParams={queryPar}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
            />
            {
              <div className="-mt-2">
                <BookingsTable
                  type={ProductStatus.ARCHIVED}
                  queryParams={queryPar}
                />
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

export const BookingsIndex = () => {
  return (
    <>
      <Routes>
        <Route index element={<Navigate to="products" />} />
        <Route
          path="/products"
          element={<BookingsIndexTable type="products" />}
        />
        <Route
          path="/archive"
          element={<BookingsIndexTable type="archive" />}
        />
      </Routes>
    </>
  );
};
