import { Box } from "@mui/system"
import { useEffect } from "react"
import { useAuthContext } from "../../../contexts/auth-context"
import { Button, Container } from "@mui/material"
import {useTranslation} from "react-i18next";

export const UserProfilePage = (props: any) => {
  const { t } = useTranslation()

  const authContext = useAuthContext()

  useEffect(() => {
    authContext.refetchCurrentUser()
    // eslint-disable-next-line
  }, [])
  return (
    <Container maxWidth="lg">
      <div className="pt-8">
        <div>
          <div>{t("user_profil_page.username", "Nazwa użytkownika")}</div>
        </div>
        <div className="mb-8 font-bold">
          {authContext.currentUser
            ? authContext.currentUser.name ?? authContext.currentUser.email
            : t("user_profil_page.not_logged_in", "Nie zalogowany")}
        </div>
        <div>
          <div>Id</div>
          <div className="mb-8 font-bold">{authContext.currentUser?.id}</div>
        </div>

        <Box className="cursor-pointer">
          <Button
            onClick={(event: any) => authContext.logout()}
            variant="contained"
            color="primary"
          >
            {t("logout", "Wyloguj")}
          </Button>
        </Box>
      </div>
    </Container>
  )
}
