import { useTranslation } from 'react-i18next'
import { CampFees } from '../Fees/CampFees/CampFees'

export const TripDataDialogFees = ({
  control,
  currency
}: {
  currency: string
  control: any
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className='mb-2 mt-6 font-bold text-lg'>
        {t('ui.trips.fees', 'Opłaty')}
      </div>
      <CampFees control={control} currency={currency} />
    </>
  )
}
