import axios from "axios"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react"
import { useAuth } from "wakacje-web/src/auth/AuthContext"

export interface CurrentUser {
  id: string
  name: string
  email?: string
  emailVerified?: boolean
}
export interface Merchant {
  id: string
  name: string
}
export interface AuthContextType {
  currentUser?: CurrentUser
  logout: Function
  refetchCurrentUser: Function
  initializationCompleted: boolean
  isLoadingProfile: boolean
  isSuperAdmin: boolean
  homeData?: {
    unreadMessagesCount: number
    reservationsToApprove: number
  }
  refreshHomeData: Function
}

const LOCAL_STORAGE_KEY = "mojeAdminAuthContext"

export const AuthContext = createContext<AuthContextType>({
  logout: () => {},
  refetchCurrentUser: () => {},
  initializationCompleted: false,
  isLoadingProfile: false,
  isSuperAdmin: false,
  refreshHomeData: () => {}
})

export const FCAuthContextProvider = ({ children }: { children: any }) => {
  const [currentUser, setCurrentUser] = useState<any>(undefined)
  const [isInitializationCompleted, setIsInitializationCompleted] =
    useState<boolean>(false)
  const [isLoadingProfile, setIsLoadingProfile] = useState(false)
  const [homeData, setHomeData] = useState()
  const firebaseAuthContext = useAuth()

  const _rehydrate = async () => {
    if (window.localStorage) {
      const dataStr = window.localStorage.getItem(LOCAL_STORAGE_KEY)
      if (dataStr != null) {
        const data = JSON.parse(dataStr)
        if (data.currentUser) {
          setCurrentUser(data.currentUser)
          return data.currentUser
        }
      }
    }
  }

  useEffect(() => {
    //
    if (firebaseAuthContext.user) {
      refetchCurrentUser()
    }
  }, [firebaseAuthContext.user])

  // Old Cognito
  // useEffect(() => {
  //   // Access the user session on the client
  //   Auth.currentAuthenticatedUser()
  //     .then(async (user) => {
  //       setCognitoUser(user)
  //       const rehydrated = await _rehydrate()
  //       // const currentUser = await refetchCurrentUser()
  //       setIsInitializationCompleted(true)
  //       refetchCurrentUser()
  //     })
  //     .catch((err) => {
  //
  //
  //       setCurrentUser(null)
  //       setIsInitializationCompleted(true)
  //     })
  // }, [])

  useEffect(() => {
    if (firebaseAuthContext.ready) {
      const run = async () => {
        try {
          var u = await firebaseAuthContext.auth.currentUser()
        } catch (err) {
        }
        setIsInitializationCompleted(true)
      }
      run()
    }
  }, [firebaseAuthContext.ready])

  const refreshHomeData = useCallback(async () => {
    if (!currentUser) {
      return
    }
    let meUrl = process.env.REACT_APP_WAKACJE_API_URL + `/v1/home`

    try {
      var result = await axios({
        method: "GET",
        url: meUrl
      })
      if (result && result.status === 200) {
        setHomeData(result.data)
      } else {
        setHomeData(undefined)
      }
    } catch (err) {
      console.error("Error get homedata")
    }
  }, [])

  const logout = () => {
    firebaseAuthContext.logout()
    setCurrentUser(undefined)
  }

  const _persist = useCallback(() => {
    if (window.localStorage) {
      if (currentUser) {
        const data = {
          currentUser
        }
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
      } else {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY)
      }
    }
  }, [currentUser])

  useEffect(() => {
    if (isInitializationCompleted) {
      _persist()
      refreshHomeData()
    }
  }, [currentUser, isInitializationCompleted, refreshHomeData])

  const refetchCurrentUser = useCallback(async () => {
    let meUrl = process.env.REACT_APP_WAKACJE_API_URL + `/v1/me`

    try {
      setIsLoadingProfile(true)
      var result = await axios({
        method: "GET",
        url: meUrl
      })
      if (result && result.status === 200) {
        setCurrentUser(result.data.user)
      } else {
        setCurrentUser(undefined)
      }
      setIsLoadingProfile(false)
    } catch (err: any) {
      console.error("Error get me")
      if (err.response && err.response.status === 401) {
        // Auth.signOut()
      }
      setIsLoadingProfile(false)
    }
  }, [])

  // useEffect(() => {
  //   console.log(
  //     "on currentCognitoUser changed effect currentCognitoUser",
  //     cognitoUser
  //   )
  //   refetchCurrentUser()
  // }, [cognitoUser])

  var user: any = undefined
  if (firebaseAuthContext.user) {
    user = {
      id: firebaseAuthContext.user.uid,
      email: firebaseAuthContext.user.email,
      name: firebaseAuthContext.user.displayName,
      emailVerified: firebaseAuthContext.user.emailVerified
    }
  }
  // Copy additional local details
  //

  const contextValue = {
    logout,
    currentUser: user,
    refetchCurrentUser,
    initializationCompleted: isInitializationCompleted,
    isLoadingProfile,
    isSuperAdmin: currentUser?.isAdmin,
    homeData,
    refreshHomeData
  }
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuthContext = (): AuthContextType =>
  useContext(AuthContext) as AuthContextType
