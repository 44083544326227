import { Routes, Route, useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { AddTripForm } from "../../trip/components/AddTripForm"
import { useSnackbar } from "notistack"
import { TripsTable } from "../events/TripsTable"
import { BlueBackground } from "wakacje-web/src/shared/components/BlueBackground"
import { Heading3 } from "../../../components/FormCommons"

export const ProductTripsPage = ({
  product,
  onSaved,
  refetch
}: {
  product: any
  onSaved: Function
  refetch: Function
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleSaved = (trip: any) => {
    enqueueSnackbar("Turnus dodany", { variant: "success" })
    onSaved()
  }
  return (
    <Box>
      <Routes>
        <Route
          path="dodaj"
          element={
            <Box maxWidth={1000}>
              <AddTripForm product={product} onSaved={handleSaved} />
            </Box>
          }
        ></Route>
        <Route
          index
          element={
            product.trips && product.trips.length > 0 ? (
              <TripsTable
                data={product.trips}
                product={product}
                showProduct={false}
                refetch={refetch}
              />
            ) : (
              <>
                <div className="text-semibold text-xl p-8 text-center">
                  To wydarzenie nie ma jeszcze turnusów
                </div>
                <div className="text-center">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => navigate("../turnusy/dodaj")}
                  >
                    Dodaj termin
                  </Button>
                </div>
              </>
            )
          }
        />
      </Routes>
    </Box>
  )
}
