import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { TrashIcon } from "../../../components/icons/TrashIcon"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { PlusIcon } from "../../../components/icons/PlusIcon"
import SearchIcon from "../../../components/icons/SearchIcon"

export const ActionWithLoader = ({ onClick, children }) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async (params) => {
    setLoading(true)
    await onClick(params)
    setLoading(false)
  }
  return (
    <div onClick={handleClick}>
      {children({ onClick: handleClick, loading })}
    </div>
  )
}

export const ProductActionWithLoader = ({ onClick, children }) => {
  return (
    <div className="">
      <ActionWithLoader onClick={onClick}>
        {({ loading }) => {
          return (
            <div className="flex flex-row items-center">
              <div className="text-lg">
                {loading ? (
                  <div className="text-base">
                    <LoadingSpinner />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {children}
            </div>
          )
        }}
      </ActionWithLoader>
    </div>
  )
}

type OptionType =
  | "editOffer"
  | "edit"
  | "duplicate"
  | "remove"
  | "add"
  | "preview"
  | "openChat"

const RemoveButton = () => {
  const { t } = useTranslation()
  return (
    <div
      className={clsx(
        "items-center flex gap-1 hover:underline"
      )}
    >
      <TrashIcon
        fill={"#0C48D7"}
        width="16"
        height="16"
      />
      <p className="py-2">{t("remove", "Usuń")}</p>
    </div>
  )
}

export const OptionButton = ({
  row,
  options,
  handleDelete,
  handleDuplicate,
  editUrl,
  rowType,
  chatUrl,
  setPopupEditData,
  setPopupAddDate
}: {
  row
  options: OptionType[]
  handleDelete?: Function
  handleDuplicate?: Function
  editUrl?: string
  chatUrl?: string
  rowType?: string
  setPopupEditData?: (a) => void
  setPopupAddDate?: (a) => void
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handlePreview = (row) => {
    if (row.type === "dedykowana-wycieczka-szkolna") {
      window.open(
        `https://www.fajnyczas.pl/dedykowana-oferta/${row.trips?.[0].id}`,
        "_blank"
      )
    } else {
      window.open(
        `https://www.fajnyczas.pl/${row.company?.fc_website_prefix}/${row.id}`,
        "_blank"
      )
    }
  }

  const confirmationMessage = t("confirm_delete", "Czy na pewno chcesz usunąć? Wpisz 'tak'");
  const yesAnswer = t("yes", "tak").toLowerCase();
  const noAnswer = t("no", "nie").toLowerCase();

  return (
    <div className="w-full flex flex-col md:flex-row justify-items-start text-fc-accent-3 gap-4 items-center text-xs">
      {options && options.includes("editOffer") && (
        <div
          className="flex gap-1 items-center hover:underline"
          onClick={(e) => {
            e.preventDefault()
            if (setPopupEditData) {
              setPopupEditData(row)
            } else {
              navigate(editUrl ?? `${row.id}/details`)
            }
          }}
        >
          <img src="/svg/edit-icon.svg" alt="edit" width="16" height="16" />
          <p>{t("edit", "Edytuj ")}</p>
        </div>
      )}
      {options && options.includes("edit") && (
        <div
          className="flex gap-1 items-center hover:underline p-2"
          onClick={(e) => {
            e.preventDefault()
            if (setPopupEditData) {
              e.preventDefault()
              setPopupEditData(row)
            } else {
              navigate(editUrl ?? `${row.id}/details`)
            }
          }}
        >
          <img src="/svg/edit-icon.svg" alt="edit" width="16" height="16" />
          <p>{t("edit", "edytuj ")}</p>
        </div>
      )}
      {options && options.includes("add") && setPopupAddDate && (
        <div
          className="flex gap-1 items-center ml-4"
          onClick={(e) => {
            e.preventDefault()
            if (setPopupAddDate) {
              setPopupAddDate(row)
            }
          }}
        >
          <PlusIcon
            fill={row.stat_all_trips_count > 0 ? "#0C48D7" : "#ff0000"}
          />
          <p
            className={clsx(
              "hover:underline",
              "whitespace-nowrap",
              row.stat_all_trips_count > 0
                ? "text-fc-accent-3"
                : "text-[#ff0000]"
            )}
          >
            {t("add_term", "Dodaj termin")}
          </p>
        </div>
      )}
      {options && options.includes("add") && !setPopupAddDate && (
        <Link
          to={`${row.id}/turnusy/dodaj`}
          className="flex gap-1 items-center ml-4"
        >
          <PlusIcon
            fill={row.stat_all_trips_count > 0 ? "#0C48D7" : "#ff0000"}
          />
          <p
            className={clsx(
              "hover:underline",
              "whitespace-nowrap",
              row.stat_all_trips_count > 0
                ? "text-fc-accent-3"
                : "text-[#ff0000]"
            )}
          >
            {t("add_term", "Dodaj termin")}
          </p>
        </Link>
      )}
      {options &&
        options.includes("preview") &&
        row.company?.fc_website_prefix && (
          <ProductActionWithLoader
            onClick={(e) => {
              e.preventDefault()
              handlePreview(row)
            }}
          >
            <div className="flex gap-1 items-center">
              <SearchIcon fill={"#0C48D7"} width="14" height="14" />
              <p
                className={clsx(" hover:underline", "text-fc-accent-3", "py-2")}
              >
                {t("preview", "Podgląd")}
              </p>
            </div>
          </ProductActionWithLoader>
        )}

      {options && options.includes("remove") && (
        <ProductActionWithLoader
          onClick={(e) => {
            e.preventDefault()
            const userResponse = prompt(confirmationMessage, noAnswer);

            if (userResponse && userResponse.toLowerCase() === yesAnswer) {
              handleDelete(row)
            }
          }}
        >
            <RemoveButton />
        </ProductActionWithLoader>
      )}
      {options && options.includes("openChat") && (
        <ProductActionWithLoader
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <div className="flex gap-1 items-center hover:underline p-2">
            <img
              src="/svg/chat.svg"
              alt=""
              width="16"
              height="16"
              className="cursor-pointer"
            />
            <Link
              key={row.id}
              to={chatUrl ? `/chats/${chatUrl}` : `/chats/${row.id}`}
            >
              <p className="text-fc-accent-3">
                {t("open_chat", "Otwórz czat")}{" "}
              </p>
            </Link>
          </div>
        </ProductActionWithLoader>
      )}
    </div>
  )
}
