import { createContext, useContext, useEffect, useState } from "react"

export interface WsContextType {
  isConnected: boolean
  reconnect: Function
}

export const WsContext = createContext<WsContextType>({
  isConnected: false,
  reconnect: () => {}
})

export const WsContextProvider = ({
  children,
  userId,
  mode,
  url,
  accessToken,
  companyId
}: {
  children: any
  userId?: any
  mode: string
  accessToken?: string
  url: string
  companyId?: string
}) => {
  const [isConnected, setIsConnected] = useState(false)
  const [ws, setWs] = useState<undefined | WebSocket>()

  const reconnect = async () => {
    //
    if (ws) {
      ws.close()
    }
    try {
      if (mode === "USER" && !userId) {
        return
      }
      //
      const _ws = new WebSocket(url)
      _ws.onopen = (event) => {
        setIsConnected(true)
        _ws.send(
          JSON.stringify({
            messageType: "command",
            command: {
              type: "Authenticate",
              payload: {
                type: mode,
                user_id: userId,
                company_id: companyId,
                accessToken,
                clientType: mode
              }
            }
          })
        )
      }
      _ws.onerror = (event) => {
        setIsConnected(false)
      }
      _ws.onmessage = (event) => {
        if (event.data) {
          const body = JSON.parse(event.data)
          if (document.dispatchEvent) {
            if (body.aggregateType) {
              document.dispatchEvent(
                new CustomEvent(body.aggregateType, { detail: body })
              )
            } else if (body.type) {
              document.dispatchEvent(
                new CustomEvent(body.type, { detail: body })
              )
            }
          }
        }
      }
      _ws.onclose = (err) => {
        setIsConnected(false)
        setWs(undefined)
        setTimeout(reconnect, 1000)
      }
      setWs(_ws)
    } catch (err) {
      console.error("Not authenticated")
    }
  }

  useEffect(() => {
    reconnect()
  }, [companyId])

  useEffect(() => {
    if (typeof window !== "undefined") {
      reconnect()
    }
  }, [])

  useEffect(() => {
    reconnect()
  }, [userId])

  // useEffect(() => {
  //   reconnect()
  // }, [reconnect])

  //   useEffect(() => {
  //
  //     reconnect()
  //   }, [merchantContext.merchant?.id])

  const value = {
    isConnected,
    reconnect
  }
  return <WsContext.Provider value={value}>{children}</WsContext.Provider>
}

export const useWsContext = (): WsContextType =>
  useContext(WsContext) as WsContextType
