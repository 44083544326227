import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import {
  accordionBox,
  accordionContainer,
  accordionText,
  accordionTitle,
  pageTitle,
  pageTitleWrapper,
} from "./helpStyle";
//import { ModalWindow } from "./sections/ModalWindow"
import styled from "@emotion/styled";
import { FAJNYCZAS_VERSION } from "../../../version";
import { useTranslation } from "react-i18next";

// let BoxDiv = styled.div({
// 	display: 'flex',
// 	flexFlow: 'row',
// 	justifyContent: 'space-between',
// 	padding: 40,
// });

// let AccordionDiv = styled.div({
// 	padding: 50,
//
// });
const CustomAccordionSummary = styled(AccordionSummary)`
  padding: 10px 10px;
`;

export const HelpIndex = (props: any) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Box sx={pageTitleWrapper}>
        <Typography
          variant="h4"
          component="h1"
          sx={{ ...pageTitle, paddingY: "16px", fontWeight: "bold" }}
        >
          {t("HelpIndex.help", "Pomoc")}
        </Typography>
        {/* 
          https://bold7.atlassian.net/browse/FC-363
          <ModalWindow /> 
        */}
        {/*<Button className="mx-20 my-10 rounded-lg " variant="contained" type="submit"*/}
        {/*				size="large">*/}
        {/*	Skontaktuj się*/}
        {/*</Button>*/}
      </Box>

      <Box sx={accordionBox}>
        {/* <Accordion
          sx={accordionContainer}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={accordionTitle}>
              CZY POWINNO SIĘ ODWIEDZAĆ DZIECKO NA KOLONII?
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              Oczywiście jest taka możliwość. Szczególnie dziecko, które jest
              pierwszy raz uczestnikiem kolonii ucieszy się z takich odwiedzin.
              Warto uzgodnić z dzieckiem oraz wychowawcą termin i godzinę
              odwiedzin, by mieć pewność, że uczestnik będzie wówczas na terenie
              ośrodka (a nie na przykład na wycieczce lub na zajęciach na
              „poligonie”). Po przybyciu do ośrodka należy natomiast zgłosić się
              do kierownika kolonii, który umożliwi kontakt z dzieckiem. Dorośli
              mają wstęp na teren całego ośrodka jedynie podczas Pikniku
              Rodzinnego. Zachęcamy do odwiedzin właśnie w dniu Pikniku, który
              odbywa się raz w turnusie w godzinach 11-16. W pozostałe dni
              odwiedziny odbywają się w obrębie Recepcji.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        <Accordion
          sx={accordionContainer}
          expanded={expanded === "czymjest"}
          onChange={handleChange("czymjest")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="czymjest1bh-content"
            id="czymjest1bh-header"
          >
            <Typography sx={accordionTitle}>
              {t("HelpIndex.Typography_1a", "Czym jest portal FajnyCzas.pl?")}
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              {t(
                "HelpIndex.Typography_1b",
                "Portal Fajny Czas jest nowoczesnym rozwiązaniem pomagającym firmom zarządzać swoimi usługami. Rozbudowany system pozwala dodawać nowe oferty, zapisywać uczestników, kontrolować status płatności i wolnych miejsc. Narzędzia pozwalają na utrzymanie kontaktu z klientami, wysyłanie zdjęć z wydarzeń, tworzenie pamiętników dedykowanych dla konkretnych grup odbiorców. Materiały są dostępne dla zalogowanych opiekunów, którzy wykupili udział w wydarzeniu. Dodatkowo portal umożliwia wypromowanie ofert wśród szerokiej grupie odbiorców. Portal i oferty firm są szeroko promowane."
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordionContainer}
          expanded={expanded === "czydodanie"}
          onChange={handleChange("czydodanie")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="czydodanie1bh-content"
            id="czydodanie1bh-header"
          >
            <Typography sx={accordionTitle}>
              {t("HelpIndex.Typography_2a", "Czy dodanie oferty jest płatne?")}
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              {t(
                "HelpIndex.Typography_2b",
                "Portal jest bezpłatny dla rodziców i opiekunów. Mogą oni bezpłatnie przeglądać oferty, wysyłać zapytania i rezerwować usługi. Usługodawcy/firmy organizujące wydarzenia ponoszą opłatę za widoczność swojej oferty. Szczegóły w cenniku. Polecamy sprawdzać na bieżąco nasze oferty i promocje. Publikujemy informacje o nich na naszej stronie i na"
              )}{" "}
              <a
                href="https://www.facebook.com/FajnyCzas/"
                target="_blank"
                rel="noreferrer"
              >
                {t("HelpIndex.Typography_2c", "Facebooku")}
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion
          sx={accordionContainer}
          expanded={expanded === "promocjapakiet"}
          onChange={handleChange("promocjapakiet")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="promocjapakiet1bh-content"
            id="promocjapakiet1bh-header"
          >
            <Typography sx={accordionTitle}>
              Promocja - Pakiet-Na-Fajny-Czas
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              Przy zamówieniu materiałów promocyjnych (10 plakatów i 50 ulotek)
              - w promocyjnej cenie 250 złotych. W prezencie otrzymają Państwo
              ROCZNY dostęp do portalu i swoją własną stronę www.
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        <Accordion
          sx={accordionContainer}
          expanded={expanded === "gdziebedzie"}
          onChange={handleChange("gdziebedzie")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="gdziebedzie1bh-content"
            id="gdziebedzie1bh-header"
          >
            <Typography sx={accordionTitle}>
              {t(
                "HelpIndex.Typography_3a",
                "Gdzie będzie widoczna moja oferta?"
              )}
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              {t(
                "HelpIndex.Typography_3b",
                "Państwa oferta będzie widoczna w bazie ofert Fajnego Czasu. Klienci będą mogli do niej dotrzeć poprzez wyszukiwarkę na naszej stronie a także nasze publikacje w mediach społecznościowych. Tworzymy też treści poradnikowe dla rodziców, aby pomóc w doborze najlepszej oferty, np. zestawienia obozów sportowych. Dodatkowo, stworzymy dla Państwa unikatową stronę internetową."
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion
          sx={accordionContainer}
          expanded={expanded === "jakbedzie"}
          onChange={handleChange("jakbedzie")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="jakbedzie1bh-content"
            id="jakbedzie1bh-header"
          >
            <Typography sx={accordionTitle}>
              Jak będzie wyglądać moja strona?
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              Będzie to strona white label, czyli będą tam Państwa dane, logo i
              tylko Państwa oferta. Strona będzie pod Państwa dedykowanym
              adresem: www.TwojaFirma.FajnyCzas.pl Stworzymy dla Państwa kod QR,
              który łatwo przeniesie klientów z dowolnych materiałów reklamowych
              (fizycznych lub internetowych). Aktualna, czytelna strona to klucz
              do sukcesu.
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        <Accordion
          sx={accordionContainer}
          expanded={expanded === "conastronie"}
          onChange={handleChange("conastronie")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="conastroniebh-content"
            id="conastroniebh-header"
          >
            <Typography sx={accordionTitle}>
              {t(
                "HelpIndex.Typography_4a",
                "Co konkretnie może znaleźć się na mojej stronie www?"
              )}
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              {t(
                "HelpIndex.Typography_4b",
                "Zależy to od Państwa, strona www i system dają wiele możliwości. Można umieścić informacje o swojej firmie, dane kontaktowe, zdjęcia, ofertę wyjazdów, panel informacyjny, wskazówki dojazdowe, dostępność miejsc."
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordionContainer}
          expanded={expanded === "jakczesto"}
          onChange={handleChange("jakczesto")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="jakczestobh-content"
            id="jakczestobh-header"
          >
            <Typography sx={accordionTitle}>
              {t(
                "HelpIndex.Typography_5a",
                "Jak często mogę wprowadzać zmiany na mojej stronie?"
              )}
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              {t(
                "HelpIndex.Typography_5b",
                "Zmiany można wprowadzać na bieżąco, pojawiają się one w ciągu kilku minut. Wprowadzenie zmian nie wymaga znajomości programowania, panel jest intuicyjny i prosty w obsłudze"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordionContainer}
          expanded={expanded === "mamstrone"}
          onChange={handleChange("mamstrone")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="mamstronebh-content"
            id="mamstronebh-header"
          >
            <Typography sx={accordionTitle}>
              {t("HelpIndex.Typography_6a", "Mam już stronę, co wtedy?")}
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              {t(
                "HelpIndex.Typography_6a",
                "If your website is regularly updated, includes a complete offer, and is well-promoted, we can set up a direct redirect to it. If your site needs work or refreshing, we can add a module with your current offers and a booking option. If you only have a domain, we can link it to your page on our platform. We’re happy to advise you on the best solution."
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordionContainer}
          expanded={expanded === "mampytanie"}
          onChange={handleChange("mampytanie")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="mampytaniebh-content"
            id="mampytaniebh-header"
          >
            <Typography sx={accordionTitle}>
              {t("HelpIndex.Typography_7a", "Mam inne pytanie.")}
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              <p>
                {t("HelpIndex.Typography_7b", "Prosimy o kontakt:")}{" "}
                <a href="mailto://Kontakt@FajnyCzas.pl">Kontakt@FajnyCzas.pl</a>
              </p>
              <p>
                {t(
                  "HelpIndex.Typography_7c",
                  "Chętnie odpowiemy na wszelkie pytania."
                )}
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* 
        <Accordion
          sx={accordionContainer}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={accordionTitle}>
              JAK WYBRAĆ ODPOWIEDNIĄ FORMĘ KOLONII DLA DZIECKA?
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              Donec placerat, lectus sed mattis semper, neque lectus feugiat
              lectus, varius pulvinar diam eros in elit. Pellentesque convallis
              laoreet laoreet.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={accordionContainer}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={accordionTitle}>
              JAK PRZYGOTOWAĆ PSYCHICZNIE DZIECKO DO KOLONII?
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={accordionContainer}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={accordionTitle}>
              ILE DAĆ DZIECKU KIESZONKOWEGO?
            </Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Typography sx={accordionText}>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
      </Box>
    </>
  );
};
