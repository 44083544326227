import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputField } from '../../../components/FormCommons'
import { InputAdornment } from '@mui/material'
import { useCurrency } from '../../../hooks/useCurrency'
import { ProductUtils } from 'wakacje-web/src/utils/ProductUtils'

export const CampFeesDiscount = ({ control, currency }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className='mb-2 mt-6 text-normal font-bold'>
        {t('ui.trips.deposit', 'Zaliczka')}
      </div>
      <Controller
        name='advance_price'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const handleValueChange = event => {
            const inputValue = parseFloat(event.target.value)
            if (!isNaN(inputValue)) {
              const multipliedValue = inputValue * 100
              onChange(multipliedValue)
            } else {
              onChange(null)
            }
          }

          return (
            <div className='mb-6'>
              <InputField
                type='number'
                label={t('ui.trips.deposit_per_person', 'Zaliczka / 1 osobę')}
                value={value ? value / 100 : ''}
                onChange={handleValueChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {ProductUtils.getCurrencyCode(currency)}
                    </InputAdornment>
                  )
                }}
              />
            </div>
          )
        }}
      />
    </>
  )
}
