import Button from "@mui/material/Button";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormErrors } from "../../../../components/FormErrors";
import { CompanyDetails } from "../../../../types/company";
import {
  Heading2,
  Heading3,
  Subtitle,
} from "../../../../components/FormCommons";
import {
  companyAdminContactDetailsSchema,
  CompanySectionAdminContactDetailsForm,
} from "../form/CompanySectionAdminContactDetails";
import {
  companyContactDetailsSchema,
  CompanySectionContactDetailsForm,
} from "../form/CompanySectionContactDetails";
import { useTranslation } from "react-i18next";

type SecondStepValues = Pick<
  CompanyDetails,
  | "admin_first_name"
  | "admin_last_name"
  | "admin_title"
  | "admin_phone"
  | "admin_email"
  | "phone"
  | "email"
  | "www"
  | "facebook"
  | "instagram"
>;

const companySecondStepSchema = yup.object().shape({
  ...companyAdminContactDetailsSchema,
  ...companyContactDetailsSchema,
});

export function RegisterCompanySecondStep({ values, onNext, onBack }: any) {
  const { t } = useTranslation();
  const formMethods = useForm<SecondStepValues>({
    resolver: yupResolver(companySecondStepSchema),
    mode: "onBlur",
    defaultValues: values
      ? {
          ...values,
        }
      : {},
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  async function onFormSubmit(data: SecondStepValues) {
    var isValid = await formMethods.trigger();
    if (isValid) {
      onNext(data);
    }
  }

  return (
    <section>
      <Subtitle>
        {t("Register.Organizer", "Rejestracja konta organizatora")}
      </Subtitle>
      <Heading2>{t("Register.Contact", "Dane kontaktowe")}</Heading2>
      <FormProvider {...formMethods}>
        <form className="my-8" onSubmit={handleSubmit(onFormSubmit)}>
          <Heading3>
            {t("Register.Admin_info", "Dane dla administratora")}
          </Heading3>
          <div className="text-gray-500 mb-2">
            {t(
              "Register.Contact_info",
              "Wprowadź dane osoby do kontaktów z FajnyCzas.pl"
            )}
          </div>
          <CompanySectionAdminContactDetailsForm />
          <Heading3>
            {t("Register.participants_info", "Dane dla uczestników")}
          </Heading3>
          <div className="text-gray-500 mb-2">
            {t(
              "Register.Info",
              "Dane te będą widoczne na Twojej stronie i materiałach reklamowych"
            )}
          </div>
          {/* <InputField label="Numer telefonu" {...register("phone")} /> */}
          <CompanySectionContactDetailsForm />
          <FormErrors errors={errors}></FormErrors>
          <div className="text-right">
            <Button className="mx-4" onClick={onBack}>
              {t("back", "Powrót")}
            </Button>

            <Button
              className="mx-4"
              variant="contained"
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              {t("proceed", "Dalej")}
            </Button>
          </div>
        </form>
      </FormProvider>
    </section>
  );
}
