import {
  useForm,
  FormProvider,
  get,
  RegisterOptions,
  FieldPath,
} from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormErrors } from "../../../../components/FormErrors";

import { Product, ProductCategory } from "../../../../types/wakacje-types";
import {
  FormSectionSeparator,
  Heading3,
} from "../../../../components/FormCommons";

import {
  ProductGeneralInfoForm,
  productGeneralInfoSchema,
} from "./ProductGeneralInfo";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { BlueBackground } from "wakacje-web/src/shared/components/BlueBackground";
import { NewProductWizardButtonsBar } from "./NewProductWizardButtonsBar";
import {
  productLocationSchema,
  SchoolTripSectionLocationForm,
} from "./SchoolTripSectionLocation";
import { ProductSectionLocationForm } from "./ProductSectionLocation";
import { ProductTypes } from "wakacje-web/src/utils/ProductUtils";
import { ProductSectionTransportForm } from "./ProductSectionTransport";

export type FirstStepValues = Pick<
  Product,
  "name" | "category" | "min_age" | "max_age" | "description"
> & {
  mainPhoto?: { file: File };
};

export const defaultCategories: ProductCategory[] = [
  {
    id: "kolonie",
    name: "Kolonie",
  },
  {
    id: "polkolonie",
    name: "Półkolonie",
  },
  {
    id: "wyciecza-szkolna",
    name: "Wycieczka Szkolna",
  },
  {
    id: "zielona-szkola",
    name: "Zielona Szkoła",
  },
];

const productFirstPageSchema = Yup.object({
  ...productGeneralInfoSchema,
  ...productLocationSchema,
});

export function NewProductPage1({
  values,
  onSubmit,
  onCancel,
  categories = defaultCategories,
  isSubmitting,
}: any) {
  if (!values.categories) {
    values.categories = [];
  }
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const formMethods = useForm<any>({
    mode: "all",
    resolver: yupResolver(productFirstPageSchema),
    defaultValues: values
      ? {
          ...values,
        }
      : {
          min_age: 1,
          max_age: 20,
          categories: [],
        },
  });

  const {
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = formMethods;

  const productTypeWatch = watch("type");

  const register = (name: FieldPath<any>, options?: RegisterOptions) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false)),
  });

  async function onFormSubmit(data: FirstStepValues) {
    const isValid = await formMethods.trigger();
    if (isValid) {
      setIsSaving(true);
      try {
        await onSubmit(data);
      } catch (err) {}
      setIsSaving(false);
    }
  }

  return (
    <section>
      <FormProvider {...formMethods}>
        <form className="my-8" onSubmit={handleSubmit(onFormSubmit)}>
          <BlueBackground>
            <Heading3 id="h3-1">{t("Booking.Description", "Opis")}</Heading3>
          </BlueBackground>
          <ProductGeneralInfoForm register={register} values={values} />

          <BlueBackground>
            <Heading3 id="h3-1">
              {t("Booking.location", "Lokalizacja")}
            </Heading3>
          </BlueBackground>

          {values.type === ProductTypes.DAYCAMP ? (
            <>
              <ProductSectionLocationForm />
              <ProductSectionTransportForm />
            </>
          ) : (
            <SchoolTripSectionLocationForm />
          )}
          {/* <SchoolTripSectionLocationForm /> */}
          <FormSectionSeparator />
          {/* <ProductSectionTransportForm /> already included in SchoolTripLocationForm */}

          <FormErrors errors={errors} />

          <NewProductWizardButtonsBar
            firstStep
            onCancel={onCancel}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </section>
  );
}
