import { useParams } from "react-router-dom"
import { SchoolTripOfferView } from "./CreateOfferForm/SchoolTripOfferView"

export const SchoolTripOfferPage = ({ product }) => {
  const params = useParams()

  var booking = product?.bookings?.data
    ? product?.bookings.data.find((b) => b.id === params.id)
    : undefined
  return (
    <div className="w-full bg-red">
      <div>Oferta</div>
      {booking && (
        <>
          <SchoolTripOfferView offer={booking} />
        </>
      )}
    </div>
  )
}
