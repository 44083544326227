import { useState } from "react"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"

import { RegisterCompanyFirstStep } from "./register/RegisterCompanyFirstStep"

import { RegisterCompanySecondStep } from "./register/RegisterCompanySecondStep"
import { CompanyRegistrationSummary } from "./CompanySummary"

export const steps: [string, React.ElementType, React.ElementType?][] = [
  ["Szczegóły", RegisterCompanyFirstStep],
  ["Dane kontaktowe", RegisterCompanySecondStep],
  ["Podsumowanie", CompanyRegistrationSummary],
]

export function RegisterCompany() {
  const [stepsData, setStepsData] = useState<any>({})
  const [activeStep, setActiveStep] = useState(0)

  const updateValues = (values) => {
    setStepsData((stepsData: any) => ({
      ...stepsData,
      ...values,
    }))
  }
  const handleNext = (values: any) => {
    setStepsData((stepsData: any) => ({
      ...stepsData,
      ...values,
    }))
    setActiveStep((step) => Math.min(steps.length - 1, step + 1))
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  const handleBack = () => {
    setActiveStep((step) => Math.max(0, step - 1))
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  const handleSubmit = async (values: any) => {}

  const ActiveStepComponent = steps[activeStep][1]
  return (
    <main className="p-4 flex-auto max-w-screen-xl mx-auto">
      <div className="max-w-screen-md mx-auto my-8">
        <div className="my-4">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label[0]}>
                <StepLabel>{label[0]}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        {activeStep === steps.length - 1 ? (
          <ActiveStepComponent
            values={stepsData}
            setActiveStep={setActiveStep}
            onSave={handleSubmit}
            onBack={handleBack}
            updateValues={updateValues}
          />
        ) : (
          <ActiveStepComponent
            key={stepsData}
            values={stepsData}
            onNext={handleNext}
            onBack={handleBack}
          />
        )}
      </div>
    </main>
  )
}
