import { ProductTypes, ProductUtils } from 'wakacje-web/src/utils/ProductUtils'
import { DescriptionList, LabeledField } from '../../../components/FormCommons'
import { EditableFormSection } from '../../../components/forms/EditableFormSection'
import { useProductApi } from '../../organizer/events/api/useProductApi'
import { TripForm } from './TripForm'
import { useTranslation } from 'react-i18next'
import { useCurrency } from '../../../hooks/useCurrency'
import { useProductUtils } from '../../../hooks/useProductUtils'

export const TripDetailsPreview = ({ values }: any) => {
  const { t } = useTranslation()

  const { selectedCurrency } = useCurrency()
  const { formatPriceInCents } = useProductUtils()

  const baseFields = {
    [t('ui.trips.name_info', 'Nazwa turnusu:')]: values?.name,
    [t('ui.trips.date_info', 'Data:')]: `${ProductUtils.formatDate(
      values.start_date
    )} - ${ProductUtils.formatDate(values.end_date)}`,
    [t('ui.trips.available_spots_info', 'Ilość dostępnych miejsc:')]:
      values.available_spots,
    [t('ui.trips.base_price_info', 'Cena bazowa:')]:
      // ProductUtils.formatPriceInCents(values.add_term_payments_base),
      `${formatPriceInCents(
        values.full_price,
        values.currency
      )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`,
    [t('ui.trips.base_price_date_info', 'Termin płatności:')]:
      // ProductUtils.formatDate(values.add_term_payments_base_date),
      ProductUtils.formatDate(values.payment_due_date),
    [t(
      'ui.trips.first_minute_info',
      'Cena first minute:'
    )]: `${formatPriceInCents(
      values.add_term_payments_first_minute,
      values.currency
    )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`,
    [t(
      'ui.trips.first_minute_date_info',
      'Oferta First minute obowiązuje do:'
    )]: ProductUtils.formatDate(values.add_term_payments_first_minute_date),
    [t(
      'ui.trips.last_minute_info',
      'Cena last minute:'
    )]: `${formatPriceInCents(
      values.add_term_payments_last_minute,
      values.currency
    )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`,
    [t('ui.trips.last_minute_date_info', 'Oferta Last minute obowiązuje od:')]:
      ProductUtils.formatDate(values.add_term_payments_last_minute_date),
    [t('ui.trips.deposit_info', 'Zaliczka:')]: `${formatPriceInCents(
      values.advance_price,
      values.currency
    )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
  }
  /**
   * TODO: For now, I'm substituting full_price and payment_due_date for the base items
   * Since we're not changing them on the client app yet, this is to prevent any issues with
   * displaying and adding prices and dates for currently active camps/colonies.
   */

  return (
    <div>
      <DescriptionList data={baseFields} direction='row' />
    </div>
  )
}

export const TripDetailsView = ({ trip, refetch, watch }: any) => {
  const { updateTrip } = useProductApi()
  const { t } = useTranslation()

  const saveHandler = async (data: any) => {
    var productData: any = {
      ...trip,
      ...data
    }
    return updateTrip(data.product_id, productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  return (
    <EditableFormSection
      title={t('ui.trips.term', 'Termin')}
      values={trip}
      onSave={saveHandler}
      onSaved={handleRefresh}
      align='left'
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <TripForm
                values={trip}
                {...builderProps}
                productType={trip.product_type}
                productCurrency={trip?.currency}
              />
            )}
            {!builderProps.isEditing && <TripDetailsPreview values={trip} />}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
