import axios from "axios";
import { useRef, useState } from "react";
import { ApiTable, ApiTableRefType } from "../../../components/table/ApiTable";
import {
  WakacjeChip,
  WakacjeChipVariant,
} from "../../../components/WakacjeChip";
import { ProductTypeChip } from "../../../components/events/ProductTypeChip";
import { Dialog, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils";
import { Link } from "react-router-dom";
import { useCompanyContext } from "../../../contexts/company-context";
import { FormattedDate, FormattedTime } from "react-intl";
import { Close } from "@mui/icons-material";
import KidIcon from "../../../components/icons/KidIcon";
import { CalendarIcon } from "../../../components/icons/CalendarIcon";
import { ParentIcon } from "../../../components/icons/ParentIcon";
import clsx from "clsx";
import { format } from "date-fns";
import { InquirySuperConfirmationPopup } from "../../../components/InquirySuperConfirmationPopup";
import { useCurrency } from "../../../hooks/useCurrency";
import { useProductUtils } from "../../../hooks/useProductUtils";

const listInquiries = async (query = {}) => {
  let searchParams = new URLSearchParams({
    ...query,
    view: "company",
  });

  var url =
    process.env.REACT_APP_WAKACJE_HTTP_API_URL +
    `/inquiries/v1?${searchParams}`;

  var res = await axios.get(url);

  //adjusting types naming from backend to frontend convention, issue to be addressed later
  if (res?.data?.data) {
    for (var inquiry of res.data.data) {
      if (inquiry.type === "SCHOOLTRIP") {
        inquiry.type = "wycieczka-szkolna";
      } else if (inquiry.type === "GROUPACCOMODATION") {
        inquiry.type = "nocleg-dla-grup";
      }
    }
  }

  return res.data;
};

const buildColumns = ({ t, setPopupData, setOpenParticipant }) => {
  var columns = [
    {
      header: t('Booking.Update_Date', 'Data aktualizacji'),
      cell: (row: any) => (
        <>
          <div className="text-xs leading-none">
            {row.created_at && (
              <div>
                <FormattedDate value={row.created_at} />{" "}
                <FormattedTime value={row.created_at} />
              </div>
            )}
          </div>
        </>
      ),
      sort: false,
    },
    {
      header: t("Booking.person", "Osoba"),
      cell: (row: any) => {
        var person = row?.user;
        return (
          <>
            {person && (
              <div
                onClick={() => {
                  setPopupData(row);
                  setOpenParticipant(true);
                }}
              >
                <p className="text-base font-bold underline text-fc-accent-3 hover:no-underline">
                  {person?.name}
                </p>
                <p className="text-xs mt-1">{row.product?.price}</p>
              </div>
            )}
          </>
        );
      },
      sort: false,
    },
    {
      header: t("ui.booking.column5", "Nazwa wydarzenia"),
      cell: (row: any) => (
        <>
          <div className="leading-none pb-2">
            <p className="font-semibold text-xs mb-3">{row.product?.name}</p>
            <ProductTypeChip type={row.type} />
          </div>
        </>
      ),
      sort: false,
    },
    {
      header: t("ui.booking.column6", "Termin"),
      cell: (row: any) => (
        <>
          <div className="leading-none font-bold text-xs">
            <p className="whitespace-nowrap">
              <FormattedDate value={row.start_date} />
              {" - "} <FormattedDate value={row.end_date} />
            </p>
          </div>
        </>
      ),
      sort: false,
      align: "center",
    },
    {
      header: t("ui.groups.persona_number", "Liczba osób"),
      cell: (row: any) => (
        <div className="leading-none text-xs flex justify-center">
          {row?.participants_count}
        </div>
      ),
      sort: false,
      align: "center",
    },
    {
      header: t('Booking.Departure', 'Miejsce wyjazdu'),
      cell: (row: any) => (
        <div className="leading-none text-xs flex justify-center">
          {row?.pickup_place}
        </div>
      ),
      align: "center",
      sort: false,
    },
    {
      header: t("Booking.query_content", "Treść zapytania"),
      cell: (row: any) => (
        <div className="leading-none text-xs max-h-[36px] overflow-hidden text-ellipsis whitespace-nowrap w-[300px]">
          <span>{row?.user_name + ": "}</span>{" "}
          <span className="italic">{row?.content}</span>
        </div>
      ),
      sort: false,
      width: "320px",
      align: "left",
    },
    {
      header: t("ui.booking.column12", "Opcje"),
      cell: (row: any) => (
        <div className="flex gap-1 items-center hover:underline relative bottom-3">
          <img
            src="/svg/chat.svg"
            alt=""
            width="16"
            height="16"
            className="cursor-pointer"
          />
          <Link key={row.chat_hash} to={`/chats/${row.id}`}>
            <p className="text-fc-accent-3">
              {t("Booking.Open_chat", "Otwórz czat")}{" "}
            </p>
          </Link>
        </div>
      ),
      sort: false,
    },
  ];

  return columns;
};

const LabelWithText = ({
  className,
  label,
  children,
  textLarge,
  textBold,
}: {
  className?: string;
  label: string;
  children: any;
  textLarge?: boolean;
  textBold?: boolean;
}) => {
  return (
    <div className={clsx("flex flex-col gap-0.5 px-4", className)}>
      <p className="text-xs text-[#707070] leading-[16px] font-semibold">
        {label}
      </p>
      <p
        className={clsx(
          "leading-[23px]",
          textLarge ? "text-lg" : "text-base",
          textBold && "font-bold"
        )}
      >
        {children}
      </p>
    </div>
  );
};

const DialogParticipant = ({
  open,
  participant,
  booking,
  onClose,
}: {
  open: boolean;
  participant: any;
  booking: any;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const { selectedCurrency } = useCurrency();
  const { formatPriceInCents } = useProductUtils();
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "564px",
          width: "100%",
          borderRadius: "8px",
        },
      }}
      onClose={onClose}
    >
      {participant && (
        <div className="flex flex-col gap-4 pt-6 pb-10 px-10">
          <div className="flex justify-end">
            <div className="cursor-pointer" onClick={onClose}>
              <Close width={16} />
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex items-center px-4 gap-6 h-[96px] bg-[#0559E80D] rounded-lg">
              <KidIcon />
              <div className="flex flex-col">
                <p className="text-lg leading-[22px]">
                  {t("Booking.Participant_data", "Dane uczestnika")}
                </p>
                <p className="text-2xl leading-[38px] font-bold">
                  {participant.first_name} {participant.last_name}
                </p>
                <p className="text-lg leading-[22px]">
                  {new Date().getFullYear() -
                    new Date(participant.birth_date).getFullYear()}{" "}
                  lat
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <LabelWithText
                label="Rok urodzenia"
                className="col-span-2"
                textLarge
              >
                {format(new Date(participant.birth_date), "dd.MM.yyyy")}
              </LabelWithText>
              <LabelWithText label="Kraj">
                {" "}
                {participant.country ?? "Polska"}
              </LabelWithText>
              <LabelWithText label="Województwo">
                {participant.province}
              </LabelWithText>
              <LabelWithText label="Kod pocztowy">
                {" "}
                {participant.postcode}
              </LabelWithText>
              <LabelWithText label="Miejscowość">
                {" "}
                {participant.city}
              </LabelWithText>
              <LabelWithText
                label="Ulica, nr domu i lokalu"
                className="col-span-2"
              >
                {participant.street} {participant.street_number}{" "}
                {participant.house_number}
              </LabelWithText>
              <LabelWithText
                label="Informacje dodatkowe"
                className="col-span-2"
              >
                {"---"}
              </LabelWithText>
            </div>
          </div>
          <Divider />
          <div className="flex flex-col gap-6">
            <div className="flex items-center px-4 gap-6 h-[96px] bg-[#0559E80D] rounded-lg">
              <CalendarIcon />
              <p className="text-2xl leading-[38px] font-bold">
                {t("Booking.reservation", "Rezerwacja")}
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <LabelWithText
                label={t("ui.booking.column9", "Nr rezerwacji")}
                textLarge
              >
                {booking.id}
              </LabelWithText>
              <LabelWithText label={t("ui.booking.column10", "Status")}>
                <WakacjeChip
                  label={booking.status_label ?? booking.status}
                  variant={WakacjeChipVariant.GREEN}
                />
              </LabelWithText>
              <LabelWithText label="Termin płatności">
                {booking.payment_due_date}
              </LabelWithText>
              <LabelWithText label="Płatność" textBold>
                <span className="whitespace-nowrap text-[#5CAA0B]">
                  {`${formatPriceInCents(
                    participant.paid_amount ?? 0,
                    participant?.currency
                  )} ${ProductUtils.getCurrencyCode(
                    selectedCurrency.currency
                  )}`}
                  /
                </span>
                <span className="whitespace-nowrap text-[#5CAA0B]">
                  {booking.total_price
                    ? `${formatPriceInCents(
                        booking.total_price,
                        booking?.currency
                      )} ${ProductUtils.getCurrencyCode(
                        selectedCurrency.currency
                      )}`
                    : ""}
                </span>
              </LabelWithText>
              <LabelWithText label={t("ui.booking.column7", "Miejsce wyjazdu")}>
                {booking.departure_place?.name}
              </LabelWithText>
            </div>
          </div>
          <Divider />
          <div className="flex flex-col gap-6">
            <div className="flex items-center px-4 gap-6 h-[96px] bg-[#0559E80D] rounded-lg">
              <ParentIcon />
              <div className="flex flex-col">
                <p className="text-lg leading-[22px]">
                  {t("Booking.Camp_supervisor", "Dane opiekuna")}
                </p>
                <p className="text-2xl leading-[38px] font-bold">
                  {participant.parent.first_name} {participant.parent.last_name}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <LabelWithText label="Telefon" textLarge>
                {participant.parent.phone}
              </LabelWithText>
              <LabelWithText label="E-mail" textLarge>
                {participant.parent.email}
              </LabelWithText>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export const InquiryTable = ({
  queryParams,
}: {
  queryParams: any;
  layout?: string;
}) => {
  const { t } = useTranslation();
  const tableRef = useRef<ApiTableRefType>();
  const companyContext = useCompanyContext();

  const [openParticipant, setOpenParticipant] = useState(false);
  const [popupData, setPopupData] = useState(undefined);

  var columns = buildColumns({
    t,
    setOpenParticipant,
    setPopupData,
  });

  const lqueryParams = {
    ...queryParams,
    company_id: companyContext.company?.id,
  };
  return (
    <>
      <ApiTable
        ref={tableRef}
        columns={columns}
        listApi={listInquiries}
        queryParams={lqueryParams}
        showExpandColumn
        rowDetailsRenderer={() => <></>}
      />
      {openParticipant && (
        <InquirySuperConfirmationPopup
          data={popupData}
          setOpenParticipant={setOpenParticipant}
          open={openParticipant}
        />
      )}
      {/* {popupData && (
        <DialogParticipant
          open={!!popupData}
          participant={popupData?.participant}
          booking={popupData?.booking}
          onClose={() => setPopupData(undefined)}
        />
      )} */}
    </>
  );
};
