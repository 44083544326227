import {
  Button,
  Checkbox,
  ListItemText,
  InputAdornment,
  Select,
  InputLabel
} from "@mui/material"
import {
  DescriptionList,
  Heading3,
  InputField,
  SmallCaptionText
} from "../../../../components/FormCommons"
import MenuItem from "@mui/material/MenuItem"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../events/api/useProductApi"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { ProductTypes, ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

import { FieldError } from "../../../../components/FieldError"
import { TransportType } from "../../../../types/wakacje-types"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const TransportRow = ({
  index,
  register,
  onRemove,
  value,
  ...props
}) => {
  const { t } = useTranslation()
  function handleScroll(e) {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <div className="flex flex-row mb-1 items-center">
      <div className="flex-1">
        <InputField
          label="Miejsce wyjazdu*"
          {...register(`pickup_route.${index}.address`)}
        />
      </div>
      <div className=" ml-3">
        <InputField
          type="number"
          label="Cena*"
          InputProps={{
            endAdornment: <InputAdornment position="end">zł</InputAdornment>,
            onWheel: handleScroll,
            onTouchMove: handleScroll
          }}
          {...register(`pickup_route.${index}.price`)}
        />
      </div>
      <div className="w-36 ml-3">
        <InputField
          select
          label={t("ui.groups.confirmed", "Potwierdzone")}
          SelectProps={{
            defaultValue: value?.pickup_route?.status
          }}
          {...register(`pickup_route.${index}.status`)}
        >
          <MenuItem value="yes">{t("ui.groups.yes", "Tak")}</MenuItem>
          <MenuItem value="no">{t("ui.groups.no", "Nie")}</MenuItem>
        </InputField>
      </div>
      <div className="ml-1">
        <CancelOutlinedIcon onClick={onRemove} />
      </div>
    </div>
  )
}

export const ProductSectionTransportForm = ({ errors }: any) => {
  const { t } = useTranslation()
  const { watch, getValues, register, control, setValue, trigger } =
    useFormContext<any>()
  const values = getValues()
  const { type } = values

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "pickup_route" // unique name for your Field Array
  })

  const toggleTransportType = (type, value) => {
    var newValues = values?.transport_types ?? []
    if (value) {
      newValues.push({ type })
    } else {
      newValues = newValues.filter((v) => v.type !== type)
    }
    setValue("transport_types", newValues)
  }

  const transport_types_watch = watch("transport_types")
  const transport_vehicle_watch = watch("transport_vehicle")
  const pickup_route_watch = watch("pickup_route")

  const transportProvided = transport_types_watch?.some(
    (t) => t.type === "provided"
  )

  useEffect(() => {
    if (!transportProvided) {
      setValue("transport_vehicle", null)
      setValue("pickup_route", [])
    }
  }, [transport_types_watch])

  useEffect(() => {
    trigger()
  }, [transport_types_watch, transport_vehicle_watch, pickup_route_watch])

  return (
    <>
      <Heading3 id="h3-1">{t("ui.groups.transport_type", "Dojazd")}</Heading3>
      <SmallCaptionText>
        <span className="text-[#7E7E7E]">{t("ui.groups.transport_method", "Wybierz sposób dojazdu*")}</span>
      </SmallCaptionText>
      <FieldError label={errors?.transport_type?.message} />

      <Controller
        name="transport_types"
        control={control}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => {
          const hasTransportType = (type) => {
            var types = field.value ?? []
            var found = types.find((t) => t.type === type)
            return !!found
          }
          return (
            <>
              <div className="flex flex-row items-center">
                <Checkbox
                  checked={hasTransportType(TransportType.own)}
                  onChange={(ev) => {
                    toggleTransportType(TransportType.own, ev.target.checked)
                  }}
                />
                <ListItemText primary={t("Booking.own", "Własny")} />
              </div>

              {/* Show transport option for camp and schooltrip  */}
              {type !== ProductTypes.DAYCAMP && (
                <>
                  <div className="flex flex-row gap-3">
                    <div className="flex flow-row items-center">
                      <Checkbox
                        checked={hasTransportType(TransportType.provided)}
                        onChange={(ev) => {
                          toggleTransportType(
                            TransportType.provided,
                            ev.target.checked
                          )
                        }}
                      />
                      <ListItemText primary={t("Booking.organized", "Zorganizowany")} />
                    </div>

                    {transport_types_watch && transportProvided && (
                      <div className="w-[220px]">
                        <InputField
                          select
                          label="Rodzaj transportu*"
                          SelectProps={{
                            defaultValue: values?.transport_vehicle || ""
                          }}
                          {...register("transport_vehicle")}
                        >
                          <MenuItem value="bus">Autokar</MenuItem>
                          <MenuItem value="plane">Samolot</MenuItem>
                          <MenuItem value="train">Pociąg</MenuItem>
                        </InputField>
                      </div>
                    )}
                  </div>

                  {/* add place of departure */}
                  {transportProvided &&
                    transport_vehicle_watch &&
                    transport_vehicle_watch !== "" && (
                      <div>
                        <div className="flex flex-row mt-3">
                          <div className="mx-auto">
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => {
                                append({ address: "", price: "" })
                              }}
                            >
                              + Dodaj miejsce wyjazdu
                            </Button>
                          </div>
                        </div>
                        {fields &&
                          fields.map((field, index) => {
                            return (
                              <TransportRow
                                key={field.id}
                                index={index}
                                register={register}
                                value={field}
                                onRemove={() => remove(index)}
                              />
                            )
                          })}
                      </div>
                    )}
                </>
              )}
            </>
          )
        }}
      />

      {values?.transport_types?.length &&
        values?.transport_types[0]?.type === "own" && (
          <div className="mt-4">
            <Heading3>
              {t(
                "product.own_transport_tips",
                "Informacje dotyczące dojazdu własnego"
              )}
            </Heading3>
            <InputField
              label={t("Booking.Description", "Opis")}
              {...register("own_transport_details", {
                required: false
              })}
              multiline
              minRows={4}
            />
          </div>
        )}
      <div className="mt-4">
        <Heading3>
          {t(
            "product.organised_transport_tips",
            "Informacje dotyczące dojazdu"
          )}
        </Heading3>
        <InputField
          label={t("Booking.Description", "Opis")}
          {...register("location.driving_directions", {
            required: false
          })}
          multiline
          minRows={4}
        />
      </div>
    </>
  )
}

export const ProductSectionTransportPreview = ({ values, t }: any) => {
  return (
    <div>
      <DescriptionList
        data={{
          Dojazd: ProductUtils.getTransportTypeLabel(values.transport_type, t),
          "Rodzaj Transportu": ProductUtils.getVehicleTypeLabel(
            values.transport_vehicle
          ),
          Opis: values.transport_description
        }}
      />
    </div>
  )
}

export const ProductSectionTransportView = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()

  const saveHandler = async (data: any) => {
    var productData = {
      id: values.id,
      transport_description: data.transport_description,
      transport_vehicle: data.transport_vehicle,
      transport_type: data.transport_type,
      transport_types: data.transport_types,
      pickup_route: data.pickup_route,
      own_transport_details: data.own_transport_details
    }
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  const _values = { ...values, pickup_route: values.pickup_route ?? [] }
  return (
    <EditableFormSection
      title="Dojazd"
      values={_values}
      onSave={saveHandler}
      onSaved={handleRefresh}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionTransportForm {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <ProductSectionTransportPreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
