import { useTranslation } from "react-i18next"
import { DescriptionList } from "../../../../components/FormCommons"
import { useCategories } from "../../../../components/useCategories"
import {
  ProductUtils,
  PRODUCT_TYPE_SCHOOL_TRIP,
} from "wakacje-web/src/utils/ProductUtils"
import { ProductSectionPhotosPreview } from "./ProductSectionPhotos"
import { getLocalizedKey } from "../../../../utils/getLocalizedKey"
import { useParticipantLanguages } from "../../../../hooks/useParticipantLanguages"

const months = [
  { name: "Styczeń", en_name: "January", value: 1 },
  { name: "Luty", en_name: "February", value: 2 },
  { name: "21Marzec", en_name: "March", value: 3 },
  { name: "Kwiecień", en_name: "April", value: 4 },
  { name: "Maj", en_name: "May", value: 5 },
  { name: "Czerwiec", en_name: "June", value: 6 },
  { name: "Lipiec", en_name: "July", value: 7 },
  { name: "Sierpień", en_name: "August", value: 8 },
  { name: "Wrzesień", en_name: "September", value: 9 },
  { name: "Październik", en_name: "October", value: 10 },
  { name: "Listopad", en_name: "November", value: 11 },
  { name: "Grudzień", en_name: "December", value: 12 }
]

export function ProductGeneralInfoPreview({
  values,
  jumpTo
}: {
  values: any
  jumpTo: (step: number, elementQuery: string) => void
}) {
  const { i18n } = useTranslation()
  const lang = i18n.language

  const { t: t1 } = useTranslation("common")
  const { categoriesByCode } = useCategories(t1)

  const { t } = useTranslation()
  const nameKey = getLocalizedKey("name")
  const descriptionKey = getLocalizedKey("description")
  const categoriesMerged = values.categories
    ? values.categories
        .filter((s) => s.indexOf("lokalizacja") === -1)
        .map((s) => categoriesByCode[s] ?? s)
        .join(", ")
    : "-"

  const allParticipantsLanguages = useParticipantLanguages()
  const participantsLanguagesIds = values.participants_languages || []

  const participantsLanguagesMerged = participantsLanguagesIds
    .map((languageId) => {
      const language = allParticipantsLanguages.find(
        (lang) => lang.id === languageId
      )
      return language ? language.name : null
    })
    .filter(Boolean)
    .join(", ")

  const descriptions = {}
  if (values.type === PRODUCT_TYPE_SCHOOL_TRIP.value) {
    descriptions[t("product.trip_name", "Nazwa wycieczki")] = values[nameKey]
    descriptions[t("product.trip_description", "Opis wycieczki")] =
      values[descriptionKey]
    descriptions[t("product.trip_type", "Rodzaj wycieczki")] =
      ProductUtils.getTripTypeLabel(values.trip_type)

    descriptions[t("product.days_count", "Liczba dni")] = values.duration_days

    descriptions[t("product.trip_categories", "Tematyka zajęć")] =
      categoriesMerged
  } else {
    descriptions[t("product.event_name", "Nazwa wydarzenia")] = values[nameKey]
    descriptions[t("product.categories", "Kategorie")] = categoriesMerged
    descriptions[t("product.participants_languages", "Języki uczestników")] =
      participantsLanguagesMerged
    descriptions[t("product.product_description", "Opis oferty")] =
      values[descriptionKey]
  }

  const availableAllYear =
    values.availability && values.availability.length === 12

  const getMonthName = (mo: number, lang: string) => {
    const month = months.find((m) => m.value === mo);
    return lang === "pl" ? month?.name ?? mo : month?.en_name ?? mo;
  };

  return (
    <section>
      <section className="mb-8">
        <DescriptionList data={descriptions} />
      </section>

      <>
        <section className="mb-8">
          <div className="px-4 mb-8">
            <div className="text-sm text-gray-500 font-medium">
              {t("product.participants_age", "Wiek uczestników")}
            </div>
            <div>
              <div>
                {t("product.age_from", "Od lat:")} {values.min_age}
              </div>
              <div>
                {t("product.age_till", "Do lat:")} {values.max_age}
              </div>
            </div>
          </div>
          {values.type === PRODUCT_TYPE_SCHOOL_TRIP.value && (
            <div className="px-4">
              <div className="text-sm text-gray-500 font-medium">
                {t("product.availability", "Dostępność")}
              </div>
              {availableAllYear ? (
                <>{t("product.availability_whole_year", "cały rok")}</>
              ) : (
                <>
                  {values.availability &&
                    values.availability
                      .map((av: any) => getMonthName(av, lang))
                      .join("; ")}
                </>
              )}
            </div>
          )}
        </section>
      </>

      <section className="mb-8 mx-4">
        <div className="text-sm text-gray-500 font-medium">
          {t("product.photos", "Zdjęcia")}
        </div>
        <ProductSectionPhotosPreview values={values} />
      </section>
    </section>
  )
}
