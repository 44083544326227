import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { ProductStatus } from 'wakacje-web/src/utils/ProductUtils'

interface WakacjeChipProps {
  label: string
  variant?: any
  showStatus?: boolean
  type?: string
}
const _ChipVariants: any = {
  GREEN: {
    textColor: 'var(--wakacje-action-color-green);',
    bgColor: '#f4f4f4'
  },
  ORANGE: {
    textColor: 'var(--wakacje-action-color-orange);',
    bgColor: '#f4f4f4'
  },
  RED: {
    textColor: 'var(--wakacje-action-color-red);',
    bgColor: '#f4f4f4'
  },
  BLUE: {
    textColor: 'var(--wakacje-color-blue);',
    bgColor: '#f4f4f4'
  },
  DEFAULT: {
    textColor: 'black',
    bgColor: 'grey'
  }
}
export enum WakacjeChipVariant {
  GREY = 'GREY',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED',
  BLUE = 'BLUE'
}

export function WakacjeChip (props: WakacjeChipProps) {
  const { t } = useTranslation()
  const variant =
    (props.variant ? _ChipVariants[props.variant] : _ChipVariants.DEFAULT) ??
    _ChipVariants.DEFAULT

  let statusText = ''
  let statusColor = ''

  if (props.showStatus) {
    switch (props.label) {
      case ProductStatus.DRAFT:
        statusText = t('ui.products.draft') // WakacjeChip
        statusColor = 'bg-black'
        break
      case ProductStatus.SUSPENDED:
        statusText = t('ui.products.suspended')
        statusColor = 'bg-gray-300'
        break
      case ProductStatus.ACTIVE:
        statusText = t('ui.products.active')
        statusColor = 'bg-lime-600'
        break
      case ProductStatus.ARCHIVED:
        statusText = t('ui.products.archived')
        statusColor = 'bg-[#B8B8B8]'
        break

      default:
        statusText = props.label
        statusColor = 'bg-gray-300'
        break
    }

    return (
      <div className='flex items-center'>
        <span
          className={`inline-block w-3 h-3  rounded-full ${statusColor}`}
        ></span>
        <p className='ml-3'>{statusText}</p>
      </div>
    )
  } else {
    return (
      <Box
        px={1}
        py={1}
        borderRadius={50}
        color={variant.textColor}
        bgcolor={variant.bgColor}
        display='inline'
        fontSize={10}
        fontWeight='bold'
        sx={{
          textTransform: 'uppercase',
          whiteSpace: 'nowrap'
        }}
      >
        {props.label}
      </Box>
    )
  }
}
