import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputField } from '../../../components/FormCommons'
import { InputAdornment } from '@mui/material'
import { useCurrency } from '../../../hooks/useCurrency'
import { ProductUtils } from 'wakacje-web/src/utils/ProductUtils'

export const CampFeesFirstMinute = ({ control, currency }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className='mb-2 mt-6 text-normal font-bold'>
        {t('ui.trips.first_minute_price', 'Cena first minute')}
      </div>
      <div className='mt-1 text-sm font-light'>
        {t(
          'ui.trips.first_minute_description',
          'Cena promocyjna obowiązująca na początku publikacji oferty. Można edytować dowolnym momencie.'
        )}
      </div>

      <div className='flex flex-col md:flex-row gap-2'>
        <Controller
          name='add_term_payments_first_minute'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const handleValueChange = event => {
              const inputValue = parseFloat(event.target.value)
              if (!isNaN(inputValue)) {
                const multipliedValue = inputValue * 100
                onChange(multipliedValue)
              } else {
                onChange(null)
              }
            }

            return (
              <InputField
                type='number'
                label={t('ui.trips.price_per_person', 'Cena / 1 osobę')}
                value={value ? value / 100 : ''}
                onChange={handleValueChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {ProductUtils.getCurrencyCode(currency)}
                    </InputAdornment>
                  )
                }}
              />
            )
          }}
        />

        <Controller
          name={'add_term_payments_first_minute_date'}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const val =
              value != null && value.length > 10 ? value.substr(0, 10) : value
            return (
              <InputField
                type='date'
                onChange={onChange}
                value={val}
                InputLabelProps={{ shrink: true }}
                label={t('ui.trips.offer_to', 'Oferta obowiązuje do')}
                id={`add_term_payments_first_minute_date`}
              />
            )
          }}
        />
      </div>
    </>
  )
}
