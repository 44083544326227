import { debounce } from "@mui/material"
import FilterIcon from "../icons/FilterIcon"
import { MojeTextInput } from "../ui/MojeTextInput"
import {useTranslation} from "react-i18next";

export const SearchBox = ({
  setSearchTextDebounced,
  setSearchValue,
  label
}: {
  setSearchTextDebounced: any
  setSearchValue?: any
  label?: string
}) => {
  const { t } = useTranslation()
  const handleChange = (ev) => {
    setSearchTextDebounced(ev.target.value)
    if(setSearchValue) {
      debounce(setSearchValue(ev.target.value), 500)
    }
  }

  return (
    <div className="flex items-center gap-3">
      <div>{label ?? `${t("ui.attractions.search", "Szukaj")}:`}</div>
      <div>
        <MojeTextInput
          margin="none"
          onChange={(ev) => handleChange(ev)}
          size="small"
        />
      </div>
      {/* <button>
        <FilterIcon />
      </button> */}
    </div>
  )
}
