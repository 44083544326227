import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import * as Yup from "yup"
import {
    DescriptionList,
    FormHelperText, InputField
} from "../../../../components/FormCommons"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { getLocalizedKey } from "../../../../utils/getLocalizedKey"
import { useProductApi } from "../../events/api/useProductApi"
import { FormSectionContainer } from "./components/FormSectionContainer"
import {useTranslation} from "react-i18next";

export const productSectionInsuranceFormSchema = {
  insurance_type: Yup.string().required("Ubezpieczenie jest wymagana")
}

export const ProductSectionInsuranceForm = ({ register, control }: any) => {
  const { t } = useTranslation()
  const { watch } = useFormContext()
  const watchInsuranceType = watch("insurance_type")
  const insuranceDescriptionKey = getLocalizedKey("insurance_description")

  return (
    <FormSectionContainer sectionTitle={t("product.insurance", "Ubezpieczenie")}>
      <div className="mb-4">
        <FormHelperText>
            {`${t("ui.groups.insurance_question", "Czy w cenie wyjazdu zapewnione jest ubezpieczenie dla uczestników?")}*`}
        </FormHelperText>
      </div>
      <Controller
        name="insurance_type"
        control={control}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <RadioGroup {...field}>
            <div className="flex flex-row">
              <FormControlLabel
                label={t("Booking.yes", "Tak")}
                value="tak"
                control={<Radio value="tak" />}
              />
              <FormControlLabel
                label={t("Booking.no", "Nie")}
                value="nie"
                control={<Radio value="nie" />}
              />
            </div>
          </RadioGroup>
        )}
      />
      {watchInsuranceType === "tak" && (
        <>
          <InputField
            label={t("Booking.Description", "Opis")}
            multiline
            minRows={3}
            value={watch(insuranceDescriptionKey)}
            {...register(insuranceDescriptionKey)}
          />
        </>
      )}
    </FormSectionContainer>
  )
}

export const ProductSectionInsurancePreview = ({ values }: any) => {
  const { t } = useTranslation()
  const insuranceDescriptionKey = getLocalizedKey("insurance_description")

  return (
    <DescriptionList
      data={{
        [t("product.insurance", "Ubezpieczenie")]: values.insurance_type,
        [t("Booking.Description", "Opis")]: values[insuranceDescriptionKey]
      }}
    />
  )
}

export const ProductSectionInsuranceView = ({
  values,
  refetch,
  watch
}: any) => {
  const { t } = useTranslation()

  const { updateProduct } = useProductApi()

  const insuranceDescriptionKey = getLocalizedKey("insurance_description")

  const saveHandler = async (data: any) => {
    var productData: any = {
      id: data.id,
      insurance_description: data[insuranceDescriptionKey],
      insurance_type: data.insurance_type
    }
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  return (
    <EditableFormSection
      title={t("product.insurance", "Ubezpieczenie")}
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionInsuranceForm
                values={values}
                watch={watch}
                {...builderProps}
              />
            )}
            {!builderProps.isEditing && (
              <ProductSectionInsurancePreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
