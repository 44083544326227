import { Button } from "@mui/material"
import { useState } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { InputField, SectionTitle } from "../../../../components/FormCommons"
import { ItemRow } from "../../../../pages/organizer/products/forms/ProductSectionPlace/ItemRow"
import PlaceOrAccommodationModal from "../../../../pages/organizer/products/forms/ProductSectionPlace/PlaceOrAccommodationModal"

import { SectionContainer } from "./CreateOfferForm"

function FormSectionPlaces() {
  const [openPlaceModal, setOpenPlaceModal] = useState(false)
  const handleOpenPlaceModal = () => setOpenPlaceModal(true)
  const handleClosePlaceModal = () => setOpenPlaceModal(false)

  const { register, watch, control, setValue } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "places" // unique name for your Field Array
  })

  const handleItemSelected = (newItems) => {
    for (var i = 0; i < newItems.length; i++) {
      append({
        place: {
          id: newItems[i].id,
          name: newItems[i].name
        }
      })
    }
    handleClosePlaceModal()
  }

  const handleDeleteItem = (item) => {
    const newItem = fields.filter((p: any) => p.place.id !== item.id)
    setValue("places", newItem)
  }

  return (
    <SectionContainer>
      <SectionTitle>Atrakcje</SectionTitle>

      {fields.map((field, index) => (
        <ItemRow
          key={field.id}
          index={index}
          register={register}
          watch={watch}
          control={control}
          onRemove={() => remove(index)}
          type="place"
          isIndividualTrip
        />
      ))}

      <div className="flex justify-center mt-5">
        <Button
          className="mx-4"
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleOpenPlaceModal}
        >
          Dodaj atrakcje
        </Button>
      </div>

      <PlaceOrAccommodationModal
        type="place"
        open={openPlaceModal}
        handleClose={handleClosePlaceModal}
        disabledItems={[]}
        onItemsSelected={handleItemSelected}
        handleDeleteItem={handleDeleteItem}
      />
    </SectionContainer>
  )
}

export default FormSectionPlaces
