import Link from "next/link"
import React from "react"
import { useFileUploadedContext } from "../../../contexts/FileUploadedContext"
import { ProductUtils } from "../../../utils/ProductUtils"

import { HomeSectionContact } from "../../../white/home/HomeSectionContact"
import { BookingStatusChip } from "../../booking/BookingStatusChip"
import SideAttachedFile from "../../booking/SideAttachedFile"

function CompanySideWidget({
  company,
  booking
}: {
  company: any
  booking?: any
}) {
  const { fileUploaded } = useFileUploadedContext()
  return (
    <div className="">
      <div className="flex items-center gap-3">
        {company.logo_image_url && (
          <Link
            href={company.fc_website_url ? company.fc_website_url : "#"}
            passHref
          >
            <a className=" cursor-pointer">
              <div className="w-[48px]">
                <img
                  src={company.logo_image_url}
                  alt={company.name}
                  width="94"
                />
              </div>
            </a>
          </Link>
        )}
        <Link
          href={company.fc_website_url ? company.fc_website_url : "#"}
          passHref
        >
          <a className=" cursor-pointer">
            <div className="font-bold text-[32px] leading-none">
              {company.name}
            </div>
          </a>
        </Link>
      </div>
      <div className="mt-2 mb-16">
        {booking && <BookingStatusChip booking={booking} />}
      </div>

      {booking && (
        <>
          <div className="font-bold">Oferta</div>
          <div className="text-base text-fc-primary">
            <Link href={`/profile/events/${booking?.id}`}>
              <a className="text-fc-accent-3 underline hover:no-underline hover:text-fc-accent-3">
                {booking?.product?.name}
              </a>
            </Link>
          </div>
          <div className="text-gray-500">
            {ProductUtils.formatDate(booking.start_date)}
            {booking.end_date && (
              <>
                {" - "}
                {ProductUtils.formatDate(booking.end_date)}
              </>
            )}
          </div>
        </>
      )}

      {!booking && <HomeSectionContact company={company} isChatWithCompany />}

      {!booking && (
        <>
          <div className="pb-2 font-bold mt-10">Organizujemy</div>
          <div>
            {company.operation_types && (
              <div className="flex flex-row  flex-wrap ">
                {company.operation_types.map((op: any) => (
                  <div
                    key={op}
                    className="text-fc-accent-3 font-bold text-xxs uppercase  px-3 py-2  rounded-full mr-4 whitespace-nowrap mb-1 bg-[#F9F9F9]"
                    style={{
                      color: ProductUtils.productTypeColor(op)
                    }}
                  >
                    {ProductUtils.typeLabel(op, true)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default CompanySideWidget
