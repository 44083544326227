import axios from 'axios'
import {Close} from "@mui/icons-material";
import {SectionTitle} from "../../../components/FormCommons";
import {Button, CircularProgress, Dialog} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {Box} from "@mui/system";
import {useTranslation} from "react-i18next";
import {ReservationPriceField} from "./ReservationDialogFields/ReservationPriceField";
import {useSnackbar} from "notistack";
import {type Company} from "../../../contexts/company-context";
import {ReservationSelectField} from "./ReservationDialogFields/ReservationSelectField";

type BookingData = {
    product_id: string;
    product_name: string;
    company_id: string;
    company_name: string;
    trip_id: string;
    trip_name: string;
    start_date: string;
    end_date: string;
    total_price: number;
    discount_amount: number;
    payable_price: number;
    advance_payment_price: number;
    original_total_price: number;
}

interface EditBookingProps {
    open: boolean;
    onClose: () => void;
    bookingData?: any;
    companies: Company[];
    products: any[];
    trips: any[];
}

export const EditBooking = ({ open, onClose, bookingData, companies, products, trips }: EditBookingProps) => {
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.up('md'))
    const { enqueueSnackbar } = useSnackbar()

    const { t } = useTranslation()

    const [isSaving, setIsSaving] = useState(false)

    const formMethods = useForm({
        mode: 'onBlur',
        defaultValues: {
            product_id: bookingData?.product_id ?? '',
            product_name: bookingData?.product_name ?? '',
            company_id: bookingData?.company_id ?? '',
            company_name: bookingData?.company_name ?? '',
            trip_id: bookingData?.trip_id ?? '',
            trip_name: bookingData?.trip_name ?? '',
            start_date: bookingData?.start_date ?? '',
            end_date: bookingData?.end_date ?? '',
            total_price: bookingData?.total_price ?? 0,
            discount_amount: bookingData?.discount_amount ?? 0,
            payable_price: bookingData?.payable_price ?? 0,
            advance_payment_price: bookingData?.advance_payment_price ?? 0,
            original_total_price: bookingData?.original_total_price ?? 0,
        }
    })

    const {
        handleSubmit,
        formState: { errors, isValid },
        control,
        setValue,
        reset,
        watch
    } = formMethods

    const companyID = watch("company_id")
    const productID = watch("product_id")
    const tripID = watch("trip_id")

    const handleSave = async (data: BookingData) => {
        const {
            product_id,
            product_name,
            company_id,
            company_name,
            trip_id,
            trip_name,
            start_date,
            end_date,
            total_price,
            discount_amount,
            payable_price,
            advance_payment_price,
            original_total_price,
        } = data

        const newData: BookingData = {
            product_id,
            product_name,
            company_id,
            company_name,
            trip_id,
            trip_name,
            start_date,
            end_date,
            total_price,
            discount_amount,
            payable_price,
            advance_payment_price,
            original_total_price,
        }

        const bookID = bookingData?.id;
        if (bookID == null || bookID.length === 0) {
            enqueueSnackbar(t('error', 'Nieprawidłowy identyfikator rezerwacji.'), {
                variant: 'error'
            })
            return
        }

        const product = products.find((product) => product.id === product_id)
        if (product) {
            newData.product_name = product.name
        }

        const company = companies.find((company) => company.id === company_id)
        if (company) {
            newData.company_name = company.name
        }

        const trip = trips.find((trip) => trip.id === data.trip_id)
        if (trip) {
            newData.trip_name = trip.name
            newData.start_date = trip.start_date
            newData.end_date = trip.end_date
        }


        const url = process.env.REACT_APP_WAKACJE_HTTP_API_URL + `/bookings/v2.0/booking/${bookID}`

        await axios.post(url, {
            ...newData
        })
    }

    const onFormSubmit = async (data: any) => {
        if (isSaving) {
            return
        }

        const isValid = await formMethods.trigger()

        if (!isValid) {
            return
        }

        try {
            setIsSaving(true)

            await handleSave(data)

            enqueueSnackbar(t('ui.trips.success_save', 'Zapisano!'), {
                variant: 'success'
            })

            setIsSaving(false)
            onClose()
        } catch (err) {
            setIsSaving(false)

            console.log('Error saving...')
            enqueueSnackbar(
                t('ui.trips.error_save', 'Problem z zapisem ') + (err as any)?.message,
                {
                    variant: 'error'
                }
            )
        }
    }

    useEffect(() => {
        reset({
            product_id: bookingData?.product_id ?? '',
            company_id: bookingData?.company_id ?? '',
            trip_id: bookingData?.trip_id ?? '',
            start_date: bookingData?.start_date ?? '',
            end_date: bookingData?.end_date ?? '',
            total_price: bookingData?.total_price ?? 0,
            discount_amount: bookingData?.discount_amount ?? 0,
            payable_price: bookingData?.payable_price ?? 0,
            advance_payment_price: bookingData?.advance_payment_price ?? 0,
            original_total_price: bookingData?.original_total_price ?? 0
        })
    }, [bookingData, reset])

    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: md ? '564px' : '300px',
                    width: '100%',
                    borderRadius: '8px'
                }
            }}
            onClose={onClose}
        >
            {
                <div className='flex flex-col gap-4 pt-6 pb-10 px-10'>
                    <div className=''>
                        <div className=' flex justify-end cursor-pointer' onClick={onClose}>
                            <Close width={16} />
                        </div>
                        <section>
                            <SectionTitle useBlueBackground={false}>
                                {t("superAdminBookings.edit_reservation", "Edytuj rezerwacje")}
                            </SectionTitle>
                            <form onSubmit={handleSubmit(onFormSubmit)} className='p-2'>
                                <ReservationSelectField
                                    label={t("superAdminBookings.select_company", "Wybierz firme")}
                                    name="company_id"
                                    control={control as any}
                                    setValue={setValue}
                                    fieldID={companyID}
                                    data={companies}
                                />

                                <ReservationSelectField
                                    label={t("superAdminBookings.select_product", "Wybierz produkt")}
                                    name="product_id"
                                    control={control as any}
                                    setValue={setValue}
                                    fieldID={productID}
                                    data={companyID ? products.filter((product) => product.company_id === companyID) : products}
                                />

                                <ReservationSelectField
                                    label={t("superAdminBookings.select_trip", "Wybierz turnus")}
                                    name="trip_id"
                                    control={control as any}
                                    setValue={setValue}
                                    fieldID={tripID}
                                    data={productID ? trips.filter((trip) => trip.product_id === productID) : trips}
                                />

                                <ReservationPriceField
                                    label={t("superAdminBookings.total_price", "Cena całkowita")}
                                    name="total_price"
                                    control={control as any}
                                    setValue={setValue}
                                    price={bookingData?.total_price}
                                />

                                <ReservationPriceField
                                    label={t("superAdminBookings.discount_amount", "Kwota rabatu")}
                                    name="discount_amount"
                                    control={control as any}
                                    setValue={setValue}
                                    price={bookingData?.discount_amount}
                                />

                                <ReservationPriceField
                                    label={t("superAdminBookings.payable_price", "Cena do zapłaty")}
                                    name="payable_price"
                                    control={control as any}
                                    setValue={setValue}
                                    price={bookingData?.payable_price}
                                />

                                <ReservationPriceField
                                    label={t("superAdminBookings.advance_payment_price", "Cena zaliczki")}
                                    name="advance_payment_price"
                                    control={control as any}
                                    setValue={setValue}
                                    price={bookingData?.advance_payment_price}
                                />

                                <ReservationPriceField
                                    label={t("superAdminBookings.original_total_price", "Cena oryginalna")}
                                    name="original_total_price"
                                    control={control as any}
                                    setValue={setValue}
                                    price={bookingData?.original_total_price}
                                />

                                <div className="flex flex-row mb-4 mt-4">
                                    <Button
                                        onClick={() => {
                                            onClose()
                                        }}
                                    >
                                        {t("cancel", "Anuluj")}
                                    </Button>
                                    <Box flex={1} />
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSaving || !isValid}
                                    >
                                        {isSaving ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : (
                                            t("save", "Zapisz")
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </section>
                    </div>
                </div>
            }
        </Dialog>
    )
}
