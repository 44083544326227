import axios from 'axios'
import { SuperAdminPromoCodesDialog } from './SuperAdminPromoCodesDialog'
import { HandleSavePromoCodeProps, PromoCodeProps } from './AddPromoCode'

export const EditPromoCode = ({ open, promoCodeData, onClose }) => {
  const handleSave = async (data: HandleSavePromoCodeProps) => {
    const {
      unlimited,
      uses_available,
      percentage_value,
      currency,
      price_value,
      code,
      valid_to,
      id,
      product_id
    } = data

    const updatedPromoCode: PromoCodeProps = {
      code,
      valid_to,
      percentage_value: percentage_value ? percentage_value : null,
      price_value: price_value ? price_value * 100 : null,
      currency: currency ? currency : null,
      uses_available: unlimited ? -1 : uses_available,
      product_id: product_id ? product_id : null,
      id
    }

    const url = process.env.REACT_APP_WAKACJE_API_URL + `/promo-codes/v1.0`

    await axios.put(url, {
      ...updatedPromoCode
    })
  }

  return (
    <SuperAdminPromoCodesDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSave}
      promoCodeData={promoCodeData}
      headerTitle={'Edytuj kod rabatowy'}
    />
  )
}
