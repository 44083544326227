import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CloudinaryImageUploadAdapter } from 'ckeditor-cloudinary-uploader-adapter'
import '../../../customEditor.css'

function MyCustomUploadAdapterPlugin (editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    const cloudName = 'www-fajnyczas-pl'
    const unsignedUploadPreset = 'ml_default'
    return new CloudinaryImageUploadAdapter(
      loader,
      cloudName,
      unsignedUploadPreset
    )
    // return new MyUploadAdapter(loader)
  }
}

// // Plugins to include in the build.
// Editor.builtinPlugins = [
//   Autoformat,
//   BlockQuote,
//   Bold,
//   CKFinder,
//   CKFinderUploadAdapter,
//   CloudServices,
//   Essentials,
//   Heading,
//   Image,
//   ImageCaption,
//   ImageStyle,
//   ImageToolbar,
//   ImageUpload,
//   Indent,
//   Italic,
//   Link,
//   List,
//   MediaEmbed,
//   Paragraph,
//   PasteFromOffice,
//   Table,
//   TableToolbar,
//   TextTransformation,
//   Base64UploadAdapter,
//   SourceEditing,
// ];
function CustomEditor ({ value, onChange }: any) {
  // const config={ {
  //     plugins: [ Paragraph, Bold, Italic, Essentials ],
  //     // toolbar: [ 'bold', 'italic' ]
  // } }

  const config = {
    extraPlugins: [MyCustomUploadAdapterPlugin]
  }
  return (
    <div className='py-5 editor'>
      <CKEditor
        config={config}
        editor={ClassicEditor}
        data={value}
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          //
          //   editor.plugins.get("FileRepository").createUploadAdapter = (
          //     loader
          //   ) => {
          //
          //     return new MyUploadAdapter(loader)
          //   }
        }}
        onChange={(event, editor) => {
          const data = editor.getData()
          onChange(data)
        }}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
      />
    </div>
  )
}

export default CustomEditor
