import { Route, Routes, useNavigate } from "react-router-dom";
import { InquiryTable } from "./InquiryTable";
import { PageTitle } from "../../../components/typography";
import { useTranslation } from "react-i18next";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import BookingsToolbar from "./BookingsToolbar";

const IndexTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(undefined);

  const [query, setQuery] = useState("");

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      path: "products",
      label: t("ui.booking.inquiry_list", "Lista zapytań"),
      icon: <MapOutlinedIcon />,
    },
  ];

  const queryParams: any = {};
  if (selectedProduct) {
    queryParams.product_id = selectedProduct.id;
  }
  if (query) {
    queryParams.query = query;
  }

  return (
    <div className="p-8">
      <div className="my-4">
        <div className="px-8">
          <PageTitle gutterBottom>
            <div className="">{t("ui.menu.inquiries", "Zapytania")}</div>
          </PageTitle>
        </div>
        <p className="text-base mt-2 px-8">
          {t(
            "Booking.Check",
            "Sprawdź nowe zapytania. Bądź w bezpośrednim kontakcie z Klientem."
          )}
        </p>
      </div>
      <div className="p-8">
        <Tabs
          value={tabIndex}
          aria-label="organizatorze tab"
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              onClick={() => navigate(`/inquiries`)}
              label={
                <div className="flex justify-center items-center">
                  <div className="mr-1">{tab["icon"]}</div>
                  {tab["label"] ?? tab["path"]}
                </div>
              }
              key={tab["path"]}
            />
          ))}
        </Tabs>
        {tabIndex === 0 && (
          <>
            <BookingsToolbar
              setSearchTextDebounced={setQuery}
              api={() => {}}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
            />
            <div className="-mt-2">
              <InquiryTable queryParams={queryParams} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const InquiryPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<IndexTable />} />
      </Routes>
    </>
  );
};
