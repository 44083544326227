import axios from "axios"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react"
import { parse, isAfter, formatDistance, differenceInDays } from "date-fns"
import { pl, enUS } from "date-fns/locale"

export interface Company {
  id: string
  name: string
  description: string
  created_at: string
  created_by_user_id: string
  owner_user_id: string
  nip: string
  regon: string
  address_string: string
  city: string
  postcode: string
  // bank
  bank_account: string
  bank_name: string
  bank_verified: string
  //
  logo_image: any
  logo_image_url: string
  admin_first_name: string
  admin_last_name: string
  admin_position: string
  admin_phone: string
  admin_email: string
  // Company
  phone: string
  email: string
  www: string
  facebook: string
  instagram: string
  license_type: string
  license_valid_from: string
  license_valid_till: string
  archived_at: string
  validated_at: string
  validated_by_user_id: string
  fc_website_url: string
  fc_website_prefix: string
}

export interface OnboardingStatus {
  companyCompleted?: boolean
  posterCompleted?: boolean
  offerCompleted?: boolean
  completed: boolean // whole onboarding completed
}
export interface CompanyContextType {
  company?: Company
  homeData?: {
    unreadMessagesCount: number
    reservationsToApprove: number
  }
  refreshHomeData: Function
  setCompany: Function
  onboardingProgress: number
  onboardingStatus: OnboardingStatus
  initializationCompleted: boolean
  initializationError?: string
  isActiveMerchant: boolean
  licenseRemainingTimeLabel: string
  licenseRemainingTimeDays: number
}

// const LOCAL_STORAGE_KEY = "fajnyCzasCompanyContext"

export const CompanyContext = createContext<CompanyContextType>({
  refreshHomeData: () => {},
  setCompany: (company: any) => {},
  onboardingStatus: {
    completed: false
  },
  initializationCompleted: false,
  onboardingProgress: 0,
  isActiveMerchant: false,
  licenseRemainingTimeLabel: "",
  licenseRemainingTimeDays: 0
})

export const CompanyContextProvider = ({ children, lang }: { children: any; lang: string }) => {
  const [company, setCompany] = useState(undefined)
  const [isInitializationCompleted, setIsInitializationCompleted] =
    useState<boolean>(false)
  const [initializationError, setInitializationError] = useState<any>()
  const [homeData, setHomeData] = useState()
  const [useAdminMode, setUseAdminMode] = useState(false)

  useEffect(() => {
    refreshHomeData()
    // eslint-disable-next-line
  }, [])

  const refreshHomeData = async () => {
    let homeUrl = process.env.REACT_APP_WAKACJE_API_URL + `/v1/home`
    if (useAdminMode) {
      homeUrl += "?admin_company_id=" + company.id
    }
    try {
      var result = await axios({
        method: "GET",
        url: homeUrl
      })

      if (result && result.status === 200) {
        if (useAdminMode) {
          setIsInitializationCompleted(true)
          if (result.data?.company?.id === company.id) {
            setCompany(result.data.company)
          }
          return
        }
        setHomeData(result.data)
        if (!useAdminMode) {
          setCompany(result.data.company)
        } else {
        }
        setIsInitializationCompleted(true)
      } else {
        setInitializationError("Problem z połączeniem - odśwież stronę")
        setHomeData(undefined)
      }
    } catch (err) {
      console.error("Error get homedata")
    }
  }

  // const _persist = useCallback(() => {
  //   if (window.localStorage) {
  //     //   if (currentUser) {
  //     //     const data = {
  //     //       currentUser,
  //     //     }
  //     //     window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
  //     //   } else {
  //     //     window.localStorage.removeItem(LOCAL_STORAGE_KEY)
  //     //   }
  //   }
  // }, [company])

  const onboardingStatus: OnboardingStatus = {
    completed: false
  }
  var onboardingProgress = 0
  if (company) {
    onboardingStatus.companyCompleted = true
    onboardingProgress = 1
  }

  const isActiveMerchant =
    company?.license_type &&
    (!company.license_valid_till ||
      isAfter(
        parse(company.license_valid_till, "yyyy-MM-dd", new Date()),
        new Date()
      ))
  // calculate contract
  const licenseRemainingTimeLabel = company?.license_valid_till
    ? formatDistance(
        new Date(),
        parse(company.license_valid_till, "yyyy-MM-dd", new Date()),
        {
          locale: lang === "pl" ? pl : enUS
        }
      )
    : "czas nieokreślony"
  const licenseRemainingTimeDays = company?.license_valid_till
    ? differenceInDays(
        parse(company.license_valid_till, "yyyy-MM-dd", new Date()),
        new Date()
      )
    : undefined

  const contextValue: CompanyContextType = {
    company,
    setCompany: (newCompany: any) => {
      setUseAdminMode(true)
      setCompany(newCompany)
    },
    initializationCompleted: isInitializationCompleted,
    homeData,
    refreshHomeData,
    onboardingStatus,
    initializationError,
    onboardingProgress,
    isActiveMerchant,
    licenseRemainingTimeLabel,
    licenseRemainingTimeDays
  }
  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  )
}

export const useCompanyContext = (): CompanyContextType =>
  useContext(CompanyContext) as CompanyContextType
