import { FcTabPanel, FcTabsHeader } from "../../../components/FcTabs/FcTabs";
import { SuperAdminLeads } from "./SuperAdminLeads";
import { SuperAdminPosters } from "./SuperAdminPosters";
import { useTabs } from "../useTabs";
import { SuperAdminSettings } from "./SuperAdminSettings";
import { useTranslation } from "react-i18next";
export const SuperAdminOthers = () => {
  const { selectedTab, handleTabChange } = useTabs();
  const { t } = useTranslation();
  return (
    <>
      <h1 className="text-[32px] font-bold">
        {t("SuperAdmin.Others", "Inne")}
      </h1>
      <FcTabsHeader
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        tabs={["Leady", "Plakaty", "Ustawienia"]}
      />

      <div className="w-full">
        <FcTabPanel selectedTab={selectedTab} index={0}>
          <SuperAdminLeads />
        </FcTabPanel>

        <FcTabPanel selectedTab={selectedTab} index={1}>
          <SuperAdminPosters />
        </FcTabPanel>

        <FcTabPanel selectedTab={selectedTab} index={2}>
          <SuperAdminSettings />
        </FcTabPanel>
      </div>
    </>
  );
};
