import { useTranslation } from "react-i18next";
import {
  DescriptionList,
  Heading3,
  InputField,
} from "../../../../components/FormCommons";
import diacritics from "diacritics";
import slugify from "slugify";
import { IMaskInput, IMaskMixin } from "react-imask";

import { EditableFormSection } from "../../../../components/forms/EditableFormSection";
import { useCompanyApi } from "../../events/api/useCompanyApi";
import {
  UploadablePhoto,
  buildPhoto,
} from "../../products/forms/ProductSectionPhotos";
import { forwardRef, useCallback, useEffect, useState } from "react";
import {debounce, MenuItem, TextField} from "@mui/material";
import { MojeTextInput } from "../../../../components/ui/MojeTextInput";
import axios from "axios";
import {useTranslatedStatesAndCountries} from "../../../../hooks/useTranslatedStatesAndCountries";
import {countries} from "../../products/countries";

export const TextMaskPostCode = forwardRef(function TextMaskCustom(
  props: any,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
const PostCodeInput: any = IMaskMixin(({ inputRef, ...props }: any) => {
  return (
    <MojeTextInput
      variant="outlined"
      name={props.name}
      id={props.id}
      label={props.label}
      fullWidth={props.fullWidth}
      value={props.value}
      forceValidEndorsement={props.forceValidEndorsement}
      inputRef={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
    />
  );
});

export const CompanySectionGeneralForm = ({
  register,
  watch,
  setValue,
  trigger,
}: any) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language
  const [isCalculatingSlug, setIsCalculatingSlug] = useState(false);
  const handlePhotoUpdated = (photo) => {
    setValue("logo_image", buildPhoto(photo));
    return true;
  };
  const postCodeVal = watch("postcode");
  const postCodeValid = /\d{2}-\d{3}/.test(postCodeVal);
  const postCodeReg = register("postcode");
  const fcWebsitePrefix = register("fc_website_prefix");
  const websiteUrlSuggestionWatch = watch("website_url_suggestion");
  const nameWatch = watch("name");
  const fcWebsiteWatch = watch("fc_website_prefix");
  const logoImageValue = watch("logo_image");
  const country = watch("country");

  const debouncedWebsite = useCallback(
    debounce(async (name) => {
      if (name && name.length > 0) {
        setIsCalculatingSlug(true);
        try {
          let url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/website/slug`;
          var result = await axios({
            method: "POST",
            data: {
              name: name,
            },
            url,
          });
          if (result.status === 200) {
            setValue("fc_website_prefix", result.data.url);
          }
        } catch (err) {}
        setIsCalculatingSlug(false);
      }
    }, 1000),
    []
  );

  useEffect(() => {

    if (nameWatch) {
      var normalized = diacritics.remove(
        slugify(nameWatch, {
          lower: true,
        })
      );
      setValue("website_url_suggestion", normalized);
    } else {
      setValue("website_url_suggestion", "");
    }
  }, [nameWatch]);

  useEffect(() => {
    debouncedWebsite(websiteUrlSuggestionWatch);
  }, [websiteUrlSuggestionWatch]);

  return (
    <>
      <div className="flex gap-4 items-center">
        <InputField
          className="w-1/2"
          label="NIP"
          {...register("nip")}
          autoComplete="off"
        />
      </div>
      <InputField
        label={`${t("superAdminBookings.company_name", "Nazwa firmy")}*`}
        {...register("name", {
          required: true,
        })}
        autoComplete="off"
      />
      <InputField
        label={t("superAdminBookings.site_name", "Nazwa strony")}
        placeholder={t("superAdminBookings.site_name_placeholder", "Nazwa strony (link do strony)")}
        {...register("website_url_suggestion", {
          required: true,
        })}
        autoComplete="off"
      />

      <InputField
        label={t("superAdminBookings.company_description", "Opis firmy (widoczny dla klientów)")}
        {...register("description", {
          required: true,
        })}
        autoComplete="off"
        multiline
        minRows={6}
      />
      <TextField
        select
        margin='normal'
        label={t("Booking.Country", "Kraj")}
        variant="outlined"
        className="bg-white"
        fullWidth
        {...register("country")}
        size="small"
        name="country"
        value={country}
        required
      >
        {countries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
                {lang === "pl" ? country.name_pl : country.name_en }
            </MenuItem>
        ))}
      </TextField>
      <InputField label={`${t("Booking.City", "Miasto")}*`} {...register("city")} autoComplete="off" />
      <PostCodeInput
        name={postCodeReg.name}
        id={postCodeReg.id}
        error={postCodeReg.error}
        onBlur={postCodeReg.onBlur}
        value={postCodeVal}
        ref={postCodeReg.ref}
        mask="00-000"
        label={`${t("ui.groups.post_code", "Kod pocztowy")}*`}
        autoComplete="off"
        onAccept={(val) => {
          setValue("postcode", val);
          trigger("postcode");
        }}
        forceValidEndorsement={postCodeValid}
        fullWidth
      />
      <InputField
        label={`${t("superAdminBookings.address", "Adres")}*`}
        {...register("address_string")}
        autoComplete="off"
      />
      <Heading3>{t("Company.logo", "Logo firmy")}</Heading3>
      <div className="w-20 h-20 rounded-full my-2 bg-gray-100 cursor:pointer overflow-hidden">
        <UploadablePhoto
          onPhotoUploaded={handlePhotoUpdated}
          photo={logoImageValue}
        />
      </div>
    </>
  );
};

export const CompanySectionGeneralPreview = ({ values }: any) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  return (
    <div>
      <DescriptionList
        data={{
          [t("superAdminBookings.company_name", "Nazwa firmy")]: values.name,
          NIP: values.nip,
          [t("add_second_step.address", "Adres")]: values.address_string,
          [t("superAdminBookings.company_description", "Opis firmy")]: values.description,
          [t("Booking.Country", "Kraj")]: countries.find(
            (c) => c.code === values.country
          )?.[lang === "pl" ? "name_pl" : "name_en"] || values.country,
          [t("Booking.City", "Miasto")]: values.city,
        }}
      />
      <section className="my-8 px-4">
        <dt className="text-sm text-gray-500 font-medium mb-2">
          {t("Company.logo", "Logo firmy")}
        </dt>
        {values.logo_image?.url ? (
          <dd className="mx-4">
            <img
              className="w-32 h-32 rounded-full object-cover"
              src={values.logo_image?.url}
              alt="Logo"
            />
          </dd>
        ) : (
          <div>{t("Company.not_selected", "Nie wybrano")}</div>
        )}
      </section>
    </div>
  );
};

export const CompanySectionGeneral = ({
  values,
  refetch,
  onSave,
}: {
  onSave?: Function;
  values: any;
  refetch: Function;
}) => {
  const { t } = useTranslation();
  const { updateCompany } = useCompanyApi();
  const [val, setValue] = useState(values);

  const saveHandler = async (data: any) => {
    var updateData: any = {
      id: data.id,
      nip: data.nip,
      country: data.country,
      city: data.city,
      postcode: data.postcode,
      address_string: data.address_string,
      logo_image: data.logo_image,
      fc_website_prefix: data.fc_website_prefix,
      description: data.description,
      name: data.name,
    };
    if (onSave) {
      return onSave(data);
    }
    return updateCompany(updateData);
  };

  const handleRefresh = () => {
    refetch();
  };

  useEffect(() => {
    if (val.id !== values.id) {
      setValue(values);
    }
  }, [values]);

  const update = (data: object) => {
    setValue(data);
  };
  return (
    <EditableFormSection
      title={t("Register.Basics", "Dane podstawowe")}
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      onUpdate={update}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <CompanySectionGeneralForm values={val} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <CompanySectionGeneralPreview values={val} />
            )}
          </div>
        );
      }}
    </EditableFormSection>
  );
};
