import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PaymentsTable } from "../../pages/organizer/payments/PaymentsTable";
import { useTranslation } from "react-i18next";

export interface IPackagePaymentSectionProps {}

interface ITablePaymentProps {
  children: React.ReactNode;
}

interface ITableHeadProps {
  title1?: string;
  title2?: string;
  title3?: string;
  className?: string;
  fontBold?: boolean;
}

interface ITableBodyItemProps {
  packageName: string;
  qouta: string;
  paymentDate: string;
  onClick?: (props: any) => void;
}

interface PaymentProps {
  name: string;
  kwota: string;
  termin: string;
}

const payments: PaymentProps[] = [
  {
    name: "Pakiet standard",
    kwota: "350",
    termin: "07.06.2021",
  },
  {
    name: "Pakiet full+",
    kwota: "650",
    termin: "08.06.2022",
  },
];

export function PackagePaymentSection(props: IPackagePaymentSectionProps) {
  const { t } = useTranslation();
  return (
    <div className="mt-5 font-sans">
      {/* TODO: Not implemented yet <TablePayment>
        <TableHead title1='Następna płatność' title2='Kwota' title3='Termin płatności'/>
        <TableBodyItem 
          packageName='Pakiet Standard 07.07.2021' 
          qouta='350,00 zł'
          paymentDate='07.07.2021'
        />
      </TablePayment> */}

      <div className="w-full mt-8">
        <h4 className="font-bold">
          {t("Booking.History_payment", "Historia płatności")}
        </h4>
        <PaymentsTable type="COMPANY_PACKAGE_ORDER" />
        {/* TODO: Not implemented yet <div className="flex space-y-2 md:space-y-0 md:space-x-4 mt-3 font-sans flex-wrap py-4 md:py-0">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Zaznacz wszystkie"
            />
          </FormGroup>
          <div className="flex space-x-1 items-center cursor-pointer">
            <DownloadIcon />
            <p>Pobierz zaznaczone</p>
          </div>
        </div> */}

        {/* <div className="w-full mt-6 flex justify-between">
          <div className="w-[80%]">
            <HistoryPayment>
              <HistoryPaymentHead title2="Kwota" title3="Termin płatności" />
              {payments?.map((payment) => (
                <HistoryPaymentItem
                  key={payment.termin}
                  packageName={payment.name}
                  qouta={payment.kwota}
                  paymentDate={payment.termin}
                />
              ))}
            </HistoryPayment>
          </div>
          <div className="w-[15%] flex space-x-2">
            <DownloadIcon className="text-fc-accent-3" />
            <VisibilityIcon className="text-fc-accent-3" />
          </div>
        </div> */}
      </div>

      <hr className="border-[#E6EEFD] w-full mt-3" />
    </div>
  );
}

export function TablePayment({ children }: ITablePaymentProps) {
  return <div className="overflow-x-scroll md:overflow-auto ">{children}</div>;
}

export function TableHead({
  title1,
  title2,
  title3,
  className,
  fontBold,
}: ITableHeadProps) {
  return (
    <div className="grid grid-cols-[80%_30%_30%_40%] md:grid-cols-[40%_20%_20%_20%]">
      <div>
        <h4 className="font-bold">{title1}</h4>
      </div>
      <div>
        <h4 className="font-bold">{title2}</h4>
      </div>
      <div>
        <h4 className="font-bold">{title3}</h4>
      </div>
      <div></div>
    </div>
  );
}

export function TableBodyItem({
  packageName,
  qouta,
  paymentDate,
  onClick,
}: ITableBodyItemProps) {
  return (
    <div className="w-full grid grid-cols-[80%_30%_30%_40%] md:grid-cols-[40%_20%_20%_20%] mt-2 items-center">
      <p>{packageName}</p>
      <p>{qouta}</p>
      <p>{paymentDate}</p>
      <button className="text-white bg-fc-accent-3 py-3 rounded-lg">
        Opłać
      </button>
    </div>
  );
}

export function HistoryPayment({ children }: ITablePaymentProps) {
  return <TablePayment>{children}</TablePayment>;
}

export function HistoryPaymentHead({ title2, title3 }: ITableHeadProps) {
  return (
    <div className="grid grid-cols-[80%_30%_30%_40%] md:grid-cols-[40%_20%_20%_20%]">
      <div />
      <div>
        <h4>{title2}</h4>
      </div>
      <div>
        <h4>{title3}</h4>
      </div>
      <div></div>
    </div>
  );
}

export function HistoryPaymentItem({
  packageName,
  qouta,
  paymentDate,
  onClick,
}: ITableBodyItemProps) {
  return (
    <div className="w-full grid grid-cols-[80%_30%_30%_40%] md:grid-cols-[40%_20%_20%_20%] items-center">
      <FormGroup>
        <FormControlLabel control={<Checkbox />} label={packageName} />
      </FormGroup>
      <p>{qouta}</p>
      <p>{paymentDate}</p>
    </div>
  );
}
