import { MenuItem } from "@mui/material"
import { InputField } from "../FormCommons"
import { useTranslation } from "react-i18next"

export const TimeSelectDropdown = (props) => {
  // var options = []
  // for (var i = 0; i < 23; i++) {
  //   options.push({ value: i * 100, label: i + ":00" })
  //   options.push({ value: i * 140, label: i + ":30" })
  // }

  const { t } = useTranslation()

  return (
    <div>
      <InputField
        label={`${t("time_selection_dropdown.trip_place", "Miejsce wyjazdu")}*`}
        {...props}
        select
      >
        {/* {options.map((o: any) => (
          <MenuItem value={o.value} key={o.value}>
            {o.label}
          </MenuItem>
        ))} */}
        <MenuItem value={0}>0:00</MenuItem>
        <MenuItem value={30}>0:30</MenuItem>
        <MenuItem value={100}>1:00</MenuItem>
        <MenuItem value={130}>1:30</MenuItem>
        <MenuItem value={200}>2:00</MenuItem>
        <MenuItem value={230}>2:30</MenuItem>
        <MenuItem value={300}>3:00</MenuItem>
        <MenuItem value={330}>3:30</MenuItem>
        <MenuItem value={400}>4:00</MenuItem>
        <MenuItem value={430}>4:30</MenuItem>
        <MenuItem value={500}>5:00</MenuItem>
        <MenuItem value={530}>5:30</MenuItem>
        <MenuItem value={600}>6:00</MenuItem>
        <MenuItem value={630}>6:30</MenuItem>
        <MenuItem value={700}>7:00</MenuItem>
        <MenuItem value={730}>7:30</MenuItem>
        <MenuItem value={800}>8:00</MenuItem>
        <MenuItem value={830}>8:30</MenuItem>
        <MenuItem value={900}>9:00</MenuItem>
        <MenuItem value={930}>9:30</MenuItem>
        <MenuItem value={1000}>10:00</MenuItem>
        <MenuItem value={1030}>10:30</MenuItem>
        <MenuItem value={1100}>11:00</MenuItem>
        <MenuItem value={1130}>11:30</MenuItem>
        <MenuItem value={1200}>12:00</MenuItem>
        <MenuItem value={1230}>12:30</MenuItem>
        <MenuItem value={1300}>13:00</MenuItem>
        <MenuItem value={1330}>13:30</MenuItem>
        <MenuItem value={1400}>14:00</MenuItem>
        <MenuItem value={1430}>14:30</MenuItem>
        <MenuItem value={1500}>15:00</MenuItem>
        <MenuItem value={1530}>15:30</MenuItem>
        <MenuItem value={1600}>16:00</MenuItem>
        <MenuItem value={1630}>16:30</MenuItem>
        <MenuItem value={1700}>17:00</MenuItem>
        <MenuItem value={1730}>17:30</MenuItem>
        <MenuItem value={1800}>18:00</MenuItem>
        <MenuItem value={1830}>18:30</MenuItem>
        <MenuItem value={1900}>19:00</MenuItem>
        <MenuItem value={1930}>19:30</MenuItem>
        <MenuItem value={2000}>20:00</MenuItem>
        <MenuItem value={2030}>20:30</MenuItem>
        <MenuItem value={2100}>21:00</MenuItem>
        <MenuItem value={2130}>21:30</MenuItem>
        <MenuItem value={2200}>22:00</MenuItem>
        <MenuItem value={2230}>22:30</MenuItem>
        <MenuItem value={2300}>23:00</MenuItem>
        <MenuItem value={2330}>23:30</MenuItem>
      </InputField>
    </div>
  )
}
