import { useEffect, useState } from "react"
import MuiTable from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { TableCellProps } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import IconButton from "@mui/material/IconButton"
import { Tooltip } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { Box } from "@mui/system"
import MenuItemsTable from "./MenuItemsTable"
import { Link } from "react-router-dom"

export type TableProps = React.ComponentPropsWithRef<"section"> & {
  rows?: any
  columns: Array<any>
  showExpandColumn?: boolean
  rowLinkBuilder?: Function
  rowDetailsRenderer?: any
  onRowClicked?: any
  rowPopupBuilder?: any
  childrenTable?: boolean
  nestedTableComponent?: any
  onHeaderClicked?: Function
  sort?: {
    field: string
    direction: string
  }
  selectedRow?: any
  prefix?: String
}

const TableHeadCell = (props: TableCellProps) => (
  <TableCell
    className={`font-bold text-xs text-gray-500 border-0 `}
    component="th"
    scope="column"
    {...props}
  />
)

export default function Table({
  rows = [],
  columns,
  showExpandColumn = false,
  rowLinkBuilder,
  rowDetailsRenderer,
  onRowClicked,
  rowPopupBuilder,
  childrenTable,
  nestedTableComponent,
  onHeaderClicked,
  sort,
  selectedRow,
  prefix,
  ...props
}: TableProps) {
  const [expandedRowId, setExpandedRowId] = useState<any>()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openedPopoverId, setOpenedPopoverId] = useState(null)

  const COLUMNS_WIDTH = columns.length? (100/columns.length).toString() + "%" : "auto" 

  const handleClickAnchor = (
    id,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
    setOpenedPopoverId(id)
  }


  const handleCloseAnchor = () => {
    setAnchorEl(null)
    setOpenedPopoverId(null)
  }

  useEffect(() => {
    setExpandedRowId(selectedRow)
  }, [selectedRow])

  if (!childrenTable) {
    return (
      <TableContainer className="my-4">
        <MuiTable
          // sx={prefix === "camp" || prefix === "daycamp" ? { width: "100%" } : { width: "100%" }}
        >
          <TableHead>
            <TableRow
              className="rounded-lg"
              sx={{ bgcolor: "rgba(5, 89, 232, 0.03)" }}
            >
              {columns.map((col, index) => (
                <TableHeadCell
                  key={index}
                  onClick={() => {
                    if (onHeaderClicked) {
                      onHeaderClicked(col)
                    }
                  }}
                  align={col.align ?? "left"}
                  width={col.width ?? "auto"}
                >
                  {col.header}
                  {col.field && sort && (
                    <Tooltip title="sort">
                      <>
                        {sort?.field === col.field && (
                          <>
                            {sort.direction === "desc" ? (
                              <ArrowDropDownIcon />
                            ) : (
                              <ArrowDropUpIcon />
                            )}
                          </>
                        )}
                      </>
                    </Tooltip>
                  )}
                </TableHeadCell>
              ))}

              {rowDetailsRenderer && <TableHeadCell />}
              {showExpandColumn && <TableHeadCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              Array.isArray(rows) &&
              rows.map((row: any, index: number) => {
                const rowLink = rowLinkBuilder ? rowLinkBuilder(row) : ""
                const rowPopupBuilderComponent =
                  rowPopupBuilder != null ? rowPopupBuilder(row) : undefined
                return (
                  <>
                    <TableRow
                      className="hover:bg-[#fafafb] transition-all cursor-pointer group"
                      key={index}
                      onClick={() => {
                        if (onRowClicked) {
                          onRowClicked(row, index)
                        }
                      }}
                    >
                      {columns.map((col, index) => {
                        var cellContent = (
                          <div className="p-2">
                            {col.cell
                              ? col.cell(row, {
                                  expanded: expandedRowId === row.id
                                })
                              : col.field
                              ? row[col.field as string]
                              : ""}
                          </div>
                        )
                        return (
                          <TableCell
                            align={col.align ?? "left"}
                            key={index}
                            padding="none"
                            className=""
                            // width={col.width ?? COLUMNS_WIDTH}
                          >
                            {rowLink ? (
                              <Link to={rowLink}>{cellContent}</Link>
                            ) : (
                              cellContent
                            )}
                          </TableCell>
                        )
                      })}
                      {/* Arrow button has moved to parent component (ProductsTable) */}
                      {rowDetailsRenderer && (
                        <TableCell>
                          {/* <IconButton
                            aria-label="go"
                            sx={{ color: "#111111" }}
                            onClick={(event: any) => {
                              event.preventDefault()

                              setExpandedRowId(
                                expandedRowId === row.id ? undefined : row.id
                              )
                            }}
                          >
                            <KeyboardArrowDown />
                          </IconButton> */}
                        </TableCell>
                      )}
                      {showExpandColumn && (
                        <TableCell>
                          {rowPopupBuilderComponent && (
                            <IconButton
                              aria-label="go"
                              sx={{ color: "#111111" }}
                              onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                              ) => handleClickAnchor(row.id, e)}
                            >
                              <img
                                className="shrink-0 min-w-[16px]"
                                src="/assets/svg/kebab-menu.svg"
                                alt=""
                              />
                            </IconButton>
                          )}
                        </TableCell>
                      )}

                      {rowPopupBuilderComponent && (
                        <MenuItemsTable
                          key={row.id}
                          anchorEl={anchorEl}
                          open={openedPopoverId === row.id}
                          handleCloseAnchor={handleCloseAnchor}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "center"
                          }}
                          transformOrigin={{
                            vertical: "center",
                            horizontal: "center"
                          }}
                        >
                          {rowPopupBuilderComponent}
                        </MenuItemsTable>
                      )}
                    </TableRow>
                    {expandedRowId === row.id && rowDetailsRenderer && (
                      <TableRow>
                        <TableCell colSpan={columns.length + 2}>
                          <Box pl={4}>{rowDetailsRenderer({ row })}</Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )
              })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    )
  }

  if (childrenTable) {
    return (
      <TableContainer className="my-0">
        <MuiTable>
          <TableHead>
            <TableRow
              className="rounded-lg group"
              sx={{
                background: "#F8F9FA",
                borderRadius: "8px"
              }}
            >
              {columns.map((col, index) => (
                <TableHeadCell
                  key={index}
                  onClick={() => {
                  }}
                  align={col.align ?? "left"}
                  sx={{
                    background: "none",
                    border: "none",
                    padding: "4px 10px",
                    color: "#7E7E7E"
                  }}
                >
                  {col.header}
                  {/* {col.sort && (
                    <Tooltip title="sort">
                      <>
                        {sortData ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                      </>
                    </Tooltip>
                  )} */}
                </TableHeadCell>
              ))}

              {rowDetailsRenderer && <TableHeadCell />}
              {showExpandColumn && <TableHeadCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const rowLink = rowLinkBuilder ? rowLinkBuilder(row) : ""
              return (
                <TableRow
                  key={index}
                  className=" hover:bg-[#fafafb] transition-all"
                >
                  {columns &&
                    columns.map((col, index) => {
                      var cellContent = (
                        <div className="p-3">
                          {col.cell
                            ? col.cell(row, {
                                expanded: expandedRowId === row.id
                              })
                            : col.field
                            ? row[col.field as string]
                            : ""}
                        </div>
                      )
                      return (
                        <TableCell
                          sx={{ border: "none" }}
                          align={col.align ?? "left"}
                          key={index}
                          padding="none"
                        >
                          {rowLink ? (
                            <Link to={rowLink}>{cellContent}</Link>
                          ) : (
                            cellContent
                          )}
                        </TableCell>
                      )
                    })}
                </TableRow>
              )
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    )
  }
}
