import axios from "axios"
import { useState } from "react"

const uploadToS3 = async (signedUrl, file) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest()
    var fd = new FormData()
    xhr.open("PUT", signedUrl, true)
    xhr.upload.addEventListener("progress", function (e) {
      console.log(`fileuploadprogress data.loaded: ${e.loaded},
      data.total: ${e.total}`)
      // const percentLoaded = Math.round((100 * e.loaded) / e.total);
    })

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully
        resolve({
          url: signedUrl,
        })
      }
    }
    fd.append("file", file)
    xhr.send(file)
  })
}
export const useFileUpload = () => {
  const [isUploading, setIsUploading] = useState(false)

  const uploadFile = async (req, file) => {
    var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/getUploadCode`

    var res = await axios.post(url, req)
    if (res.status === 200) {
      const { signedUrl, key, bucket } = res.data
      var uploadResult = await uploadToS3(signedUrl, file)
      return {
        key,
        bucket,
      }
    } else {
      setIsUploading(false)
      throw new Error("Error uploading file")
    }
  }

  return { uploadFile, isUploading }
}
