import axios from 'axios'
import { createContext, useContext, useEffect, useState } from 'react'
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  ActionCodeSettings,
  sendEmailVerification,
  updateProfile,
  Auth
} from '@firebase/auth'
import { auth } from '../../config/firebase'
import { useRouter } from 'next/router'

export const AuthContext = createContext<any>({})
export const useAuth = () => useContext(AuthContext)

/*
IMPORTANT!!!
this component is used in fajnyczas app and admin app, check user actions in both apps
*/

export const AuthContextProvider = ({
  children,
  type,
  client
}: {
  children: any
  type: string
  client: string
}) => {
  const router = useRouter()

  const [user, setUser] = useState<any>(null)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (window) {
      const unsubscribe = onAuthStateChanged(auth, user => {
        if (user) {
          setUser({
            ...user,
            uid: user.uid,
            id: user.uid,
            email: user.email,
            displayName: user.displayName,
            accessToken: (user as any).accessToken
          })
        } else {
          setUser(null)
        }
        setReady(true)
      })

      return () => unsubscribe()
    }
  }, [])

  const signup = async (
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    phone: string,
    company_country_group: string,
    state_or_country: string,
    language = 'pl',
    redirectUrl?: string
  ) => {
    auth.languageCode = language
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const currentUser = await auth.currentUser
    if (!redirectUrl) {
    }
    if (currentUser) {
      await updateProfile(res.user, {
        displayName: first_name + ' ' + last_name
      })
      const actionCodeSettings: any = {
        url: redirectUrl ?? 'https://www.gofunlo.com/profile'
      }
      ;(currentUser as any).languageCode = language
      await sendEmailVerification(currentUser, actionCodeSettings)
    }
    await registerFCUser(currentUser, {
      first_name,
      last_name,
      phone,
      company_country_group,
      state_or_country,
      language
    })
    return res
  }

  const sendVerificationEmail = async (redirectUrl: any, language = 'pl') => {
    const currentUser = auth.currentUser
    if (currentUser) {
      ;(currentUser as any).languageCode = language
    }

    return sendEmailVerification(currentUser, { url: redirectUrl })
  }
  const registerFCUser = async (currentUser, data) => {
    const req = { ...data, type, client, country: data.language }
    const url = (window as any).fajnyczas_api_url + '/v1.1/public/register-user'
    const register_res = await axios.post(url, req, {
      headers: {
        Authorization: `Bearer ${await currentUser.getIdToken()}`
      }
    })
  }

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
  }
  const _sendPasswordResetEmail = (
    email: string,
    language = 'pl',
    actionCodeSettings?: ActionCodeSettings
  ) => {
    if (auth) {
      auth.languageCode = language
    }
    return sendPasswordResetEmail(auth, email, actionCodeSettings)
  }

  const logout = async () => {
    setUser(null)
    await signOut(auth)
    const { pathname } = router
    if (pathname.startsWith('/profile')) {
      router.push('/')
    }
  }
  const value = {
    user,
    login,
    signup,
    logout,
    auth,
    sendPasswordResetEmail: _sendPasswordResetEmail,
    accessToken: user?.accessToken,
    registerFCUser,
    ready,
    sendVerificationEmail
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
