import { debounce } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/system";
import { useCompanyApi } from "../organizer/events/api/useCompanyApi";
import { ApiTable } from "../../components/table/ApiTable";
import { SuperAdminCompanyPage } from "./SuperAdminCompanyPage";
import { FormattedDate } from "react-intl";
import { useState } from "react";
import { SuperDataExportButton } from "./SuperDataExport";
import { SearchBox } from "../../components/table/SearchBox";

import { useTranslation } from "react-i18next";
// import * as XLSX from "xlsx/xlsx.mjs"
// import ReactExport from "react-data-export"

const SuperCompaniesTable = ({ queryParams }: any) => {
  const { listCompanies } = useCompanyApi();
  const columns = [
    {
      header: "Nazwa",
      field: "name",
    },
    {
      header: "Imie",
      field: "admin_first_name",
    },
    {
      header: "Nazwisko",
      field: "admin_last_name",
    },
    {
      header: "Tel",
      field: "admin_phone",
    },
    {
      header: "Email",
      field: "admin_email",
    },
    {
      header: "Zarejestrowana",
      cell: (row: any) => (
        <div>
          <FormattedDate value={row.created_at} />
        </div>
      ),
      align: "right",
      field: "created_at",
    },
    {
      header: "Prowizja",
      cell: (row: any) => (
        <div className="text-left">{row.fee ? row.fee / 100 : "-"} %</div>
      ),
      width: 200,
    },
    {
      header: "Stripe aktywny",
      cell: (row: any) => (
        <div className="text-left">{row.stripe_id ? "TAK" : "NIE"}</div>
      ),
      width: 200,
    },
    {
      header: "Uwagi Admina",
      cell: (row: any) => <div className="text-left">{row.admin_comment}</div>,
      width: 200,
    },
    {
      header: "Pakiet",
      cell: (row: any) => <div className="text-left">{row.license_type}</div>,
      width: 100,
      field: "license_type",
    },
    {
      header: "Licencja do",
      cell: (row: any) => (
        <div className="text-left">{row.license_valid_till}</div>
      ),
      width: 100,
      field: "license_valid_till",
    },
    // {
    //   header: "Id",
    //   field: "id",
    // },
  ];

  // return (
  //   <TableContainer component={Paper}>
  //     <Table sx={{ minWidth: 650 }} aria-label="simple table">
  //       <TableHead>
  //         <TableRow>
  //           <TableCell>Dessert (100g serving)</TableCell>
  //           <TableCell align="right">Calories</TableCell>
  //           <TableCell align="right">Fat&nbsp;(g)</TableCell>
  //           <TableCell align="right">Carbs&nbsp;(g)</TableCell>
  //           <TableCell align="right">Protein&nbsp;(g)</TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {rows.map((row) => (
  //           <TableRow
  //             key={row.name}
  //             sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  //           >
  //             <TableCell component="th" scope="row">
  //               {row.name}
  //             </TableCell>
  //             <TableCell align="right">{row.calories}</TableCell>
  //             <TableCell align="right">{row.fat}</TableCell>
  //             <TableCell align="right">{row.carbs}</TableCell>
  //             <TableCell align="right">{row.protein}</TableCell>
  //           </TableRow>
  //         ))}
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  // )

  return (
    <ApiTable
      columns={columns}
      listApi={listCompanies}
      showExpandColumn
      rowLinkBuilder={(row: any) => `/super/companies/${row.id}`}
      queryParams={queryParams}
    ></ApiTable>
  );
};

// const DownloadCompaniesComponent = () => {
//   const dataSet1 = [{}]

//   return (
//     <ExcelFile>
//       <ExcelSheet data={dataSet1} name="Employees">
//         <ExcelColumn label="Name" value="name" />
//         <ExcelColumn label="Wallet Money" value="amount" />
//         <ExcelColumn label="Gender" value="sex" />
//         <ExcelColumn
//           label="Marital Status"
//           value={(col) => (col.is_married ? "Married" : "Single")}
//         />
//       </ExcelSheet>
//       {/* <ExcelSheet data={dataSet2} name="Leaves">
//                   <ExcelColumn label="Name" value="name"/>
//                   <ExcelColumn label="Total Leaves" value="total"/>
//                   <ExcelColumn label="Remaining Leaves" value="remaining"/>
//               </ExcelSheet> */}
//     </ExcelFile>
//   )
// }

export const SuperAdminCompanies = () => {
  const { listCompanies } = useCompanyApi();
  const queryParams: any = {};
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  if (searchText) {
    queryParams.search = searchText;
  }
  const setSearchTextDebounced = debounce(async (name) => {
    setSearchText(name);
  }, 500);

  return (
    <div className="mt-10">
      <h1 className="text-[32px] font-bold">
        {t("SuperAdmin.Products", "Produkty")}
      </h1>
      <Box width="100%">
        <div className="flex flex-row items-center w-full mb-4">
          <SuperDataExportButton api={listCompanies} />
          <div className="flex-1" />

          <SearchBox setSearchTextDebounced={setSearchTextDebounced} />
        </div>

        <Routes>
          <Route path=":id" element={<SuperAdminCompanyPage />} />
          <Route
            index
            element={<SuperCompaniesTable queryParams={queryParams} />}
          />
        </Routes>

        {/* <DownloadCompaniesComponent /> */}
      </Box>
    </div>
  );
};
