import { Box } from "@mui/material"
import { WakacjeInfoCard } from "../../../../../components/WakacjeInfoCard"
import { formatDistance, parse } from "date-fns"
import { pl } from "date-fns/locale"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { useCurrency } from "../../../../../hooks/useCurrency"
import { useProductUtils } from "../../../../../hooks/useProductUtils"

export const TripHeader = ({ trip }: { trip: any }) => {
  const { selectedCurrency } = useCurrency()

  const { formatPriceInCents } = useProductUtils()
  const daysToCome = trip.start_date
    ? formatDistance(
        parse(trip.start_date, "yyyy-MM-dd", new Date()),
        new Date(),
        { locale: pl }
      )
    : "-"

  var paymentsLabel = trip.bookings_stats?.total_paid_amount
    ? `${formatPriceInCents(
        trip.bookings_stats?.total_paid_amount,
        trip.bookings_stats?.currency
      )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
    : ""
  if (trip.confirmed_bookings_payments_amount) {
    paymentsLabel +=
      "/" +
      `${formatPriceInCents(
        trip.bookings_stats?.total_price,
        trip.bookings_stats?.currency
      )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
  }
  var participantsCountLabel = trip.bookings_stats?.participants_count ?? "0"
  if (trip.available_spots > 0) {
    participantsCountLabel += "/" + trip.available_spots
  }
  //
  return (
    <div className="flex flex-row">
      {/* <WakacjeInfoCard
          label="Liczba uczestników"
          value={participantsCountLabel}
        /> */}

      {trip?.bookings_stats?.total_price && (
        <WakacjeInfoCard
          label="Suma opłat"
          value={`${formatPriceInCents(
            trip?.bookings_stats?.total_paid_amount ?? 0,
            trip?.booking_stats?.currency
          )}  / ${formatPriceInCents(
            trip?.bookings_stats?.total_price ?? 0,
            trip?.booking_stats?.currency
          )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`}
          width={240}
        />
      )}
      <WakacjeInfoCard
        label="Termin"
        value={ProductUtils.getTripDatesRange(trip)}
        width={240}
      />
      {/* <WakacjeInfoCard label="Dni do rozpoczęcia" value={daysToCome} /> */}
    </div>
  )
}
