import axios from 'axios'
import { SuperAdminPromoCodesDialog } from './SuperAdminPromoCodesDialog'
import { v4 as uuidv4 } from 'uuid'

export interface PromoCodeProps {
  code: string
  valid_to: Date
  percentage_value: number | null
  price_value: number | null
  currency: string | null
  uses_available: number
  id: string
  product_id: string | null
}

export type HandleSavePromoCodeProps = PromoCodeProps & { unlimited: boolean }

export const AddPromoCode = ({ open, onClose }) => {
  const handleSave = async (data: HandleSavePromoCodeProps) => {
    const {
      unlimited,
      uses_available,
      percentage_value,
      currency,
      price_value,
      code,
      valid_to,
      product_id
    } = data

    const promoCode: PromoCodeProps = {
      code,
      valid_to,
      percentage_value: percentage_value ? percentage_value : null,
      price_value: price_value ? price_value * 100 : null,
      currency: currency ? currency : null,
      uses_available: unlimited ? -1 : uses_available,
      product_id: product_id ? product_id : null,
      id: uuidv4()
    }

    const url = process.env.REACT_APP_WAKACJE_API_URL + `/promo-codes/v1.0`

    await axios.post(url, {
      ...promoCode
    })
  }

  return (
    <SuperAdminPromoCodesDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSave}
      headerTitle={'Dodaj nowy kod rabatowy'}
    />
  )
}
