import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import { SectionTitle } from "../FormCommons"
import { useSnackbar } from "notistack"
import {
  useForm,
  get,
  RegisterOptions,
  FieldPath,
  FormProvider
} from "react-hook-form"
import { Button, CircularProgress } from "@mui/material"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"

export const EditableFormSection = ({
  title,
  editable = true,
  children,
  values,
  defaultValues,
  onSave,
  onSaved,
  validationSchema,
  groupValidationSchema,
  align,
  styles,
  onUpdate,
  fullWidth,
  stickyButtons
}: {
  title: string
  editable?: boolean
  children: any
  values?: any
  defaultValues?: any
  onSave: any
  onSaved?: any
  validationSchema?: any
  groupValidationSchema?: any
  align?: "left" | "center"
  styles?: any
  onUpdate?: Function
  fullWidth?: boolean
  stickyButtons?: boolean
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [_defaultValues, setDefaultValues] = useState(
    values ? values : defaultValues ? defaultValues : {}
  )
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()

  if (_defaultValues.country_code && !_defaultValues.country) {
    _defaultValues.country = _defaultValues.country_code
  }

  const getResolver = () => {
    if (groupValidationSchema) {
      return yupResolver(groupValidationSchema)
    } else {
      if (validationSchema) {
        return yupResolver(Yup.object(validationSchema))
      } else {
        return undefined
      }
    }
  }

  const formMethods = useForm<any>({
    mode: "all",
    defaultValues: _defaultValues,
    resolver: getResolver()
  })

  let {
    handleSubmit,
    formState: { errors, isValid },
    getValues
  } = formMethods

  const register = (name: FieldPath<any>, options?: RegisterOptions) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false))
  })

  const state = {
    isEditing,
    ...formMethods,
    register
  }

  useEffect(() => {
    setDefaultValues(values)
  }, [values])

  useEffect(() => {
    //reset the form to receive fresh data
    formMethods.reset(_defaultValues)
  }, [_defaultValues, formMethods])

  const onFormSubmit = async (data: any) => {
    if (isSaving) {
      return
    }

    try {
      setIsSaving(true)
      await onSave(data)

      enqueueSnackbar(t("saved", "Zapisano"), {
        variant: "success"
      })
      setIsEditing(false)
      setIsSaving(false)
      if (onSaved) {
        onSaved()
      }
      if (onUpdate) {
        onUpdate(data)
      }
    } catch (err) {
      setIsSaving(false)
      console.error("Error saving")
      enqueueSnackbar(
        t("error_saving", "Problem z zapisem ") + (err as any)?.message,
        {
          variant: "error"
        }
      )
    }
  }

  if (Object.keys(errors)?.length === 0) {
    isValid = true
  }
  return (
    <Box
      maxWidth={fullWidth ? "auto" : 670}
      margin={align === "left" ? "" : "auto"}
      sx={{
        position: "relative"
      }}
    >
      <section className={styles}>
        <SectionTitle onEdit={() => setIsEditing(true)}>{title}</SectionTitle>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onFormSubmit)} className="p-2">
            {children(state)}
            {isEditing && !stickyButtons && (
              <div className="flex flex-row mb-4">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsEditing(false)
                  }}
                >
                  {t("cancel", "Anuluj")}
                </Button>
                <Box flex={1} />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSaving || !isValid}
                >
                  {isSaving ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    t("save", "Zapisz")
                  )}
                </Button>
              </div>
            )}
            {isEditing && stickyButtons && (
              <div className="flex flex-row mb-4  gap-3 justify-end sticky bottom-3">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsEditing(false)
                  }}
                >
                  {t("cancel", "Anuluj")}
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSaving || !isValid}
                >
                  {isSaving ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    t("save", "Zapisz")
                  )}
                </Button>
              </div>
            )}
          </form>
        </FormProvider>
      </section>
    </Box>
  )
}
