import { useNavigate } from "react-router-dom"
import { ProductSummaryPage } from "../ProductSummaryPage"
import { PRODUCT_TYPE_SCHOOL_TRIP } from "wakacje-web/src/utils/ProductUtils"
import { NewProductWizardButtonsBar } from "./NewProductWizardButtonsBar"
import { NewSchoolTripSummaryPage } from "./NewSchoolTripSummaryPage"

const TypePage = {
  kolonie: "camp",
  polkolonie: "daycamp",
  "wycieczka-szkolna": "schooltrip",
  "noclegi-dla-grup": "groups"
}

export const NewProductPublishPage = ({ values, jumpTo, refetch }: any) => {
  const navigate = useNavigate()

  const handleSubmit = async () => {
    navigate(`/products/${TypePage[values.type] ?? ""}`)
  }

  return (
    <section>
      {/* <div className="text-center text-2xl font-bold my-2">Gratulacje!</div> */}

      {/* <PublishProductSection product={values} /> */}
      {/*  */}

      {values.type === PRODUCT_TYPE_SCHOOL_TRIP.value ? (
        <NewSchoolTripSummaryPage values={values} refetch={refetch} />
      ) : (
        <ProductSummaryPage product={values} refetch={refetch} />
      )}

      <NewProductWizardButtonsBar
        onCancel={() => {}}
        finalStep
        onSubmit={handleSubmit}
      />
      {/* {values && values.map((values: any, index: number) => {
          return <div>Summary</div>
          // const Preview = steps[index][2]
          // return (
          //   Preview && <Preview values={values} key={index} jumpTo={jumpTo} />
          // )
        })} */}
    </section>
  )
}
