import { Heading3, InputField } from "../../../../components/FormCommons"
import MenuItem from "@mui/material/MenuItem"
import { Controller, useFormContext, useFormState } from "react-hook-form"
import { Button } from "@mui/material"
import { useEffect, useState } from "react"

import { countries } from "../countries"
import allCountries from "country-region-data/data.json"
import * as Yup from "yup"
import { FieldError } from "../../../../components/FieldError"
import { PlaceOrAccommodationModal } from "./ProductSectionPlace/PlaceOrAccommodationModal"
import FormSectionTransportation from "../../../../domains/offer/components/CreateOfferForm/FormSectionTransportation"
import { TimeSelectDropdown } from "../../../../components/forms/TimeSelectDropdown"
import {useTranslation} from "react-i18next";

const pickupRouteSchema = Yup.object().shape({
  address: Yup.string().required("Miejsce wyjazdu jest wymagany"),
  price: Yup.number().required("Cena jest wymagany")
})

export const productLocationSchema = {
  country_code: Yup.string().required("Kraj jest wymagany"),
  location: Yup.object().shape({
    province: Yup.string().test(
      "country_code",
      "Województwo jest wymagane",
      function (value) {
        const country_code = this.from?.[1]?.value?.country_code
        return (
          country_code !== "PL" || (country_code === "PL" && value.length > 0)
        )
      }
    ),
    city: Yup.string().required("Miejsce docelowe jest wymagane")
  }),
  location_types: Yup.array().required("Typ lokalizacji jest wymagany"),
  transport_types: Yup.array()
    .min(1, "Dojazd jest wymagana")
    .required("Dojazd jest wymagana"),
  transport_vehicle: Yup.string().when("transport_types", {
    is: (transport_types) =>
      transport_types.some(
        (transport_type) => transport_type.type === "provided"
      ),
    then: Yup.string().nullable().required("Rodzaj transportu jest wymagany"),
    otherwise: Yup.string().nullable().notRequired()
  }),
  pickup_route: Yup.array()
    .of(pickupRouteSchema)
    .when("transport_vehicle", {
      is: (transport_vehicle) => transport_vehicle,
      then: Yup.array().min(1, "Miejsce wyjazdu jest wymagany"),
      otherwise: Yup.array().notRequired()
    })
}

export const SchoolTripIndividualSectionLocation = (props: any) => {
  const { t } = useTranslation()

  const { watch, getValues, register, trigger } = useFormContext<any>()
  const { errors } = useFormState()
  const values = getValues()

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { setValue } = useFormContext()
  const { type } = values
  const accommodation = watch("accommodation")
  // Keep this field registered
  const tripType = watch("trip_type")

  const countryCode = watch("country_code")

  const transportType = watch("transport_type")

  const startDate = watch("start_date")
  const endDate = watch("end_date")

  useEffect(() => {
    if (countryCode !== "PL") {
      setValue("country_is_poland", "no")
      setValue("location.province", "", {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      })
    } else {
      setValue("country_is_poland", "yes")
      trigger("location.province")
    }
  }, [countryCode])

  useEffect(() => {
    if (transportType === "provided") {
      setValue("pickup_collection_date", startDate)
      setValue("pickup_return_date", endDate)
    }
  }, [transportType])

  let selectedCountry = allCountries.find((obj) => {
    return obj.countryShortCode === countryCode
  })

  return (
    <div className="mb-8 pt-4">
      <div className="my-4">
        <Heading3>Nocleg</Heading3>

        {accommodation && (
          <>
            <div className="flex flex-row items-center">
              <div className="my-4 flex-1 text-lg">{accommodation.name}</div>
              <div>
                <div
                  className="text-red-500 font-bold cursor-pointer hover:underline"
                  onClick={() => {
                    setValue("accommodation", undefined)
                  }}
                >
                  Usuń
                </div>
              </div>
            </div>
          </>
        )}

        {!accommodation && (
          <div className="flex justify-center">
            <Button variant="outlined" size="large" onClick={handleOpen}>
              Dodaj nocleg
            </Button>
          </div>
        )}

        <PlaceOrAccommodationModal
          type="accommodation"
          open={open}
          handleClose={handleClose}
          disabledItems={[]}
          onItemsSelected={(items) => {

            if (items && items[0].type === "accommodation") {
              setValue("accommodation", items[0])
              setValue("accommodation_place_id", items[0].id)
            }

            if (
              items &&
              // items[0].type === "place" &&
              items.length > 0
            ) {
              setValue("place", {
                ...items[0]
              })
              setValue("place_id", items[0].id)
            }
            handleClose()
          }}
          handleDeleteItem={() => {}}
        />
      </div>

      <div className="mb-3">
        <Heading3>{t("Booking.Localization", "Lokalizacja")}</Heading3>
      </div>
      <div>
        <InputField
          select
          label={`${t("Booking.Country", "Kraj")}*`}
          SelectProps={{
            defaultValue: values?.location?.province || ""
          }}
          value={countryCode}
          {...register("country_code")}
        >
          {countries.map((c, index) => (
            <MenuItem key={index} value={c.code}>
              {c.name_pl}
            </MenuItem>
          ))}
        </InputField>
        <FieldError label={errors?.country_code?.message} />
      </div>

      <div>
        {countryCode === "PL" && (
          <>
            <InputField
              select
              label={`${t("ui.groups.province", "Województwo")}*`}
              SelectProps={{
                defaultValue: values?.location?.province || ""
              }}
              value={watch("location.province")}
              {...register("location.province", { required: false })}
            >
              {selectedCountry.regions.map((pr, index) => (
                <MenuItem value={pr.name}>{pr.name}</MenuItem>
              ))}
            </InputField>
            <FieldError label={errors?.location?.province?.message} />
          </>
        )}
      </div>

      <div className="mb-4">
        <InputField label={`${t("ui.groups.destination", "Miejsce docelowe")}*`}  {...register("location.city")} />

        <FieldError label={errors?.location?.city?.message} />
      </div>

      <div className="mb-8" />
      <FormSectionTransportation isIndividualOffer />
      <div className="mb-2 ">
        <p className="mb-2">Wyjazd</p>
        <InputField
          label="Miejsce zbiórki"
          {...register(`pickup_collection_place`)}
        />
        <InputField label="Adres" {...register(`pickup_collection_address`)} />
        <div className="flex flex-row gap-2">
          <div className="flex-1">
            {transportType === "provided" ? (
              <Controller
                name={"pickup_collection_date"}
                control={props?.control}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  const val =
                    value != null && value.length > 10
                      ? value.substr(0, 10)
                      : value

                  return (
                    <InputField
                      label="Data Wyjazdu"
                      type="date"
                      onChange={onChange}
                      value={val}
                      InputLabelProps={{ shrink: true }}
                      {...register("pickup_collection_date")}
                    />
                  )
                }}
              />
            ) : (
              <InputField
                label="Data Wyjazdu"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...register("pickup_collection_date")}
              />
            )}
          </div>{" "}
          <div className="flex-1">
            <TimeSelectDropdown
              label="Godzina wyjazdu"
              type="date"
              {...register("pickup_collection_time")}
            />
          </div>
        </div>
      </div>
      <div className="mb-2 ">
        <p className="mb-2">Powrót</p>
        <InputField
          label="Miejsce zbiórki"
          {...register(`pickup_return_place`)}
        />
        <InputField label="Adres" {...register(`pickup_return_address`)} />
        <div className="flex flex-row gap-2">
          <div className="flex-1">
            {transportType === "provided" ? (
              <Controller
                name={"pickup_return_date"}
                control={props?.control}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  const val =
                    value != null && value.length > 10
                      ? value.substr(0, 10)
                      : value

                  return (
                    <InputField
                      label="Data Wyjazdu"
                      type="date"
                      onChange={onChange}
                      value={val}
                      InputLabelProps={{ shrink: true }}
                      {...register("pickup_return_date")}
                    />
                  )
                }}
              />
            ) : (
              <InputField
                label="Data Wyjazdu"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...register("pickup_return_date")}
              />
            )}
          </div>{" "}
          <div className="flex-1">
            <TimeSelectDropdown
              label="Godzina wyjazdu"
              type="date"
              {...register("pickup_return_time")}
            />
          </div>
        </div>
        <div className="mt-4">
          <InputField
            label="Wskazówki dojazdu"
            {...register("location.driving_directions", {
              required: false
            })}
            multiline
            minRows={4}
          />
        </div>
      </div>

      <FieldError label={errors?.location_types?.message} />
    </div>
  )
}
