import { Routes, useNavigate, useLocation } from "react-router";
import { ArticlesTable } from "./ArticlesTable";
import { Route } from "react-router";
import { ArticleForm, ArticlePage } from "./ArticleForm";
import { useEffect, useState } from "react";
import { FcTabPanel, FcTabsHeader } from "../../../components/FcTabs/FcTabs";
import Toolbar from "../Toolbar/Toolbar";
import { useSearchText } from "../useSearchText";
import { useTabs } from "../useTabs";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";
import SEOTree from "./SEOTree";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
export const ArticlesIndex = () => {
  /* Tab */
  const { selectedTab, handleTabChange } = useTabs();
  const { setSearchTextDebounced, queryParams } = useSearchText();

  const [type, setType] = useState<any>("ALL");

  const navigate = useNavigate();
  const location = useLocation();

  const isArticle = selectedTab === 0;
  const isSeo = selectedTab === 1;
  const { t } = useTranslation();
  const isNew = location.pathname.includes("new");
  const isNewArticle = isArticle && location.pathname.includes("new");
  const isNewSeo = isSeo && location.pathname.includes("new");
  const isEdit =
    location.pathname.includes("/super/articles/") &&
    !location.pathname.includes("/super/articles/new");
  const isEditArticle = isEdit && isArticle;
  const isEditSeo = isEdit && isSeo;

  queryParams.type = isArticle ? "blog" : "seo"; // type;
  // if (type && type !== "ALL") {
  //     queryParams.type = 'blog'
  // }

  return (
    <>
      {/*<div>*/}
      {/*    <p>isNew  : {isNew ? 'true':'false' }</p>*/}
      {/*    <p>isNewA : {isNewArticle ? 'true':'false' }</p>*/}
      {/*    <p>isNewS : {isNewSeo ? 'true':'false' }</p>*/}
      {/*    <p>isEdit : {isEdit ? 'true':'false'}</p>*/}
      {/*    <p>isEditA: {isEditArticle ? 'true':'false'}</p>*/}
      {/*    <p>isEditS: {isEditSeo ? 'true':'false'}</p>*/}
      {/*</div>*/}
      {/* <IconButton size="small" onClick={() => navigate(-1)}>
                <ArrowBackIosIcon fontSize="small"/>
                <p> Powrót</p>
            </IconButton> */}
      <h1 className="text-[32px] font-bold">{t("SuperAdmin.Blog", "Blog")}</h1>
      {!(isNew || isEdit) && (
        <>
          <FcTabsHeader
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            tabs={["Artykuły", "SEO"]}
          />
          <div className="flex flex-row flex-wrap justify-between items-center">
            {!isSeo && (
              <div className="">
                <Toolbar
                  setSearchTextDebounced={setSearchTextDebounced}
                  api={[]}
                />
                {/*<div className="flex gap-4 items-center">*/}
                {/*    <p>Wybierz: </p>*/}
                {/*    <RadioGroup*/}
                {/*        row*/}
                {/*        onChange={(ev) => {*/}
                {/*            setType(ev.target.value)*/}
                {/*        }}*/}
                {/*        value={type}*/}
                {/*    >*/}
                {/*        <FormControlLabel*/}
                {/*            label="Wszyscy"*/}
                {/*            value="ALL"*/}
                {/*            control={<Radio value="ALL"/>}*/}
                {/*        />*/}
                {/*        <FormControlLabel*/}
                {/*            label="Blog"*/}
                {/*            value="blog"*/}
                {/*            control={<Radio value="blog"/>}*/}
                {/*        />*/}
                {/*        <FormControlLabel*/}
                {/*            label="SEO"*/}
                {/*            value="seo"*/}
                {/*            control={<Radio value="seo"/>}*/}
                {/*        />*/}
                {/*    </RadioGroup>*/}
                {/*</div>*/}
              </div>
            )}
            <div
              className={!isArticle ? "mt-2 w-full justify-end flex" : "mt-2"}
            >
              {!(isNew || isEdit) && (
                <>
                  {isArticle ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        navigate("/super/articles/newArticle");
                      }}
                    >
                      Dodaj Artykuł
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        navigate("/super/articles/newSeo");
                      }}
                    >
                      Dodaj Seo
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}

      <FcTabPanel selectedTab={selectedTab} index={0}>
        <div className="mt-2">
          <Routes>
            <Route path="newArticle" element={<ArticleForm type="blog" />} />
            <Route path="newSeo" element={<ArticleForm type="seo" />} />
            <Route path=":id" element={<ArticlePage />} />
            <Route
              index
              element={
                <ArticlesTable
                  queryParams={queryParams}
                  initialSort={{ direction: "desc", field: "published_at" }}
                />
              }
            />
          </Routes>
        </div>
      </FcTabPanel>
      <FcTabPanel selectedTab={selectedTab} index={1}>
        <div className="mt-2">
          <Routes>
            <Route path="newArticle" element={<ArticleForm type="blog" />} />
            <Route path="newSeo" element={<ArticleForm type="seo" />} />
            <Route path=":id" element={<ArticlePage />} />
            <Route index element={<SEOTree queryParams={queryParams} />} />
          </Routes>
        </div>
      </FcTabPanel>
    </>
  );
};
