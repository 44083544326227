import { useState } from "react"
import { Button } from "@mui/material"
import PlaceOrAccommodationModal from "./PlaceOrAccommodationModal"
import { EditableFormSection } from "../../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../../events/api/useProductApi"
import { LabeledField } from "../../../../../components/FormCommons"
import { useFieldArray, useFormContext } from "react-hook-form"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { ItemRow } from "./ItemRow"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import { t } from "i18next"

export const productSectionPlacesFormSchema = {
  places: Yup.array().of(
    Yup.object().shape({
      price_type: Yup.string().required("Cena jest wymagana"),
      price: Yup.string().when("price_type", {
        is: "optional",
        then: Yup.string().required("is reequired")
      })
    })
  )
}

export function ProductSectionPlacesForm() {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { watch, register, control, setValue } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "places" // unique name for your Field Array
  })

  const handleItemSelected = (newItems) => {
    for (var i = 0; i < newItems.length; i++) {
      append({
        place: {
          id: newItems[i].id,
          name: newItems[i].name
        }
      })
    }
    handleClose()
  }

  const handleDeleteItem = (item) => {
    const newItem = fields.filter((p: any) => p.place.id !== item.id)
    setValue("places", newItem)
  }

  return (
    <div className="px-4">
      {fields.map((field, index) => (
        <ItemRow
          key={field.id}
          index={index}
          register={register}
          watch={watch}
          control={control}
          onRemove={() => remove(index)}
          type="place"
        />
      ))}

      <div className="flex justify-center my-5">
        <Button
          className="mx-4"
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleOpen}
        >
          {t("ui.groups.add_attraction", "Dodaj atrakcje")}
        </Button>
      </div>

      <PlaceOrAccommodationModal
        type="place"
        open={open}
        handleClose={handleClose}
        disabledItems={[]}
        onItemsSelected={handleItemSelected}
        handleDeleteItem={handleDeleteItem}
      />
    </div>
  )
}

export const ProductSectionPlacesPreview = ({ values }: any) => {
  return (
    <div className="pt-4 pb-2 px-4">
      {values.places &&
        values.places.map((productPlace: any, index) => (
          <div
            className={
              "mb-8 pb-4 " +
              (index !== values.places.length - 1 ? "border-b" : "")
            }
            key={index}
          >
            <LabeledField
              label={t("ui.attractions.attraction_name", "Nazwa atrakcji")}
              className="my-0"
            >
              <div className="font-bold mb-2 ">{productPlace?.place?.name}</div>
            </LabeledField>

            {productPlace.price_type && (
              <>
                <LabeledField
                  label={t("ui.groups.price", "Cena")}
                  className="my-0"
                >
                  <>
                    <div>
                      {productPlace.price_type === "included" &&
                        t("ui.groups.included", "W cenie")}
                    </div>
                    <div>
                      {productPlace.price_type === "optional" &&
                        t("ui.groups.additional_payment", "Dodatkowa opłata")}
                      {productPlace.price && <>{", " + productPlace.price} {productPlace.currency}</>}
                    </div>
                  </>
                </LabeledField>
              </>
            )}
          </div>
        ))}
    </div>
  )
}

export const ProductSectionPlacesView = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()
  const { t } = useTranslation()

  const saveHandler = async (data: any) => {
    var productData: any = {
      id: values.id,
      places: data.places
    }

    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }

  values.places = values.places ?? []

  return (
    <EditableFormSection
      title={t("ui.groups.attractions", "Atrakcje")}
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
      validationSchema={productSectionPlacesFormSchema}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && <ProductSectionPlacesForm />}
            {!builderProps.isEditing && (
              <ProductSectionPlacesPreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
