import { ProductUtils } from 'wakacje-web/src/utils/ProductUtils'
import { WakacjeChip, WakacjeChipVariant } from '../WakacjeChip'
import { useTranslation } from 'react-i18next'

export const ProductTypeChip = ({ type }: { type: string }) => {
  const { t } = useTranslation()

  const variants = {
    kolonie: WakacjeChipVariant.RED,
    polkolonie: WakacjeChipVariant.GREEN,
    'wycieczka-szkolna': WakacjeChipVariant.BLUE,
    'zielona-szkola': WakacjeChipVariant.BLUE,
    'noclegi-dla-grup': WakacjeChipVariant.BLUE
  }
  const translations = {
    kolonie: t('Booking.camps', 'Kolonie i obozy'),
    polkolonie: t('Booking.daycamps'),
    'wycieczka-szkolna': t('Booking.school_trips'),
    'zielona-szkola': t('green-school'),
    'noclegi-dla-grup': t('Booking.groups')
  }
  return <WakacjeChip variant={variants[type]} label={translations[type]} />
}
