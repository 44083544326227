import { Avatar, Popover } from "@mui/material"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useAuthContext } from "../../contexts/auth-context"
import { useCompanyContext } from "../../contexts/company-context"
import { LogoutIcon } from "../icons/LogoutIcon"
import { PopoverArrow } from "../popover/PopoverArrow"
import {useTranslation} from "react-i18next";

export const UserDropdownMenu = () => {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const authContext = useAuthContext()
  const companyContext = useCompanyContext()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const company = companyContext?.company
  const initialCompany = company?.name?.charAt(0)

  return (
    <div className="relative">
      <div
        onClick={handleClick}
        className="flex gap-2 items-center cursor-pointer"
      >
        <Avatar sx={{ bgcolor: "#FDA500", height: "32px", width: "32px" }}>
          {initialCompany}
        </Avatar>
        <p>{company?.name}</p>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { width: "300px", marginTop: "48px" }
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{
          "& .MuiPaper-root": {
            overflow: "unset"
          }
        }}
      >
        <PopoverArrow />
        <ul className="" aria-labelledby="dropdownButton">
          <li className="flex items-center gap-3 px-5 py-5">
            <Avatar sx={{ bgcolor: "#FDA500", height: "40px", width: "40px" }}>
              {initialCompany}
            </Avatar>
            <div>
              <p className="font-lato">{company?.name}</p>
              <p className="font-lato text-sm leading-[14px] tracking-[0.01em] text-[#747474] mt-1">
                {company?.admin_email}
              </p>
            </div>
          </li>
          <li className="mb-2.5 px-1.5">
            <div className="border-b"></div>
          </li>
          <li>
            <a href="/profil" target="_blank">
              <div className="flex items-center py-2 px-5 text-sm hover:bg-[#E5EBF7] h-[48px]">
                {t("user_dropdown_menu.user_data", "Dane użytkownika")}
              </div>
            </a>
          </li>
          <li>
            <Link to="/about/info">
              <div className="flex items-center py-2 px-5 text-sm hover:bg-[#E5EBF7]   h-[48px]">
                {t("user_dropdown_menu.company_data", "Dane  firmy")}
              </div>
            </Link>
          </li>
          <li className="mt-2.5 px-1.5">
            <div className="border-b"></div>
          </li>
          <li className="py-3">
            <div
              onClick={(ev: any) => authContext.logout()}
              className="cursor-pointer flex items-center gap-4 py-2 px-5 text-sm hover:bg-[#E5EBF7]  h-[48px]"
            >
              <LogoutIcon />
              {t("logout", "Wyloguj")}
            </div>
          </li>
        </ul>
      </Popover>
    </div>
  )
}
