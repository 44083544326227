import { SectionTitle } from '../../../../components/FormCommons'
import { EditableFormSection } from '../../../../components/forms/EditableFormSection'
import { useProductApi } from '../../events/api/useProductApi'
import {
  ProductTypes,
  PRODUCT_TYPE_SCHOOL_TRIP
} from 'wakacje-web/src/utils/ProductUtils'
import { ProductGeneralInfoPreview } from './ProductGeneralInfoPreview'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { ProductGeneralInfoForm } from './ProductGeneralInfo'
import {
  ProductSectionScheduleForm,
  ProductSectionSchedulePreview
} from './ProductSectionSchedule'
import {
  ProductSectionFoodForm,
  ProductSectionFoodPreview
} from './ProductSectionFood'
import {
  ProductSectionInsuranceForm,
  ProductSectionInsurancePreview
} from './ProductSectionInsurance'
import {
  ProductSectionConditionsForm,
  ProductSectionConditionsPreview
} from './ProductSectionConditions'
import {
  ProductSectionFilesForm,
  ProductSectionFilesPreview
} from '../ProductSectionFiles'
import {
  ProductSectionPlacesForm,
  ProductSectionPlacesPreview
} from './ProductSectionPlace/ProductSectionPlace'
import {
  ProductSectionPricingForm,
  ProductSectionPricingPreview
} from './ProductSectionPricing'
import {
  ProductSectionLocationForm,
  ProductSectionLocationPreview
} from './ProductSectionLocation'
import { ProductSectionTransportForm } from './ProductSectionTransport'
import { getLocalizedKey } from '../../../../utils/getLocalizedKey'

export const productGeneralInfoSchema = {
  name: Yup.string().required('Nazwa oferty jest wymagana'),
  description: Yup.string().required('Opis oferty jest wymagany'),
  categories: Yup.array().nullable().required('Kategorie są wymagane'),
  min_age: Yup.number().required('Minimalny wiek jest wymagany'),
  max_age: Yup.number().required('Maksymalny wiek jest wymagany')
}

export const productGeneralInfoSchoolTripSchema = {
  name: Yup.string().required('Nazwa oferty jest wymagana'),
  description: Yup.string().required('Opis oferty jest wymagany'),
  trip_type: Yup.string().required('Rodzaj wycieczki jest wymagany'),
  duration_days: Yup.mixed().when('trip_type', {
    is: 'wielodniowa',
    then: Yup.number().required('Liczba dni jest wymagany')
  }),
  categories: Yup.array().nullable().required('Kategorie są wymagane'),
  min_age: Yup.number().required('Minimalny wiek jest wymagany'),
  max_age: Yup.number().required('Maksymalny wiek jest wymagany'),
  availability: Yup.array().required('Dostępność jest wymagany')
}

export const ProductJoinedSectionsDaycamp = ({
  values,
  refetch,
  watch,
}: any) => {
  const { updateProduct } = useProductApi();

  const nameKey = getLocalizedKey('name')
  const descriptionKey = getLocalizedKey('description')
  const foodDescriptionKey = getLocalizedKey('food_description')
  const insuranceDescriptionKey = getLocalizedKey('insurance_description')
  const participationDescriptionKey = getLocalizedKey(
    'participation_description'
  )
  const scheduleDescriptionKey = getLocalizedKey('schedule_description')

  const saveHandler = async (data: any) => {
    var productData: any = {
      id: values.id,
      name: data[nameKey],
      description: data[descriptionKey],
      category_code: data.category_code,
      subcategory_code: data.subcategory_code,
      categories: data.categories,
      media: data.media,
      min_age: data.min_age,
      max_age: data.max_age,
      // ProductSectionLocationView

      country_code: data.country_code,
      country_name: data.country_name,
      location: data.location,
      location_types: data.location_types,
      location_name: data.location_name,
      transport_description: data.transport_description,
      transport_vehicle: data.transport_vehicle,
      transport_type: data.transport_type,
      pickup_route: data.pickup_route,
      //   ProductSectionScheduleView
      schedule: data.schedule,
      food_type: data.food_type,
      food_description: data[foodDescriptionKey],
      food_diets: data.food_diets,
      insurance_description: data[insuranceDescriptionKey],
      insurance_type: data.insurance_type,
      participation_conditions: data.participation_conditions,
      participation_description: data[participationDescriptionKey],
      file_sections: data.file_sections,
      files: data.files,
      schedule_description: data[scheduleDescriptionKey],
      discounts_description: data.discounts_description,
      //   ProductSectionPlacesView
      places: data.places,
      //   ProductSectionPricingView
      min_price: data.min_price,
      price_description: data.price_description,
      price_includes_text: data.price_includes_text,
      price_not_includes_text: data.price_not_includes_text,
      own_transport_details: data.own_transport_details,
      participants_languages: data.participants_languages,
      currency: data.currency
    }

    if (
      data.type === PRODUCT_TYPE_SCHOOL_TRIP.value ||
      data.type === ProductTypes.CAMP
    ) {
      productData.trip_type = data.trip_type;
      productData.duration_days = data.duration_days;
      productData.availability = data.availability;
    }

    return updateProduct(productData);
  };
  const handleRefresh = () => {
    setTimeout(() => {
      refetch();
    }, 3000);
  };

  const initialValues = { ...values, categories: values.categories ?? [] }
  const { t } = useTranslation()
  var _values = { ...values }
  _values.files = _values.files ?? []
  _values.file_sections = _values.file_sections ?? []
  _values.schedule = values?.schedule ?? []

  var schema =
    values.type === PRODUCT_TYPE_SCHOOL_TRIP.value
      ? productGeneralInfoSchoolTripSchema
      : productGeneralInfoSchema;
  return (
    <>
      <EditableFormSection
        title={t('product.description', 'Opis')}
        values={values}
        onSave={saveHandler}
        onSaved={handleRefresh}
        validationSchema={schema}
        fullWidth
        stickyButtons
      >
        {(builderProps: any) => {
          return (
            <div>
              {builderProps.isEditing && (
                <>
                  <ProductGeneralInfoForm
                    values={initialValues}
                    {...builderProps}
                  />
                  {/* ProductSectionLocationView */}
                  <SectionTitle>
                    {t("Booking.Localization", "Lokalizacja")}
                  </SectionTitle>
                  <ProductSectionLocationForm
                    values={initialValues}
                    register={builderProps.register}
                    watch={builderProps.watch}
                  />
                  <ProductSectionTransportForm
                    values={values}
                    {...builderProps}
                  />
                  {/* ProductSectionScheduleView */}
                  <SectionTitle>
                    {" "}
                    {t("Booking.Schedule", "Plan wydarzenia")}
                  </SectionTitle>
                  <ProductSectionScheduleForm />
                  <ProductSectionFoodForm
                    values={_values}
                    watch={watch}
                    {...builderProps}
                  />
                  <ProductSectionInsuranceForm
                    values={_values}
                    watch={watch}
                    {...builderProps}
                  />
                  <ProductSectionConditionsForm
                    values={_values}
                    {...builderProps}
                  />
                  <ProductSectionFilesForm
                    values={_values}
                    {...builderProps}
                    showSection={false}
                  />
                  {/* ProductSectionPlacesView */}
                  <SectionTitle>
                    {t("Booking.Attractions", "Atrakcje")}
                  </SectionTitle>
                  <ProductSectionPlacesForm />
                  {/* PRODUCT SECTION PRICING */}
                  <SectionTitle>{t("Booking.Price", "Cennik")}</SectionTitle>
                  <ProductSectionPricingForm
                    values={values}
                    {...builderProps}
                  />
                </>
              )}
              {!builderProps.isEditing && (
                <>
                  <ProductGeneralInfoPreview
                    values={values}
                    jumpTo={() => {}}
                  />
                  {/* ProductSectionLocationView */}
                  <SectionTitle>
                    {t("Booking.Localization", "Lokalizacja")}
                  </SectionTitle>
                  <ProductSectionLocationPreview values={values} />
                  {/* productsectionsschedule */}
                  <SectionTitle>
                    {t("Booking.Schedule", "Plan wydarzenia")}
                  </SectionTitle>
                  <ProductSectionSchedulePreview values={_values} />
                  <ProductSectionFoodPreview values={_values} />
                  <ProductSectionInsurancePreview values={_values} />
                  <ProductSectionConditionsPreview values={_values} />
                  <ProductSectionFilesPreview values={_values} />
                  {/* productsectionplace */}
                  <SectionTitle>
                    {t("Booking.Attractions", "Atrakcje")}
                  </SectionTitle>
                  <ProductSectionPlacesPreview values={values} />
                  {/* PRODUCT SECTION PRICING */}
                  <SectionTitle>{t("Booking.Price", "Cennik")}</SectionTitle>
                  <ProductSectionPricingPreview values={values} />
                </>
              )}
            </div>
          );
        }}
      </EditableFormSection>
    </>
  );
};
