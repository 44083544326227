import { useReducer, useState } from "react"
import { useForm, FormProvider } from "react-hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { MojeButton } from "../MojeButton"
import { WakacjeTextInput } from "../../shared/components/WakacjeTextInput"
import { useAuth } from "../../auth/AuthContext"
import { ActionCodeSettings } from "@firebase/auth"
import { LinkUtils } from "../../shared/LinkUtils"
import { useTranslation } from "next-i18next"

const Actions = {
  EMAIL_SENT: "EMAIL_SENT"
}

const Step = {
  ENTER_EMAIL: "ENTER_EMAIL",
  EMAIL_SENT: "EMAIL_SENT"
}

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.EMAIL_SENT:
      return { ...state, loading: false, step: Step.EMAIL_SENT }
    default:
      return state
  }
}

export const ResetPasswordForm = () => {
  const { i18n, t } = useTranslation("common")

  const logInSchema = Yup.object({
    email: Yup.string()
      .email(t("resetPasswordForm.invalid_email", "E-mail nie jest poprawny"))
      .required(
        t("resetPasswordForm.required_email", "Wprowadź poprawny e-mail")
      )
  })

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    step: Step.ENTER_EMAIL
  })
  const [isSaving, setIsSaving] = useState(false)

  const defaultValues = { email: "" }
  const formMethods = useForm({
    resolver: yupResolver(logInSchema),
    mode: "onChange",
    defaultValues
  })
  const {
    handleSubmit,
    getValues,
    watch,
    formState: { errors, touchedFields },
    register
  } = formMethods

  const { sendPasswordResetEmail } = useAuth()
  const values = getValues()

  const onSubmit = async (values) => {
    const isValid = await formMethods.trigger()
    if (!isValid) return

    setIsSaving(true)
    if (state.step === Step.ENTER_EMAIL) {
      try {
        const href = LinkUtils.getAdminHref()
        const actionCodeSettings: ActionCodeSettings = {
          url: href + "/login?source=reset-password"
        }
        await sendPasswordResetEmail(
          values.email,
          i18n.language,
          actionCodeSettings
        )
        dispatch({ type: Actions.EMAIL_SENT })
      } catch (error) {}
    }
    setIsSaving(false)
  }

  return (
    <div>
      {state.step === Step.ENTER_EMAIL && (
        <div className="my-4 text-center">
          {t(
            "resetPasswordForm.enter_email_instruction",
            "Wpisz adres e-mail podany podczas rejestracji konta, na który wyślemy instrukcje resetowania hasła."
          )}
        </div>
      )}

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {state.step === Step.ENTER_EMAIL && (
            <div className="mb-4">
              <WakacjeTextInput
                id="email"
                label={t("resetPasswordForm.email", "Email")}
                fullWidth
                {...register("email")}
                errors={errors}
                touched={touchedFields?.email}
                valid={!errors.email}
                value={watch("email")}
                disabled={isSaving}
              />
            </div>
          )}

          {state.step === Step.EMAIL_SENT && (
            <>
              <div className="mb-8">
                <div className="mb-8">
                  {t(
                    "resetPasswordForm.send_link",
                    "Link do zrestartowania hasła został wysłany na wskazany adres"
                  )}
                </div>
                <div className="text-center font-bold">{values.email}</div>
              </div>
            </>
          )}

          {state.step !== Step.EMAIL_SENT && (
            <div>
              <MojeButton
                type="submit"
                variant="contained"
                color="primary"
                label={t("resetPasswordForm.reset_password", "Resetuj hasło")}
                isSaving={isSaving}
              />
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  )
}
