import { Control, Controller } from "react-hook-form"
import { useEffect } from "react"
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface ReservationSelectFieldProps {
    label: string;
    name: string;
    control: Control
    setValue: (name: string, newValue: string) => void
    fieldID?: string
    data: any[]
}

export const ReservationSelectField = ({
                                            label,
                                            name,
                                            control,
                                            setValue,
                                            fieldID,
                                            data
                                        }: ReservationSelectFieldProps) => {
    useEffect(() => {
        const isValid = data.some((item) => item.id === fieldID);
        setValue(name, isValid ? fieldID : undefined);
    }, [fieldID, data, setValue]);

    return (
        <>
            <Controller
                name={name}
                control={control}
                rules={{
                    required: "Pole wymagane.",
                    validate: (value) => {
                        if (!data.some((item) => item.id === value)) {
                            return "Niepoprawna wartość.";
                        }
                        return true;
                    }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    const safeValue = data.some((item) => item.id === value) ? value : "";

                    const handleChange = (event: SelectChangeEvent) => {
                        onChange(event.target.value);
                    };

                    return (
                        <div>
                            <FormControl sx={{ my: 2, minWidth: 240 }} error={!!error}>
                                <InputLabel id={`select-${name}-label`}>{label}</InputLabel>
                                <Select
                                    labelId={`select-${name}-label`}
                                    id={`select-${name}-helper`}
                                    value={safeValue}
                                    label={label}
                                    onChange={handleChange}
                                    disabled={data.length === 0}
                                >
                                    {data.length === 0 ? (
                                        <MenuItem value="" disabled>
                                            Brak dostępnych opcji
                                        </MenuItem>
                                    ) : (
                                        data.map(({ id, name }) => (
                                            <MenuItem key={id} value={id}>
                                                {name}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                            </FormControl>
                        </div>
                    )
                }}
            />
        </>
    )
}
