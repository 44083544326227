import {
  DescriptionList,
  InputField,
} from "../../../../components/FormCommons";
import { Button } from "@mui/material";
import { EditableFormSection } from "../../../../components/forms/EditableFormSection";
import { useProductApi } from "../../events/api/useProductApi";
import { useFieldArray, useFormContext } from "react-hook-form";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useTranslation } from "react-i18next";
import {
  ProductSectionFoodForm,
  productSectionFoodFormSchema,
  ProductSectionFoodPreview,
} from "./ProductSectionFood";
import {
  ProductSectionInsuranceForm,
  productSectionInsuranceFormSchema,
  ProductSectionInsurancePreview,
} from "./ProductSectionInsurance";
import {
  ProductSectionConditionsForm,
  ProductSectionConditionsPreview,
} from "./ProductSectionConditions";
import {
  ProductSectionFilesForm,
  ProductSectionFilesPreview,
} from "../ProductSectionFiles";

import { FormSectionContainer } from "./components/FormSectionContainer";
import {
  ProductTypes,
  PRODUCT_TYPE_CAMP,
} from "wakacje-web/src/utils/ProductUtils";
import { getLocalizedKey } from "../../../../utils/getLocalizedKey";

const ScheduleDayRow = ({
  index,
  register,
  onRemove,
  ...props
}: {
  index: number;
  register: any;
  onRemove?: any;
}) => {
  return (
    <div>
      <div className="font-bold">{index + 1} Dzień</div>

      <div className="flex flex-row mb-1 items-center">
        <div className="w-full mr-3">
          <InputField
            label="Plan dnia"
            {...register(`schedule.${index}.description`)}
            multiline
            minRows={5}
          />
        </div>
        {onRemove && (
          <div className="ml-1">
            <CancelOutlinedIcon onClick={onRemove} />
          </div>
        )}
      </div>
    </div>
  );
};

export const productSectionScheduleSchema = {
  // name: Yup.string().required("Nazwa oferty jest wymagana")
};

export const ProductSectionScheduleForm = (props: any) => {
  const { getValues, register, control, watch } = useFormContext();
  const values = getValues();

  const scheduleDescriptionKey = getLocalizedKey("schedule_description");

  const multipleDays = values.trip_type !== "jednodniowa";
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "schedule", // unique name for your Field Array
  });
  const { t } = useTranslation();
  if (
    values.type === PRODUCT_TYPE_CAMP.value ||
    values.type === ProductTypes.DAYCAMP
  ) {
    return (
      <FormSectionContainer>
        <InputField
          value={watch(scheduleDescriptionKey)}
          label={t("Booking.Description", "Opis")}
          {...register(scheduleDescriptionKey)}
          multiline
          minRows={5}
        />
      </FormSectionContainer>
    );
  }
  return (
    // Only this section has special X margin
    <div className="px-8">
      <FormSectionContainer>
        {multipleDays ? (
          <>
            {fields &&
              fields.map((field, index) => {
                return (
                  <ScheduleDayRow
                    key={field.id}
                    index={index}
                    register={register}
                    onRemove={() => remove(index)}
                  />
                );
              })}
            <div className="flex flex-row items-center justify-center">
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    append({});
                  }}
                >
                  {t("Booking.add_next_day", "Dodaj kolejny dzień")}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div>
            <ScheduleDayRow index={0} register={register} />
          </div>
        )}
      </FormSectionContainer>
    </div>
  );
};

export const ProductSectionSchedulePreview = ({ values }: any) => {
  const { t } = useTranslation();
  const scheduleDescriptionKey = getLocalizedKey("schedule_description");
  return (
    <div className="">
      {(values.type === PRODUCT_TYPE_CAMP.value ||
        values.type === ProductTypes.DAYCAMP) && (
        <DescriptionList
          data={{
            [t("Booking.Description", "Opis")]: values[scheduleDescriptionKey],
          }}
        />
      )}
      {values.type !== PRODUCT_TYPE_CAMP.value &&
        values.type !== ProductTypes.DAYCAMP &&
        values.schedule &&
        values.schedule.map((sch: any, index) => (
          <div className="mb-8 border-b pb-8 px-4" key={index}>
            <div className="font-bold mb-4">{t("Booking.day", "Dzień")} {index + 1}</div>
            <div className="whitespace-pre-line">{sch.description}</div>
          </div>
        ))}
    </div>
  );
};

export const ProductSectionScheduleView = ({ values, refetch, watch }: any) => {
  const { updateProduct } = useProductApi();

  const foodDescriptionKey = getLocalizedKey("food_description");
  const insuranceDescriptionKey = getLocalizedKey("insurance_description");
  const participationDescriptionKey = getLocalizedKey(
    "participation_description"
  );
  const scheduleDescriptionKey = getLocalizedKey("schedule_description");

  const saveHandler = async (data: any) => {
    var productData: any = {
      id: values.id,
      schedule: data.schedule,
      food_type: data.food_type,
      food_description: data[foodDescriptionKey],
      food_diets: data.food_diets,
      insurance_description: data[insuranceDescriptionKey],
      insurance_type: data.insurance_type,
      participation_conditions: data.participation_conditions,
      participation_description: data[participationDescriptionKey],
      file_sections: data.file_sections,
      files: data.files,
      schedule_description: data[scheduleDescriptionKey],
      discounts_description: data.discounts_description,
    };
    return updateProduct(productData);
  };
  const handleRefresh = () => {
    refetch();
  };
  const { t } = useTranslation();
  var _values = { ...values };
  _values.files = _values.files ?? [];
  _values.file_sections = _values.file_sections ?? [];
  _values.schedule = values?.schedule ?? [];
  const mergedSchema = {
    ...productSectionScheduleSchema,
    ...productSectionFoodFormSchema,
    ...productSectionInsuranceFormSchema,
  };
  return (
    <EditableFormSection
      title={t("Booking.Schedule", "Plan wydarzenia")}
      values={_values}
      onSave={saveHandler}
      onSaved={handleRefresh}
      validationSchema={mergedSchema}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <>
                <ProductSectionScheduleForm />
                <ProductSectionFoodForm
                  values={_values}
                  watch={watch}
                  {...builderProps}
                />
                <ProductSectionInsuranceForm
                  values={_values}
                  watch={watch}
                  {...builderProps}
                />
                <ProductSectionConditionsForm
                  values={_values}
                  {...builderProps}
                />
                <ProductSectionFilesForm
                  values={_values}
                  {...builderProps}
                  showSection={false}
                />
              </>
            )}
            {!builderProps.isEditing && (
              <>
                <ProductSectionSchedulePreview values={_values} />
                <ProductSectionFoodPreview values={_values} />
                <ProductSectionInsurancePreview values={_values} />
                <ProductSectionConditionsPreview values={_values} />
                <ProductSectionFilesPreview values={_values} />
              </>
            )}
          </div>
        );
      }}
    </EditableFormSection>
  );
};
