import axios from "axios"
import { FormattedDate, FormattedTime } from "react-intl"
import { ApiTable } from "../../../components/table/ApiTable"
import { useCompanyContext } from "../../../contexts/company-context"
// import { BookingStatusChip } from "../../../../../fajnyczas-web/src/shared/booking/BookingStatusChip"
import { BookingStatusChip } from "wakacje-web/src/shared/booking/BookingStatusChip"
import { withServiceBlocked } from "../../../components/widgets/ServiceBlocked"
import { BookingStatus } from "wakacje-web/src/shared/wakacje-types"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { PageTitle } from "../../../components/typography"

const ChatsTable = (props) => {
  const companyContext = useCompanyContext()

  const listChats = async (query = {}) => {
    if (!companyContext.company) {
      return
    }
    let searchParams = new URLSearchParams({
      ...query,
      view: "company",
      company_id: companyContext.company?.id
    })
    var url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL +
      `/messages/v1/chats?${searchParams}`
    var res = await axios.get(url)
    return res.data
  }

  const columns = [
    {
      header: "Klient",
      cell: (row) => (
        <div>
          <div className="font-bold">{row.user_name ?? row.user_email}</div>
          <div>{row.content_text}</div>
        </div>
      )
    },
    {
      header: "Data",
      cell: (row) => (
        <div>
          {row.sent_at ? (
            <>
              <FormattedDate value={row.sent_at} />{" "}
              <FormattedTime value={row.sent_at} />
            </>
          ) : (
            <span />
          )}
        </div>
      )
    },
    {
      header: "Oferta",
      cell: (row) => (
        <div>
          {row.chat_hash?.indexOf("cmp") === 0 ? (
            <>{row.company_name} </>
          ) : (
            <>
              <div className="font-bold">{row.product_name}</div>

              {row.booking_type_label?.type === "INQUIRY" && (
                <>
                  {row.booking_start_date &&
                    ProductUtils.inquiryStartDateMonth({
                      start_date: row.booking_start_date
                    })}
                </>
              )}
              {row.booking_type_label?.type !== "INQUIRY" &&
                row.booking_start_date && (
                  <div>
                    <FormattedDate value={row.booking_start_date} />{" "}
                    <FormattedTime value={row.booking_end_date} />
                  </div>
                )}
            </>
          )}
        </div>
      )
    },
    {
      header: "Status",
      cell: (row) => (
        <div>
          <div>
            {row.booking_status && (
              <BookingStatusChip
                booking={{
                  status: row.booking_status,
                  status_label: row.booking_status_label
                }}
              />
            )}
            {row.chat_hash?.indexOf("cmp") === 0 && (
              <BookingStatusChip
                booking={{
                  status_label: "Zapytanie",
                  status: BookingStatus.REQUEST
                }}
              />
            )}
          </div>
        </div>
      )
    }
  ]

  const queryParams = {}
  return (
    <ApiTable
      {...props}
      columns={columns}
      listApi={listChats}
      queryParams={queryParams}
      showExpandColumn
      rowLinkBuilder={(row: any) => {
        if (row.chat_hash.indexOf("book") === 0) {
          return `/bookings/${row.chat_hash}`
        } else {
          return `/chats/${row.chat_hash}`
        }
      }}
    />
  )
}
export const MessagesIndex = withServiceBlocked((props: any) => {
  return (
    <div className="p-8">
      <div className="max-h-screen flex flex-col">
        <div className="my-2 pl-8">
          <PageTitle gutterBottom>Wiadomości</PageTitle>
        </div>
        <div className="flex-1 overflow-y-auto">
          <ChatsTable />
        </div>
      </div>
    </div>
  )
})
