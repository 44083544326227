import { Control, Controller } from "react-hook-form"
import { InputField } from "../../../../components/FormCommons"
import { useEffect } from "react"

interface ReservationPriceFieldProps {
    label: string;
    name: string;
    control: Control
    setValue: (name: string, newValue: number) => void
    price?: number
}

export const ReservationPriceField = ({
                                          label,
                                          name,
                                          control,
                                          setValue,
                                          price,
                                      }: ReservationPriceFieldProps) => {
    useEffect(() => {
        if (price && typeof price === 'number' && price > 0) {
            setValue(name, price)
        } else {
            setValue(name, 0)
        }
    }, [price, setValue])

    return (
        <>
            <Controller
                name={name}
                control={control}
                rules={{
                    min: 0,
                    required: "Pole wymagane."
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    const handleValueChange = event => {
                        const inputValue = parseFloat(event.target.value)

                        if (!isNaN(inputValue)) {
                            const multipliedValue = inputValue * 100
                            onChange(multipliedValue)
                        } else {
                            onChange(null)
                        }
                    }

                    return (
                        <InputField
                            id={name}
                            type="number"
                            label={label}
                            value={value ? value / 100 : ''}
                            onChange={handleValueChange}
                        />
                    )
                }}
            />
        </>
    )
}
