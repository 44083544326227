import { useNavigate } from "react-router-dom";
import { CompanyRegistrationForm } from "./CompanyRegistrationForm";
import {
  FCPackage,
  packageTypes,
  usaPackageTypes,
} from "../../pages/organizer/packages/PackageUtils";
import { PackageItem } from "../../components/packages/PackageItem";
import Fade from "react-reveal/Fade";
import { useEffect, useState } from "react";
import { CompanyRegistrationConfirmEmail } from "./CompanyRegistrationConfirmEmail";

import { TFunction, useTranslation } from "react-i18next";
const Korzysci = ({ t } : { t: TFunction}) => {
  const funtionalityLists1 = [
    t(
        "company_registration_page.personalized_page",
        "personalizowaną stronę z ofertą"
    ),
    t(
        "company_registration_page.unlimited_number_of_added_offers",
        "nielimitowaną ilość dodawania ofert i turnusów"
    ),
    t(
        "company_registration_page.intuitive_reservation_panel",
        "intuicyjny panel do zarządzania rezerwacjami"
    ),
    t(
        "company_registration_page.higher_visibility_of_offers",
        "wiekszą widocznośc swoich ofert w internecie"
    ),
    t(
        "company_registration_page.support_business_manager",
        "wsparcie dedykowanego opiekuna biznesowego"
    )
  ]

  const funtionalityLists2 = [
    t("company_registration_page.", "dostęp do kreatora plakatów i ulotek, wraz z unikatowym kodem QR"),
    t(
        "company_registration_page.payment_processing_system",
        "system obsługi płatności"
    ),
    t(
        "company_registration_page.analysis_number_of_sold_places",
        "analizę ilości zarezerwowanych i sprzedanych miejsc"
    ),
    t(
        "company_registration_page.convenient_contact_with_client",
        "wygodny kontakt z Klientem"
    )
  ]

  return (
    <div id="funkcje" className="pt-14 bg-pattern">
      <div className="mb-2">
        <h3 className=" text-center text-4xl text-fc-primary font-bold ">
          {t("company_registration_page.features", "Funkcjonalności")}
        </h3>
        <p className="text-center text-2xl text-fc-primary mt-4">
          {t(
              "company_registration_page.check_out_benefits",
              "Sprawdź jakie korzyści otrzymasz będąc z nami!"
          )}
        </p>
      </div>

      <div className="flex flex-col md:flex-row max-w-screen-xl m-auto items-center">
        <div>
          <img src="/assets/korzysci.png" alt="" />
        </div>
        <div className="text-fc-primary ">
          <ul className="list-disc list-outside  text-lg px-10">
            {funtionalityLists1.map((item, index) => (
              <li key={index} className="mb-1 lg:mb-4 text-md lg:text-xl">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row max-w-screen-xl m-auto items-center">
        <div className="text-fc-primary ml-10 ">
          <ul className="list-disc list-outside text-lg px-10 max-w-[500px]">
            {funtionalityLists2.map((item, index) => (
              <li key={index} className="mb-1 lg:mb-4 text-md lg:text-xl">
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <img src="/assets/korzysci2.png" alt="Korzysci" />
        </div>
      </div>
    </div>
  );
};

const DodajOferte = ({ onClick }) => {
  return (
    <div id="promocje" className="pt-14 ">
      {/* <div className="mb-8">
        <h3 className=" text-center text-4xl text-fc-primary font-bold ">
          Extra promocja
        </h3>
        <p className="text-center  text-2xl text-fc-primary mt-4 px-3">
          Kompleksowy plan i funkcjonalności, aby wesprzeć Cię w rozwoju Twojej
          firmy.
        </p>
      </div>

      <div className="w-[95%] xl:w-[100%] mx-auto">
        <div className="flex flex-col md:flex-row gap-7 justify-center items-center ">
          {promoPackageTypes.map((p, index) => (
            <PromoPackageCard key={index} product={p} onClick={onClick} />
          ))}
        </div>
      </div> */}

      <div className="mt-9 w-[100%] xl:w-[1445px] mx-auto hidden md:block">
        <img
          src="/assets/promotion-banner.png"
          alt=""
          width="1445"
          height="499"
        />
      </div>

      <div className="mt-9 w-full block md:hidden">
        <img src="/assets/promotion-banner-small.png" alt="" width="100%" />
      </div>
    </div>
  );
};

const PriceList = ({ isUSAVersion, t } : { isUSAVersion: boolean, t: TFunction}) => {
  return (
    <div className="bg-pattern-2 mt-8">
      <div
        id="cennik"
        className="flex flex-col items-center max-w-screen-xl m-auto container pt-16 "
      >
        <div className="mb-8 text-center">
          <div className="font-bold text-4xl text-fc-primary">
            {t("company_registration_page.price", "Cennik")}
          </div>
          {/* <p className="text-2xl text-fc-primary mt-3">Oferta standardowa </p> */}
        </div>

        <div className="flex flex-row flex-wrap lg:flex-nowrap justify-center  mb-8 gap-5 lg:gap-0 ">
          {isUSAVersion
              ? usaPackageTypes
                  .filter((pkg: any) => pkg.active)
                  .map((pkg: FCPackage) => (
                      <PackageItem
                          pkg={pkg}
                          packageName={pkg.name}
                          price={pkg.price}
                          currency="USD"
                          offerVisibility={pkg.offerVisibility}
                          description={pkg.description}
                          features={pkg.features}
                          savings={pkg.savings}
                          bestChoice={pkg.bestChoice}
                          onClick={() => {
                            document
                                .querySelector("#registration-box")
                                .scrollIntoView({
                                  behavior: "smooth"
                                })
                          }}
                      />
                  ))
              : packageTypes
                  .filter((pkg: any) => pkg.active)
                  .map((pkg: FCPackage) => (
                      <PackageItem
                          pkg={pkg}
                          packageName={pkg.name}
                          price={pkg.price}
                          currency="PL"
                          offerVisibility={pkg.offerVisibility}
                          description={pkg.description}
                          features={pkg.features}
                          savings={pkg.savings}
                          bestChoice={pkg.bestChoice}
                          onClick={() => {
                            document
                                .querySelector("#registration-box")
                                .scrollIntoView({
                                  behavior: "smooth"
                                })
                          }}
                      />
                  ))}
        </div>
      </div>
    </div>
  );
};

// const YellowBanner = () => (
//   <div
//     className="
//     flex
//     flex-col md:flex-row
//     justify-center
//     py-4 w-full
//     bg-[#FFBB12]
//     font-bold
//     text-center
//   "
//   >
//     <div className="mx-6 lg:mx-0">PROMOCJA!</div>
//     <div className="mx-6">
//       Darmowy dostęp przez 100 dni, tylko dla 50&nbsp;kont
//     </div>
//     <div className="mx-6 lg:mx-0">DOŁĄCZ TERAZ!!</div>
//   </div>
// )

const SideBanner = ({ t }: { t: TFunction}) => {
  const lists = [
    {
      icon: "tag.svg",
      label: t(
          "company_registration_page.unlimited_number_of_offers",
          "Nielimitowana ilość ofert"
      )
    },
    {
      icon: "chat.svg",
      label: t(
          "company_registration_page.direct_contact_with_customer",
          "Bezpośredni kontakt z Klientem"
      )
    },
    {
      icon: "card.svg",
      label: t(
          "company_registration_page.automated_payments",
          "Zautomatyzowane płatności"
      )
    },
    {
      icon: "document.svg",
      label: t(
          "company_registration_page.business_management_in_one_place",
          "Zarządzanie biznesem w jednym miejscu"
      )
    }
  ]

  return (
    <div
      className="
  w-[80%]
  mx-auto
  flex 
  flex-col
  pt-8
  relative
  h-full
  overflow-hidden 
  lg:overflow-visible
"
    >
      <h2 className="text-center text-3xl  mt-12 font-bold text-[#3C4043]">
        {t(
            "company_registration_page.comprehensive_platform_for_organisers",
            "Kompleksowa platforma dla organizatorów wyjazdów dla dzieci i młodzieży"
        )}
      </h2>

      <ul className="flex flex-wrap gap-10 mt-14">
        {lists.map((list, index) => (
          <li
            key={index}
            className="w-[calc(50%-20px)] flex  flex-col items-center"
          >
            <img
              src={`/assets/svg/${list.icon}`}
              width="68"
              height="68"
              alt=""
            />
            <p className="text-md md:text-2xl lg:text-xl xl:text-2xl text-[#5D5D5D] text-center max-w-[270px]">
              {list.label}
            </p>
          </li>
        ))}
      </ul>

      <div className="flex mt-16 gap-3 justify-center flex-wrap text-[#7E7E7E] text-sm lg:text-lg">
        <p>{t("Booking.Summer_camps", "Kolonie i obozy")}</p>
        <p className="text-[#0C48D7]">•</p>
        <p>{t("Booking.Day_camps", "Półkolonie")}</p>
        <p className="text-[#FDA500]">•</p>
        <p>{t("Booking.School_trips", "Wycieczki szkolne")}</p>
      </div>
    </div>
  );
};

const RegistrationBox = ({ onRegistered, isUSAVersion, t }) => {
  const navigate = useNavigate();
  // Potwierdzone - to jest wykorzystane
  return (
    <div
      className="flex flex-col items-center pt-8 w-[90%] lg:w-[440px] mx-auto"
      id="registration-box"
    >
      <div className="text-center mb-8 text-white font-bold text-4xl mt-5">
        {t(
            "company_registration_page.company_registration",
            "Rejestracja dla FIRM"
        )}
      </div>

      <div>
        <CompanyRegistrationForm
          onConfirmed={() => {
            navigate("/company/register");
          }}
          isUSAVersion={isUSAVersion}
          onRegistered={onRegistered}
        />
      </div>
    </div>
  );
};

const STEP_REGISTRATION = "registration";
const STEP_CONFIRM_EMAIL = "confirmEmail";

export const CompanyRegistrationPage = ({
  initialStep,
  isUSAVersion,
}: {
  initialStep?: string;
  isUSAVersion: boolean;
}) => {
  const [step, setStep] = useState(initialStep ?? STEP_REGISTRATION);

  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (isUSAVersion) {
      i18n.changeLanguage("en")
    } else {
      i18n.changeLanguage("pl")
    }
  }, [isUSAVersion, i18n])

  return (
    <div className="w-full pt-[56px]">
      {/* <YellowBanner /> */}
      <div className="flex flex-col lg:flex-row ">
        <div className="flex-1 bg-[#3AB6D2]">
          {step === STEP_REGISTRATION && (
            <Fade top distance="20px">
              <RegistrationBox
                  isUSAVersion={isUSAVersion}
                  t={t}
                onRegistered={(user) => {
                  setStep(STEP_CONFIRM_EMAIL);
                }}
              />
            </Fade>
          )}
          {step === STEP_CONFIRM_EMAIL && (
            <Fade top distance="20px">
              <CompanyRegistrationConfirmEmail />
            </Fade>
          )}
        </div>
        <div className="flex-1 max-w-[1000px]">
          <SideBanner t={t} />
        </div>
      </div>
      <Fade bottom distance="20px">
        <Korzysci t={t} />
      </Fade>
      <Fade bottom distance="20px">
        <DodajOferte
          onClick={() => {
            document.querySelector("#registration-box").scrollIntoView({
              behavior: "smooth",
            });
          }}
        />
      </Fade>
      <Fade bottom distance="20px">
        <PriceList t={t} isUSAVersion={isUSAVersion} />{" "}
      </Fade>
      <div className="border-t border-[#F6F6F6]">
        <div className="w-[150px] mx-auto my-4 ">
          <img
            src="/assets/logo/logo-with-name-h.svg"
            alt=""
            width="150px"
            height="27px"
          />
        </div>
      </div>
    </div>
  );
  // return (
  //   <Box
  //     p={2}
  //     display="flex"
  //     alignItems="center"
  //     justifyContent="center"
  //     flexDirection="column"
  //     width="100%"
  //   >
  //     <div>Załóż konto</div>
  //     <RegistrationForm
  //       onConfirmed={() => {
  //
  //         navigate("/company/register")
  //       }}
  //     />
  //   </Box>
  // )
};
