import { IconButton, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '../icons/SearchIcon'
import { useTranslation } from 'react-i18next'

export const SearchBoxWithIcon = ({ setSearchTextDebounced }) => {
  const { t } = useTranslation()
  return (
    <div className='flex items-center gap-3'>
      <div>
        <TextField
          onChange={ev => setSearchTextDebounced(ev.target.value)}
          placeholder={t('other.search', 'Wyszukaj')}
          InputProps={{
            style: {
              height: '52px',
              borderRadius: '8px'
            },
            endAdornment: (
              <InputAdornment position='end' className='relative right-2'>
                <IconButton aria-label='toggle password visibility' edge='end'>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    </div>
  )
}
