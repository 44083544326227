import { Routes, Route } from 'react-router-dom'
import { ApiTable } from '../../../components/table/ApiTable'
import { SuperAdminCompanyPage } from '../SuperAdminCompanyPage'
import { FormattedDate, FormattedTime } from 'react-intl'
import axios from 'axios'
import Toolbar from '../Toolbar/Toolbar'
import { useSearchText } from '../useSearchText'
import { ProductUtils } from 'wakacje-web/src/utils/ProductUtils'
import { testIdList } from '../../../utils/testDataID'
import MenuItem from '@mui/material/MenuItem'
import { Select } from '@mui/material'
import { useState } from 'react'
import { ProductTypes } from 'wakacje-web/src/utils/ProductUtils'

const listSurcharges = async data => {
  let url =
    process.env.REACT_APP_WAKACJE_HTTP_API_URL + `/bookings/v2.0/surcharges`
  data = data ?? {}
  if (data) {
    url += '?' + new URLSearchParams(data).toString()
  }
  const res = await axios.get(url)
  if (process.env.NODE_ENV === 'production' && res.data && res.data.data) {
    let allReservations = res.data.data
    res.data.data = allReservations.filter(
      item => !testIdList.includes(item.id)
    )
  }

  return res.data
}

const SuperSurchargesTable = ({ queryParams }) => {
  const columns = [
    {
      header: 'Data zgłoszenia',
      cell: (row: any) => (
        <div>
          <FormattedDate value={row.created_at} />{' '}
          <FormattedTime value={row.created_at} />{' '}
        </div>
      ),
      field: 'created_at'
    },
    {
      header: 'Kwota',
      cell: (row: any) => (
        <>
          <div className='text-xs leading-none'>
            <span className='whitespace-nowrap'>
              {ProductUtils.formatPriceInCents(
                row.amount ?? 0,
                true,
                row.currency
              )}
            </span>
          </div>
        </>
      ),
      sort: false
    },
    {
      header: 'Nr rezerwacji',
      cell: (row: any) => <div className='text-left'>{row.booking_id}</div>,
      field: 'booking_id'
    }
  ]

  return (
    <ApiTable
      columns={columns}
      listApi={listSurcharges}
      showExpandColumn
      queryParams={queryParams}
    ></ApiTable>
  )
}

export const SuperAdminSurcharges = () => {
  const { setSearchTextDebounced, queryParams } = useSearchText()
  const [type, setType] = useState('all')
  const [status, setStatus] = useState('all')

  const mergedQueryParams = { ...queryParams }

  if (type && type !== 'all') {
    let param = type
    mergedQueryParams.product_type = param
  }
  if (status && status !== 'all') {
    mergedQueryParams.status = status
  }

  return (
    <>
      <Toolbar
        setSearchTextDebounced={setSearchTextDebounced}
        api={listSurcharges}
        queryParams={mergedQueryParams}
      >
        <div className='flex flex-row gap-2 items-center'>
          <div>Rodzaj</div>
          <Select
            className='ml-4'
            value={type}
            inputProps={{
              'aria-labelledby': 'table-page-select-label'
            }}
            onChange={e => setType(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: 'min-content'
                }
              }
            }}
          >
            <MenuItem value={'all'}>Wszystkie</MenuItem>
            <MenuItem value={ProductTypes.CAMP}>Kolonie i obozy</MenuItem>
            <MenuItem value={ProductTypes.DAYCAMP}>Półkolonie</MenuItem>
            <MenuItem value={ProductTypes.SCHOOL_TRIP}>
              Wycieczki szkolne
            </MenuItem>
          </Select>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div>Status</div>
          <Select
            className='ml-4'
            value={status}
            inputProps={{
              'aria-labelledby': 'table-page-select-label'
            }}
            onChange={e => setStatus(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: 'min-content'
                }
              }
            }}
          >
            <MenuItem value={'all'}>Wszystkie</MenuItem>
            <MenuItem value={'FULLY_PAID'}>rezerwacja opłacona</MenuItem>
            <MenuItem value={'NEW'}>nowe zgłoszenie</MenuItem>
            <MenuItem value={'ADVANCE_PAID'}>zaliczka opłacona</MenuItem>
            <MenuItem value={'RESIGNED'}>rezygnacja</MenuItem>
            <MenuItem value={'RESERVED'}>zarezerwowano</MenuItem>
          </Select>
        </div>
      </Toolbar>

      <Routes>
        <Route path=':id' element={<SuperAdminCompanyPage />} />
        <Route
          index
          element={<SuperSurchargesTable queryParams={mergedQueryParams} />}
        />
      </Routes>
    </>
  )
}
