import axios from 'axios'
import { useRef, useState } from 'react'
import { ApiTable, ApiTableRefType } from '../../../components/table/ApiTable'
import {
  WakacjeChip,
  WakacjeChipVariant
} from '../../../components/WakacjeChip'
import { ProductTypeChip } from '../../../components/events/ProductTypeChip'
import { Button, Dialog, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProductStatus, ProductUtils } from 'wakacje-web/src/utils/ProductUtils'
import { Link } from 'react-router-dom'
import { useCompanyContext } from '../../../contexts/company-context'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Close } from '@mui/icons-material'
import KidIcon from '../../../components/icons/KidIcon'
import { CalendarIcon } from '../../../components/icons/CalendarIcon'
import { ParentIcon } from '../../../components/icons/ParentIcon'
import clsx from 'clsx'
import { format } from 'date-fns'
import { ProductActionWithLoader } from '../products/ProductsTable'
import { CheckIcon } from '../../../components/icons/CheckIcon'
import { TrashIcon } from '../../../components/icons/TrashIcon'
import { useCurrency } from '../../../hooks/useCurrency'
import { useProductUtils } from '../../../hooks/useProductUtils'

const BookingsNestedTable = () => {}

const listBookings = async data => {
  var url =
    process.env.REACT_APP_WAKACJE_HTTP_API_URL +
    `/bookings/v2.0/bff/merchant/bookings`
  data = data ?? {}
  data.view = 'company'
  if (data) {
    url += '?' + new URLSearchParams(data).toString()
  }
  var res = await axios.get(url)
  // convert to participants
  const participants = []

  for (var booking of res.data.data) {
    if (booking.participants) {
      for (var p of booking.participants) {
        participants.push({
          ...booking,
          participant: { ...(p.person ?? p), parent: booking.parent },
          booking: booking
        })
      }
    }
  }
  res.data.data = participants
  return participants
}

const buildColumns = ({
  layout,
  t,
  setPopupData,
  setOpenParticipant,
  selectedCurrency,
  formatPriceInCents
}) => {
  var columns = [
    {
      header: t('Booking.Update_Date', 'Data aktualizacji'),
      cell: (row: any) => (
        <>
          <div className='text-xs leading-none'>
            {row.updated_at && (
              <div>
                <FormattedDate value={row.updated_at} />{' '}
                <FormattedTime value={row.updated_at} />
              </div>
            )}
          </div>
        </>
      ),
      sort: false,
      field: 'updated_at'
    },
    {
      header: t('Booking.participant', 'Uczestnik'),
      cell: (row: any) => {
        var person = row?.participant
        return (
          <>
            {person && (
              <div
                onClick={() => {
                  setPopupData({ participant: person, booking: row })
                  setOpenParticipant(true)
                }}
              >
                <p className='text-base font-bold underline text-fc-accent-3 hover:no-underline'>
                  {person?.first_name} {person?.last_name}
                </p>
                <p className='text-xs mt-1'>{person?.birth_date}</p>
              </div>
            )}
          </>
        )
      },
      sort: false
    },
    {
      header: t('Booking.Parent', 'Opiekun'),
      cell: (row: any) => (
        <>
          <div className='text-xs leading-none'>
            {row.parent?.first_name} {row.parent?.last_name}
          </div>
        </>
      ),
      sort: false
    }
  ]

  const colStatus = {
    header: t('Booking.Status', 'Status'),
    cell: (row: any) => (
      <>
        <WakacjeChip
          label={t(`Booking.${row.status}`, row.status_label)}
          variant={WakacjeChipVariant.GREEN}
        />
      </>
    ),
    sort: false,
    field: 'status'
  }

  const colDeparture = {
    header: t('Booking.Departure', 'Miejsce wyjazdu'),
    cell: (row: any) => (
      <>
        <div className='text-xs leading-none'>
          {t(`Booking.${row.departure_place?.name}`, row.departure_place?.name)}
        </div>
      </>
    ),
    sort: false
  }

  const colAmount = {
    header: t('Booking.Amount', 'Kwota'),
    cell: (row: any) => {
      const participantsCount = row.participants_count ?? 1
      return (
        <>
          <div className='text-xs leading-none'>
            <span className='whitespace-nowrap'>
              {row.advance_payment_price
                ? `${formatPriceInCents(
                    row.paid_amount / participantsCount ?? 0,
                    row?.currency
                  )} ${ProductUtils.getCurrencyCode(
                    selectedCurrency.currency
                  )}` + '/'
                : ''}
            </span>
            <span className='whitespace-nowrap'>
              {row.total_price
                ? `${formatPriceInCents(
                    row.total_price / participantsCount,
                    row?.currency
                  )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
                : ''}
            </span>
          </div>
        </>
      )
    },
    sort: false
  }

  const colPricePerPax = {
    header: t('Booking.Price_per_pax', 'Cena za 1 os'),
    cell: (row: any) => (
      <>
        <div className='text-xs leading-none'>
          <span className='whitespace-nowrap'>
            {row.total_price
              ? `${formatPriceInCents(
                  row.total_price ?? 0 / row.participants_count,
                  row?.currency
                )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
              : ''}
          </span>
        </div>
      </>
    ),
    sort: false
  }

  const colPaidAmount = {
    header: t('Booking.Amount', 'Kwota'),
    cell: (row: any) => (
      <>
        <div className='text-xs leading-none'>
          <span className='whitespace-nowrap'>
            {row.advance_payment_price
              ? `${formatPriceInCents(
                  row.paid_amount ?? 0 / row.participants_count,
                  row?.currency
                )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
              : ''}
          </span>
        </div>
      </>
    ),
    sort: false
  }

  const colNr = {
    header: t('Booking.Booking_number', 'Nr rezerwacji'),
    cell: (row: any) => (
      <>
        <div className='text-xs leading-none'>{row.id}</div>
      </>
    ),
    sort: false
  }

  if (layout === 'participants') {
    columns = [
      ...columns,
      colStatus,
      colNr,
      colPricePerPax,
      colPaidAmount,
      colDeparture
    ]
  } else {
    columns = [
      ...columns,

      {
        header: t('Booking.event', 'Nazwa wydarzenia'),
        cell: (row: any) => (
          <>
            <div className='leading-none pb-2'>
              <p className='font-semibold text-xs mb-3'>{row.product?.name}</p>
              <ProductTypeChip type={row.product?.type} />
            </div>
          </>
        ),
        sort: false
      },
      {
        header: t('Booking.Term', 'Termin'),
        cell: (row: any) => (
          <>
            <div className='leading-none font-bold text-xs'>
              <p className='whitespace-nowrap'>{row.start_date}-</p>
              <p className='whitespace-nowrap'>-{row.end_date}</p>
            </div>
          </>
        ),
        sort: false
      },
      colDeparture,
      colAmount,
      colNr,
      colStatus
    ]
  }

  // columns.push({
  //   header: t("ui.booking.column11", "Czat z opiekunem"),
  //   cell: (row: any) => (
  //     <>
  //       <Link to={`/bookings/${row.id}`}>
  //         <Button
  //           variant="outlined"
  //           size="small"
  //           sx={{ borderRadius: "9999px", whiteSpace: "nowrap" }}
  //         >
  //           {t("ui.booking.open_chat", "Otwórz czat")}
  //         </Button>
  //       </Link>
  //     </>
  //   ),
  //   sort: false
  // })
  return columns
}

const LabelWithText = ({
  className,
  label,
  children,
  textLarge,
  textBold
}: {
  className?: string
  label: string
  children: any
  textLarge?: boolean
  textBold?: boolean
}) => {
  return (
    <div className={clsx('flex flex-col gap-0.5 px-4', className)}>
      <p className='text-xs text-[#707070] leading-[16px] font-semibold'>
        {label}
      </p>
      <p
        className={clsx(
          'leading-[23px]',
          textLarge ? 'text-lg' : 'text-base',
          textBold && 'font-bold'
        )}
      >
        {children}
      </p>
    </div>
  )
}

const DialogParticipant = ({
  open,
  participant,
  booking,
  onClose
}: {
  open: boolean
  participant: any
  booking: any
  onClose: () => void
}) => {
  const { t } = useTranslation()

  const { selectedCurrency } = useCurrency()

  const { formatPriceInCents } = useProductUtils()
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: '564px',
          width: '100%',
          borderRadius: '8px'
        }
      }}
      onClose={onClose}
    >
      {participant && (
        <div className='flex flex-col gap-4 pt-6 pb-10 px-10'>
          <div className='flex justify-end'>
            <div className='cursor-pointer' onClick={onClose}>
              <Close width={16} />
            </div>
          </div>
          <div className='flex flex-col gap-6'>
            <div className='flex items-center px-4 gap-6 h-[96px] bg-[#0559E80D] rounded-lg'>
              <KidIcon />
              <div className='flex flex-col'>
                <p className='text-lg leading-[22px]'>
                  {t('Booking.Participant_data', 'Dane uczestnika')}
                </p>
                <p className='text-2xl leading-[38px] font-bold'>
                  {participant.first_name} {participant.last_name}
                </p>
                <p className='text-lg leading-[22px]'>
                  {new Date().getFullYear() -
                    new Date(participant.birth_date).getFullYear()}{' '}
                  {t('Booking.years_old', 'lat')}
                </p>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <LabelWithText
                label={t('Booking.birth_date', 'Data urodzenia')}
                className='col-span-2'
                textLarge
              >
                {format(new Date(participant.birth_date), 'dd.MM.yyyy')}
              </LabelWithText>
              <LabelWithText label={t('Booking.Country', 'Kraj')}>
                {' '}
                {participant.country ?? 'Polska'}
              </LabelWithText>
              <LabelWithText label={t('Booking.Wojewodztwo', 'Województwo')}>
                {participant.province}
              </LabelWithText>
              <LabelWithText label={t('Booking.Kod_pocztowy', 'Kod pocztowy')}>
                {' '}
                {participant.postcode}
              </LabelWithText>
              <LabelWithText label={t('Booking.Town', 'Miejscowość')}>
                {' '}
                {participant.city}
              </LabelWithText>
              <LabelWithText
                label={t('Booking.Street', 'Ulica, nr domu i lokalu')}
                className='col-span-2'
              >
                {participant.street} {participant.street_number}{' '}
                {participant.house_number}
              </LabelWithText>
              <LabelWithText
                label={t('Booking.additional_information')}
                className='col-span-2'
              >
                {'---'}
              </LabelWithText>
            </div>
          </div>
          <Divider />
          <div className='flex flex-col gap-6'>
            <div className='flex items-center px-4 gap-6 h-[96px] bg-[#0559E80D] rounded-lg'>
              <CalendarIcon />
              <p className='text-2xl leading-[38px] font-bold'>
                {t('Booking.reservation', 'Rezerwacja')}
              </p>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <LabelWithText
                label={t('Booking.Booking_number', 'Nr rezerwacji')}
                textLarge
              >
                {booking.id}
              </LabelWithText>
              <LabelWithText label={t('Booking.Status', 'Status')}>
                <WakacjeChip
                  label={t(`Booking.${booking.status}`)}
                  variant={WakacjeChipVariant.GREEN}
                />
              </LabelWithText>
              {booking.fully_paid_at || booking.advance_paid_at ? (
                <LabelWithText
                  label={t('Booking.Last_pay', 'Data ostatniej płatności')}
                >
                  {new Date(
                    booking.fully_paid_at || booking.advance_paid_at
                  ).toLocaleDateString()}
                </LabelWithText>
              ) : (
                <LabelWithText
                  label={t('Booking.Pay_term', 'Termin płatności')}
                >
                  {booking.payment_due_date}
                </LabelWithText>
              )}
              <LabelWithText label={t('Booking.Payment', 'Płatność')} textBold>
                <span className='whitespace-nowrap text-[#5CAA0B]'>
                  {`${formatPriceInCents(
                    booking.paid_amount ?? 0,
                    booking?.currency
                  )} ${ProductUtils.getCurrencyCode(
                    selectedCurrency.currency
                  )}`}
                  /
                </span>
                <span className='whitespace-nowrap text-[#5CAA0B]'>
                  {booking.total_price
                    ? `${formatPriceInCents(
                        booking.total_price,
                        booking?.currency
                      )} ${ProductUtils.getCurrencyCode(
                        selectedCurrency.currency
                      )}`
                    : ''}
                </span>
              </LabelWithText>
              <LabelWithText label={t('Booking.Departure', 'Miejsce wyjazdu')}>
                {t(
                  `Booking.${booking.departure_place?.name}`,
                  booking.departure_place?.name
                )}
              </LabelWithText>
            </div>
          </div>
          <Divider />
          <div className='flex flex-col gap-6'>
            <div className='flex items-center px-4 gap-6 h-[96px] bg-[#0559E80D] rounded-lg'>
              <ParentIcon />
              <div className='flex flex-col'>
                <p className='text-lg leading-[22px]'>
                  {t('Booking.Camp_supervisor', 'Dane opiekuna')}
                </p>
                <p className='text-2xl leading-[38px] font-bold'>
                  {participant.parent.first_name} {participant.parent.last_name}
                </p>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <LabelWithText label={t('Booking.phone')} textLarge>
                {participant.parent.phone}
              </LabelWithText>
              <LabelWithText label='E-mail' textLarge>
                {participant.parent.email}
              </LabelWithText>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  )
}

export const BookingsTable = ({
  queryParams,
  layout = 'bookings',
  type
}: {
  queryParams: any
  layout?: string
  type?: ProductStatus
}) => {
  const { t } = useTranslation()
  const tableRef = useRef<ApiTableRefType>()
  const [selectedRow, setSelectedRow] = useState(null)
  const companyContext = useCompanyContext()

  const [openParticipant, setOpenParticipant] = useState(false)
  const [popupData, setPopupData] = useState(undefined)

  const { selectedCurrency } = useCurrency()
  const { formatPriceInCents } = useProductUtils()

  var columns = buildColumns({
    t,
    layout,
    setOpenParticipant,
    setPopupData,
    selectedCurrency,
    formatPriceInCents
  })

  const lqueryParams = {
    ...queryParams,
    company_id: companyContext.company?.id,
    ...(type && { liveness_status: type })
  }

  const refetch = () => {
    if (tableRef.current) {
      tableRef.current.refetch()
    }
  }

  const handleChangeBookingStatus = async (
    bookingId: string,
    status: ProductStatus
  ) => {
    const body = { status: status }
    var url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL +
      `/bookings/v2.0/booking/change-liveness-status/${bookingId}`
    try {
      const result = await axios.post(url, body)
      refetch()
    } catch (error) {}
  }
  if (type) {
    return (
      <>
        <ApiTable
          ref={tableRef}
          columns={columns}
          listApi={listBookings}
          queryParams={lqueryParams}
          showExpandColumn
          nestedTableComponent={BookingsNestedTable}
          rowDetailsRenderer={() => <></>}
          selectedRow={selectedRow}
          rowPopupBuilder={row => {
            if (row.liveness_status === ProductStatus.ARCHIVED) {
              return (
                <ProductActionWithLoader
                  label={t('activate', 'Aktywuj')}
                  onClick={() =>
                    handleChangeBookingStatus(row.id, ProductStatus.ACTIVE)
                  }
                  icon={CheckIcon}
                />
              )
            }
            if (row.liveness_status === ProductStatus.ACTIVE) {
              return (
                <ProductActionWithLoader
                  label={t('archive', 'Archiwizuj')}
                  onClick={() =>
                    handleChangeBookingStatus(row.id, ProductStatus.ARCHIVED)
                  }
                  icon={TrashIcon}
                />
              )
            }
          }}
        />
        {popupData && (
          <DialogParticipant
            open={!!popupData}
            participant={popupData?.participant}
            booking={popupData?.booking}
            onClose={() => setPopupData(undefined)}
          />
        )}
      </>
    )
  }
  return (
    <>
      <ApiTable
        ref={tableRef}
        columns={columns}
        listApi={listBookings}
        queryParams={lqueryParams}
        showExpandColumn
        nestedTableComponent={BookingsNestedTable}
        rowDetailsRenderer={() => <></>}
        selectedRow={selectedRow}
      />
      {popupData && (
        <DialogParticipant
          open={!!popupData}
          participant={popupData?.participant}
          booking={popupData?.booking}
          onClose={() => setPopupData(undefined)}
        />
      )}
    </>
  )
}
