import React from 'react'
import {
  useNavigate,
  useParams,
  useLocation,
  resolvePath
} from 'react-router-dom'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'

import { PageTitle } from '../../../components/typography'
import { MyCompanyAbout } from './MyCompanyAbout'
import { CompanyPaymentsIndex } from '../payments/CompanyPaymentsIndex'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { MyPackage } from '../packages/MyPackage'
import { ModalPackageContextProvider } from '../../../contexts/package-modal-context'
import {useTranslation} from "react-i18next";

type TabData = {
  path: string
  label?: string
  element?: React.ReactNode
  icon?: React.ReactNode
}

const tabs: TabData[] = [
  {
    path: 'info',
    label: 'Informacje',
    element: <MyCompanyAbout />,
    icon: <FlagOutlinedIcon />
  },
  // {
  //   path: "payments",
  //   label: "Płatności",
  //   element: <CompanyPaymentsIndex />,
  //   icon: <FlagOutlinedIcon />,
  // },
  {
    path: 'packets',
    label: 'Pakiety',
    element: <MyPackage />,
    icon: <HourglassEmptyIcon />
  }
]

export const MyCompanyIndex = () => {
  const { t } = useTranslation()
  const { tab } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const tabIndex = tabs.findIndex((tabData: TabData) => tabData['path'] === tab)
  if (tabIndex < 0) navigate(resolvePath(`../info`, pathname))

  return (
    <ModalPackageContextProvider>
      <Box
        marginTop={1}
        sx={{ paddingX: '64px', paddingY: '32px', margin: '0' }}
      >
        <>
          <PageTitle sx={{ paddingY: '16px', fontWeight: 'bold' }}>
            {t("ui.menu.about_company", "O organizatorze")}
          </PageTitle>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                value={tabIndex}
                aria-label='organizatorze tab'
                variant='scrollable'
            >
              {tabs.map(tab => (
                  <Tab
                      onClick={() =>
                          navigate(resolvePath(`../${tab['path']}`, pathname))
                      }
                      label={
                        <div className='flex justify-center items-center'>
                          <div className='mr-1'>{tab['icon']}</div>
                          {t(tab['path'], tab['label'])}
                        </div>
                      }
                      key={tab['path']}
                  />
              ))}
            </Tabs>
          </Box>
          {tabs?.[tabIndex]?.['element']}
        </>
      </Box>
    </ModalPackageContextProvider>
  )
}
