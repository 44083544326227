import { CircularProgress } from "@mui/material"

import { useFieldArray, useFormContext } from "react-hook-form"
import { LabeledField } from "../../../components/FormCommons"
import { EditableFormSection } from "../../../components/forms/EditableFormSection"
import { UidUtils } from "../../../utils/uid-utils"
import { useFileUpload } from "../../../utils/useFileUpload"
import { useProductApi } from "../events/api/useProductApi"
import { FileUtils } from "wakacje-web/src/utils/file-utils"
import { useState } from "react"
import { FormSectionContainer } from "./forms/components/FormSectionContainer"
import {useTranslation} from "react-i18next";

const File = ({
  fi,
  handleRemoveAttachment,
  handleDownload
}: {
  fi: any
  handleRemoveAttachment?: Function
  handleDownload?: Function
}) => {
  return (
    <div
      className="font-normal px-4 py-4  rounder-lg flex flex-row gap-2 items-center cursor-pointer"
      style={{
        border: "1px solid #CFDBF9",
        borderRadius: 8
      }}
      onClick={() => (handleDownload ? handleDownload(fi) : null)}
    >
      <img src="/assets/svg/file.svg" width="24" height="24" alt="file icon" />
      <div className="min-w-[90px] font-bold text-sm">{fi.name}</div>

      {handleRemoveAttachment && (
        <img
          className="cursor-pointer transition ease-in-out hover:scale-110"
          onClick={(event) => {
            if (handleRemoveAttachment) {
              event.preventDefault()
              handleRemoveAttachment(fi.key)
            }
          }}
          src="/assets/svg/close-circle.svg"
          width="20"
          height="20"
          alt="close icon"
        />
      )}
    </div>
  )
}

const FileLists = ({ files, handleRemoveAttachment }) => {
  return (
    <>
      {files &&
        files.length > 0 &&
        files.map((fi) => {
          return (
            <File
              key={fi.id}
              fi={fi}
              handleRemoveAttachment={handleRemoveAttachment}
            />
          )
        })}
    </>
  )
}

export const ProductSectionFilesForm = ({
  register,
  values,
  setValue,
  showSection
}: any) => {
  const { t } = useTranslation()

  const { uploadFile } = useFileUpload()
  const { watch, control } = useFormContext()
  const [isLoading, setIsLoading] = useState(false)

  // var val = control._getFieldArray("file_sections")
  //
  // const { fields, append } = useFieldArray({
  //   control, // control props comes from useForm (optional: if you are using FormContext)
  //   name: "file_sections", // unique name for your Field Array
  //   keyName: "_formsid"
  // })

  const attachmentsField = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "files", // unique name for your Field Array
    keyName: "_formsid"
  })
  const watchFileAttachments = watch("files")
  const watchNoFiles = watch("no_files")

  const handleFileChanged = async (event, index) => {
    if (event.target.files.length > 0) {
      const nextFile = event.target.files[0]
      try {
        setIsLoading(true)
        const req = {
          type: "COMPANY",
          companyId: values.company_id,
          fileName: nextFile.name,
          prefix: `products/${values.id}`
        }
        var res: any = await uploadFile(req, nextFile)
        if (res && res.key) {
          const newFile = {
            id: UidUtils.generateUid(),
            // section: index,
            key: res.key,
            name: nextFile.name,
            bucket: res.bucket
          }
          attachmentsField.append(newFile)
          setIsLoading(false)
        }
      } catch (err) {
        console.error("Error uploading file")
      }
    }
  }

  const handleRemoveAttachment = (key) => {
    const idx = watchFileAttachments.findIndex((a) => a.key === key)
    attachmentsField.remove(idx)
  }

  // let fileSections = watch("file_sections")

  return (
    <FormSectionContainer
      sectionTitle={t("files", "Pliki")}
      sectionSubtitle={t("add_optional_trip_files", "Dodaj opcjonalnie pliki związane z organizowanym wyjazdem.")}
    >
      {/* 
      <InputCheck
        control={control}
        label="Nie mam plików do zamieszczenia"
        name="no_files"
      /> */}

      {/* {!watchNoFiles && ( */}
      <>
        {/* {fields.map((fs: any, index) => { */}
        {/*
            const sectionFiles = watchFileAttachments
              ? watchFileAttachments.filter((a) => a.section === fs.id)
              : []

            var attrs = register(`file_sections.${index}.name`)

            return ( */}
        {/* {showSection && (
                  <>
                    <div className="font-bold text-lg">Sekcja {index + 1}</div>
                    <InputField label="Nazwa sekcji" {...attrs} />
                  </>
                )} */}

        <div style={{ display: "none" }}>
          <input
            type="file"
            id={`fileDialog`}
            onChange={(event) => {
              handleFileChanged(event, undefined) // fs.id)
            }}
            value=""
          />
        </div>

        <div className="flex flex-row pb-4 mt-4 flex-wrap gap-4">
          <div
            style={{
              background: "#F3F6FD",
              color: "#0C48D7"
            }}
            className="font-bold px-6 py-4 bg-[#F3F6FD] rounder-lg cursor-pointer"
            onClick={(ev) => {
              document.getElementById("fileDialog").click()
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : "+ Dodaj plik"}
          </div>

          <FileLists
            files={watchFileAttachments}
            handleRemoveAttachment={handleRemoveAttachment}
          />
        </div>
        {/* // }
          )} */}

        {/* {(showSection || fileSections?.length === 0) && (
            <div className="text-center">
              <Button
                variant="outlined"
                color="primary"
                onClick={(ev) => {
                  append({
                    id: UidUtils.generateUid("")
                  })
                }}
              >
                Dodaj kolejną pozycję +
              </Button>
            </div>
          )} */}
      </>
    </FormSectionContainer>
  )
}

export const ProductSectionFilesPreview = ({ values }: any) => {
  const { t } = useTranslation()

  return (
    <div className="px-4">
      <LabeledField label={t("files", "Pliki")} className="my-0">
        {/* {values.file_sections &&
        values.file_sections.map((fs: any) => (
          <div>
            <div className="text-lg font-bold">{fs.name}</div>
            <div className="flex flex-row pb-4 mt-4 flex-wrap gap-4"> */}
        {values.files && values.files.length > 0 ? (
          values.files.map((fil: any) => {
            var ret = (
              <div className="mb-4">
                <File
                  fi={fil}
                  handleDownload={(fi) => {
                    FileUtils.openS3File(fi)
                  }}
                />
              </div>
            )

            return ret
          })
        ) : (
          <span>-</span>
        )}
      </LabeledField>
      {/* </div>
          </div>
        ))} */}
    </div>
  )
}

export const ProductSectionFilesView = ({ values, refetch }: any) => {
  const { t } = useTranslation()

  const { updateProduct } = useProductApi()

  const saveHandler = async (data: any) => {
    var productData: any = {
      id: values.id,
      // file_sections: data.file_sections,
      files: data.files
    }
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  var _values = { ...values }
  _values.files = _values.files === null ? [] : _values.files
  // _values.file_sections = _values.file_sections ?? []
  return (
    <EditableFormSection
      title={t("files", "Pliki")}
      values={_values}
      onSave={saveHandler}
      onSaved={handleRefresh}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionFilesForm
                values={_values}
                {...builderProps}
                showSection={false}
              />
            )}
            {!builderProps.isEditing && (
              <ProductSectionFilesPreview values={_values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
