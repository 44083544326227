import { useState } from 'react'
import { MojeButton } from '../../components/ui/MojeButton'
import { CSVDownload } from 'react-csv'
import { useCompanyContext } from '../../contexts/company-context'

export const SuperDataExportButton = ({
  api,
  queryParams
}: {
  api: any
  queryParams?: any
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [csvData, setCsvData] = useState<any>()
  const companyContext = useCompanyContext()

  const unnest = (obj, prefix = '') => {
    var res = {}

    Object.keys(obj).forEach((key: string) => {
      if (
        typeof obj[key] === 'object' &&
        !Array.isArray(obj[key]) &&
        obj[key] !== undefined &&
        obj[key] != null
      ) {
        Object.keys(obj[key]).forEach((k2: string) => {
          res[key + '_' + k2] = obj[key][k2]
        })
      } else if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          Object.keys(obj[key][i]).forEach((k2: string) => {
            res[key + '_' + (i + 1) + '_' + k2] = obj[key][i][k2]
          })
        }
      } else {
        res[key] = obj[key]
      }
    })
    return res
  }
  const handleExport = async () => {
    setIsLoading(true)
    setCsvData(undefined)
    var params = { limit: 500, company_id: companyContext.company?.id }

    if (queryParams) {
      params = { ...params, ...queryParams }
    }
    var data = await api(params)
    if (data) {
      var rows = Array.isArray(data) ? data : data.data
      if (rows && rows.length > 0) {
        // var headers = Object.keys(rows[0])
        // const merged = [headers, ...rows]
        // setCsvData(rows.map(unnest))
        setCsvData(data)
      }
    }
    setIsLoading(false)
  }
  return (
    <div className='flex flex-row'>
      <div className='w-[133px] h-[40px]'>
        <MojeButton
          label='Export'
          isSaving={isLoading}
          onClick={handleExport}
          variant='outlined'
          fullHeight
        />
      </div>

      <div className='ml-4'>
        {csvData && <CSVDownload data={csvData} target='_blank' />}
      </div>
    </div>
  )
}
