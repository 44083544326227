import { ApiTable, ApiTableRefType } from "../../../components/table/ApiTable"
import { usePlacesApi } from "./usePlacesApi"
import { useCompanyContext } from "../../../contexts/company-context"
import { OptionButton } from "./OptionButton"
import { useRef } from "react"
import { useSnackbar } from "notistack"
import {useTranslation} from "react-i18next";

function PlacesSummary(props: any) {
  return props.result?.page_info?.total_items ? (
    <div className="my-4 font-bold">
      Liczba {props.type === "accommodation" ? "noclegów" : "atrakcji"}:{" "}
      {props.result.page_info.total_items}
    </div>
  ) : (
    <span />
  )
}
function PlacesTable({ type }: { type: "place" | "accommodation" }) {
  const { t } = useTranslation()

  const api = usePlacesApi()
  const { runCommand } = usePlacesApi()
  const companyContext = useCompanyContext()
  const { enqueueSnackbar } = useSnackbar()

  let columns: any = []
  columns = [
    ...columns,
    ...[
      {
        header: type === "accommodation" ? t("ui.attractions.attraction_name", "Nazwa ośrodka") : t("ui.attractions.accommodation_name", "Nazwa atrakcji"),
        cell: (row: any) => (
          <div className="font-bold  py-2 text-lg">{row.name}</div>
        ),
        sort: false
      },
      {
        header: t("ui.attractions.country", "Kraj"),
        cell: (row: any) => <div>{row.country}</div>,
        sort: false
      },
      {
        header: t("ui.attractions.province", "Województwo"),
        cell: (row: any) => <div>{row.province}</div>,
        sort: false
      },
      {
        header: t("ui.attractions.city", "Miejscowość"),
        cell: (row: any) => <div>{row.city}</div>,
        sort: false
      },
      {
        header: t("options", "Opcje"),
        cell: (row: any) => (
          <OptionButton
            row={row}
            handleDelete={handleDeletePlace}
            options={["edit", "remove"]}
          />
        ),
        sort: false
      }
    ]
  ]

  const tableRef = useRef<ApiTableRefType>()
  const refetch = () => {
    if (tableRef.current) {
      tableRef.current.refetch()
    }
  }

  const queryParams = {
    
    company_id: companyContext.company?.id,
    type: type,
    sort_by: "name"
  }

  const handleDeletePlace = async (place) => {
    const command = {
      type: "Place.Delete",
      aggregateId: place.id,
      payload: {}
    }
    try {
      var res = await runCommand(command)
      enqueueSnackbar("Miejsce usunięte!", { variant: "success" })
      refetch()
    } catch (err) {
      console.error("Error delete place")
    }
  }

  return (
    <ApiTable
      ref={tableRef}
      columns={columns}
      listApi={api.listPlaces}
      queryParams={queryParams}
      showExpandColumn
      rowLinkBuilder={(row: any) => `/places/${type}/${row.id}`}
      summaryComponent={(props) => <PlacesSummary {...props} type={type} />}
      sortAlphabetically
    />
  )
}

export default PlacesTable
