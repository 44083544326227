import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material'
import { useCurrency } from '../../../hooks/useCurrency'

export const CampFeesCurrency = ({ control }) => {
    const { t } = useTranslation()

    const { CURRENCY_LIST } = useCurrency()

    return (
        <>
            <div className='mb-2 mt-6 text-normal font-bold'>
                {t("payment_form.currency", "Waluta")}
            </div>
            <Controller
                name='currency'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    const handleChange = (event: SelectChangeEvent) => {
                        onChange(event.target.value);
                    };

                    return (
                        <div>
                            <FormControl sx={{ my: 1, minWidth: 240 }}>
                                <InputLabel id="select-currency-label">{t("payment_form.select_currency", "Wybierz walute")}</InputLabel>
                                <Select
                                    labelId="select-currency-label"
                                    id="select-currency-helper"
                                    value={value ?? undefined}
                                    label={t("payment_form.select_currency", "Wybierz walute")}
                                    onChange={handleChange}
                                >
                                    {CURRENCY_LIST.map(({ currency, name, symbol }) => (
                                        <MenuItem
                                            key={currency}
                                            value={currency}
                                        >
                                            {currency} / {name} ({symbol})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )
                }}
            />
        </>
    )
}
