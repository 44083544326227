import { WakacjeChip, WakacjeChipVariant } from "../components/WakacjeChip"
import { BookingStatus } from "../../shared/wakacje-types"
import { useTranslation } from "next-i18next"

const bookingStatusTranslations = {
  all: {
    variants: ["wszystkie", "all"]
  },
  reservation_paid: {
    variants: ["rezerwacja opłacona", "reservation paid", "reservation_paid"]
  },
  new_booking: {
    variants: ["nowe zgłoszenie", "new booking", "new_booking"]
  },
  advance_paid: {
    variants: ["zaliczka opłacona", "advance paid", "advance_paid"]
  },
  cancellation: {
    variants: ["rezygnacja", "cancellation"]
  },
  reserved: {
    variants: ["zarezerwowano", "reserved"]
  }
}

const normalizeStr = (str) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()

const translateStatusLabel = (label, t) => {
  const normalizedLabel = normalizeStr(label)
  for (const key in bookingStatusTranslations) {
    const { variants } = bookingStatusTranslations[key]
    for (const variant of variants) {
      if (normalizeStr(variant) === normalizedLabel) {
        return t(`bookingStatusChip.${key}`)
      }
    }
  }
  return label
}

const getBookingStatusChipVariant = (booking) => {
  switch (booking.status) {
    case BookingStatus.NEW:
    case BookingStatus.RESERVED:
    case BookingStatus.DRAFT:
      return WakacjeChipVariant.YELLOW;

    case BookingStatus.ADVANCE_PAID:
    case BookingStatus.FULLY_PAID:
    case BookingStatus.ACTIVE:
      return WakacjeChipVariant.GREEN;

    case BookingStatus.CANCELED:
    case BookingStatus.RESIGNED:
    case BookingStatus.REJECTED:
      return WakacjeChipVariant.RED;

    case BookingStatus.REQUEST:
      return WakacjeChipVariant.BLUE;

    default:
      return WakacjeChipVariant.GREY;
  }
};


export const BookingStatusChip = ({ booking }) => {
  const { t } = useTranslation()

  const variant = getBookingStatusChipVariant(booking)

  const label = translateStatusLabel(booking.status_label ?? booking.status, t)

  return <WakacjeChip label={label} variant={variant} />
}
