import { Controller, useFormContext } from "react-hook-form"
import {
    FormControl,
    FormControlLabel,
    InputAdornment, InputLabel, MenuItem,
    Radio,
    RadioGroup, Select
} from "@mui/material"
import { InputField } from "../../../../../components/FormCommons"
import { useEffect } from "react"
import {useTranslation} from "react-i18next";
import {useCurrency} from "../../../../../hooks/useCurrency";

export const ItemRow = ({
  index,
  register,
  onRemove,
  control,
  watch,
  type,
  ...props
}) => {
  const { t } = useTranslation()
  const { CURRENCY_LIST } = useCurrency()

  const { setValue, getValues } = useFormContext()

  const currency = watch(`places.${index}.currency`) ?? "PLN"
  function getCurrencySymbol(currency: string): string {
      const currencyData = CURRENCY_LIST.find(item => item.currency === currency);
      return currencyData ? currencyData.symbol.toLowerCase() : "zł";
  }

  useEffect(() => {
    if (props.isIndividualTrip) {
      setValue(`places.${index}.price`, undefined)
    }
  }, [])

  const place: any = {}
  register(`places.${index}.name`) // keep register!
  const placeVal = watch(`places.${index}`)

  return (
    <div className="mb-4 flex flex-row border-b pb-4">
      <div className="flex-1">
        <div className="font-bold mb-4">Nazwa atrakcji</div>
        <div className="flex flex-row items-center">
          <div className="mb-4 flex-1">{placeVal?.place?.name}</div>
          <div className="ml-4 cursor-pointer">
            <div
              className="text-red-500 cursor-pointer hover:underline"
              onClick={() => onRemove(place)}
            >
              Usuń
            </div>
          </div>
        </div>

        <div className="font-bold mb-4">Cena</div>
        <div>
          <Controller
            name={`places.${index}.price_type`}
            control={control}
            render={({
              field,
              fieldState: { invalid, isTouched, isDirty, error },
              formState
            }) => (
              <RadioGroup
                {...field}
                onChange={event => {
                  field.onChange(event)
                  if (event.target.value === "included") {
                    if (placeVal?.price !== undefined) {
                      setValue(`places.${index}.price`, undefined)
                    }
                  }
                }}
              >
                {props.isIndividualTrip && (
                  <FormControlLabel
                    label="w cenie wycieczki / bezpłatnie"
                    value="included"
                    control={
                      <Radio
                        value="included"
                        checked={field.value === undefined}
                      />
                    }
                  />
                )}
                {!props.isIndividualTrip && (
                  <>
                    <FormControlLabel
                      label="w cenie wycieczki / bezpłatnie"
                      value="included"
                      control={<Radio value="included" />}
                    />

                    <div className="flex flex-row">
                      <div>
                        <FormControlLabel
                          label="atrakcja fakultatywna"
                          value="optional"
                          control={<Radio value="optional" />}
                        />
                      </div>
                      {placeVal.price_type === "optional" && (
                        <div>
                          <InputField
                            label="Cena/ 1 osobę*"
                            {...register(`places.${index}.price`)}
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {getCurrencySymbol(currency)}
                                </InputAdornment>
                              )
                            }}
                          />

                          <FormControl sx={{ my: 1, width: "100%" }}>
                            <InputLabel id="select-currency-label">{t("payment_form.select_currency", "Wybierz walute")}</InputLabel>
                            <Select
                                labelId="select-currency-label"
                                id="select-currency-helper"
                                label={t("payment_form.select_currency", "Wybierz walute")}
                                {...register(`places.${index}.currency`)}
                                value={currency}
                            >
                                {CURRENCY_LIST.map(({ currency, name, symbol }) => (
                                    <MenuItem
                                        key={currency}
                                        value={currency}
                                    >
                                        {currency} / {name} ({symbol})
                                    </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup>
            )}
          />
        </div>
      </div>
    </div>
  )
}
