import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Trip } from "../../../types/wakacje-types"
import { useForm, get, RegisterOptions, FieldPath } from "react-hook-form"
import { addDays, formatISO, differenceInDays } from "date-fns"
import { Heading3, InputField } from "../../../components/FormCommons"
import { MojeTextInput } from "../../../components/ui/MojeTextInput"
import { FormErrors } from "../../../components/FormErrors"

export type TripValues = Pick<
  Trip,
  | "name"
  | "start_date"
  | "end_date"
  | "available_spots"
  | "full_price"
  | "full_price_conditions"
  | "advance"
  | "advance_conditions"
  | "start_hour"
  | "end_hour"
  | "min_age"
  | "max_age"
  | "min_count"
  | "max_count"
  | "reservation_min_days"
  | "reservation_price"
  | "discounts_description"
>

export const TripFormSectionParticipants = ({ register, index, watch }) => {
  const participantOptions = Array.from({ length: 50 }, (_, i) => i + 1)
  const minAge = watch("min_age")
  return (
    <>
      {/* <Heading3>Uczestnicy</Heading3> */}
      {/* <div className="flex flex-row mb-2">
        <div className="w-40 mr-2">
          <InputField
            {...register("min_age", { required: true })}
            value={minAge}
            id={`min_age-${index}`}
            label="*Wiek od"
            select
          >
            {ageOptions.map((age) => {
              return (
                <MenuItem value={age} key={age}>
                  {age} lat
                </MenuItem>
              )
            })}
          </InputField>
        </div> */}
      {/* <div className="w-40 mr-2">
          <InputField
            {...register("max_age", { required: true })}
            value={watch("max_age")}
            id={`max_age-${index}`}
            label="*Wiek od"
            select
          >
            {ageOptions.map((age) => {
              return (
                <MenuItem value={age} key={age}>
                  {age} lat
                </MenuItem>
              )
            })}
          </InputField>
        </div>
      </div> */}
      {/* <div className="flex flex-row mb-2">
       
        <div className="w-40 mr-2">
          <InputField
            {...register("min_count", { required: true })}
            value={watch("min_count")}
            id={`min_count-${index}`}
            label="*Ilość minimum"
            select
          >
            {participantOptions.map((participant, index) => {
              return (
                <MenuItem value={participant} key={index}>
                  {participant}
                </MenuItem>
              )
            })}
          </InputField>
        </div>
        <div className="w-40 mr-2">
          <InputField
            {...register("max_count", { required: true })}
            value={watch("max_count")}
            id={`max_count-${index}`}
            label="*Ilość maksimum"
            select
          >
            {participantOptions.map((participant, index) => {
              return (
                <MenuItem value={participant} key={index}>
                  {participant}
                </MenuItem>
              )
            })}
          </InputField>
        </div>
      </div> */}
    </>
  )
}
export const tripFormSchema = yup.object().shape({
  name: yup.string().required("Nazwa turnusu jest wymagana"),
  start_date: yup.date().required("Data rozpoczęcia jest wymagana"),
  end_date: yup.date().required("Data zakończenia jest wymagana"),
  start_hour: yup.number().required("Godz od jest wymagana"),
  end_hour: yup.number().required("Godz od jest wymagana"),
  min_age: yup.number().required("Minimalny wiek dziecka jest wymagany"),
  max_age: yup.number().required("Maksymalny wiek dziecka jest wymagany"),
  min_count: yup.number().required("Minimalna ilość dzieci jest wymagana"),
  max_count: yup.number().required("Maksymalna ilość dzieci jest wymagana"),
  reservation_min_days: yup
    .number()
    .required("Minimalna ilość dni jest wymagana"),
  reservation_price: yup.number().required("Cena jest wymagana"),
  available_spots: yup
    .number()
    .typeError("Ilość miejsc jest wymagana")
    .required("Ilość miejsc jest wymagana")
    .min(1, "Ilość miejsc nie może być mniejsza niż 1"),
  full_price: yup
    .number()
    .required("Pełna kwota jest wymagana")
    .typeError("Pełna kwota jest wymagana"),
  advance: yup.number()
})

export function TripFormDayCamp({
  values,
  index,
  onSubmit
}: {
  values: TripValues
  index: number
  onSubmit: (values: any, valid: boolean) => void
}) {
  const formMethods = useForm<TripValues>({
    mode: "onBlur",
    resolver: yupResolver(tripFormSchema),
    defaultValues: values ? { ...values } : {}
  })
  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = formMethods

  const register = (
    name: FieldPath<TripValues>,
    options?: RegisterOptions
  ) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false))
  })

  function onFormSubmit(data: Trip) {
    var isValid = formMethods.trigger()
    if (isValid) {
      onSubmit(data, true)
    }
  }

  const startDate = watch("start_date")
  const endDate = watch("end_date")
  const registerStartDate = register("start_date")

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      onBlur={async (e) => {
        const values = getValues()
        // Check fields for empty
        const noEmpty = Object.keys(values).every(
          (key) => values[key as keyof TripValues] !== ""
        )
        const noErrors = Object.keys(errors).length === 0
        onSubmit(values, noErrors && noEmpty)
      }}
    >
      <Heading3>Termin</Heading3>
      <div className="flex justify-items-stretch gap-4">
        <InputField
          {...registerStartDate}
          type="date"
          InputLabelProps={{ shrink: true }}
          label="*Termin od"
          id={`start_date-${index}`}
          onChange={(e: any) => {
            if (endDate) {
              const newStart = new Date(e.target.value)
              const currentStart = new Date(startDate)
              const currentEnd = new Date(endDate)
              const diff = differenceInDays(newStart, currentStart)
              setValue(
                "end_date",
                formatISO(addDays(currentEnd, diff), {
                  representation: "date"
                })
              )
            }
            registerStartDate.onChange(e)
          }}
        />
        <InputField
          {...register("end_date")}
          InputLabelProps={{ shrink: true }}
          disabled={!startDate}
          type="date"
          id={`end_date-${index}`}
          label="*Termin do"
          inputProps={{
            min: formatISO(
              addDays(startDate ? new Date(startDate) : new Date(), 1),
              {
                representation: "date"
              }
            )
          }}
          error={new Date(startDate) >= new Date(endDate)}
        />
      </div>

      <TripFormSectionParticipants
        register={register}
        index={index}
        watch={watch}
      />
      <Heading3>Opłaty</Heading3>
      <MojeTextInput
        {...register("reservation_price", { required: true })}
        type="number"
        id={`reservation_price-${index}`}
        label="*Cena za rezerwację"
        inputProps={{ min: 1 }}
        error={errors.reservation_price?.message}
        fullWidth
      />
      <FormErrors errors={errors}></FormErrors>
    </form>
  )
}
