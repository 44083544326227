import { useEffect, useState } from "react"
import { DescriptionList, InputField } from "../../../../components/FormCommons"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useCompanyApi } from "../../events/api/useCompanyApi"
import {useTranslation} from "react-i18next";

export const CompanySectionFinanceForm = ({ register, control }: any) => {
  const { t } = useTranslation()

  return (
    <>
      <InputField
        label={t("payment_form.iban_number", "Numer konta")}
        {...register("bank_account", { required: false })}
        required={false}
      />
      <InputField
        label={t("payment_form.bank_name", "Nazwa banku")}
        {...register("bank_name", { required: false })}
        required={false}
      />
      {/* <InputCheck
        control={control}
        label="Weryfikacja z białą listą"
        name="bank_verified"
      /> */}
    </>
  )
}

export const CompanySectionFinancePreview = ({ values }: any) => {
  const { t } = useTranslation()

  return (
    <div>
      <DescriptionList
        data={{
          [t("payment_form.iban_number", "Numer konta")]: values.bank_account,
          [t("payment_form.bank_name", "Nazwa banku")]: values.bank_name,
          // "Weryfikacja z białą listą": values.bank_verified ? "Tak" : "Nie",
        }}
      />
    </div>
  )
}

export const CompanySectionFinance = ({
  values,
  refetch,
  onSave,
}: {
  onSave?: Function
  values: any
  refetch: Function
}) => {
  const { t } = useTranslation()
  const { updateCompany } = useCompanyApi()
  const [val, setValue] = useState(values)

  const saveHandler = async (data: any) => {
    var updateData: any = {
      id: values.id,
      bank_account: data.bank_account,
      bank_name: data.bank_name,
      bank_verified: data.bank_verified,
    }
    if (onSave) {
      return onSave(updateData)
    }
    return updateCompany(updateData)
  }
  const handleRefresh = () => {
    refetch()
  }
  useEffect(() => {
    if (val.id !== values.id) {
      setValue(values)
    }
  }, [values])

  const update = (data: object) => {
    setValue(data)
  }
  return (
    <EditableFormSection
      title={t("Register.Finances", "Dane finansowe")}
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      onUpdate={update}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <CompanySectionFinanceForm values={val} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <CompanySectionFinancePreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
