import {
  Button,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup
} from "@mui/material"
import { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { InputField, SectionTitle } from "../../../../components/FormCommons"
import PlaceOrAccommodationModal from "../../../../pages/organizer/products/forms/ProductSectionPlace/PlaceOrAccommodationModal"

import { SectionContainer } from "./CreateOfferForm"
import {useTranslation} from "react-i18next";

function FormSectionAccommodation() {
  const { t } = useTranslation()

  const { register, watch, setValue, control, unregister } = useFormContext()

  const accommodation = watch("accommodation")
  const accommodation_price_include = watch("accommodation_price_include")

  const [openAccomodationModal, setOpenAccomodationModal] = useState(false)
  const handleOpenAccomodationModal = () => setOpenAccomodationModal(true)
  const handleCloseAccomodationModal = () => setOpenAccomodationModal(false)

  useEffect(() => {
    if (accommodation_price_include === "included") {
      unregister("accommodation_price_per_person")
    }
  }, [accommodation_price_include])

  return (
    <SectionContainer>
      <SectionTitle>{t("Booking.accommodation", "Nocleg")}</SectionTitle>

      {accommodation && (
        <>
          <div className="flex flex-row items-center">
            <div className="my-4 flex-1 text-lg">{accommodation.name}</div>
            <div>
              <div
                className="text-red-500 font-bold cursor-pointer hover:underline"
                onClick={() => {
                  setValue("accommodation", undefined)
                  setValue("accommodation_place_id", null)
                  setValue("accommodation_price_include", null)
                  setValue("accommodation_price_per_person", null)
                }}
              >
                Usuń
              </div>
            </div>
          </div>

          <div>Cena*</div>
          <Controller
            name="accommodation_price_include"
            control={control}
            render={({
              field,
              fieldState: { isTouched, isDirty, error },
              formState
            }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  label="w cenie wycieczki"
                  value="included"
                  control={<Radio />}
                />
                <div className="flex  gap-3">
                  <div>
                    <FormControlLabel
                      label="dodatkowo płatny"
                      value="additional"
                      control={<Radio />}
                    />
                  </div>

                  {accommodation_price_include === "additional" && (
                    <div className="w-60">
                      <InputField
                        type="number"
                        label="Cena/ 1 osobę* "
                        {...register("accommodation_price_per_person", {
                          valueAsNumber: true
                        })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">zł</InputAdornment>
                          )
                        }}
                      />
                    </div>
                  )}
                </div>
              </RadioGroup>
            )}
          />
        </>
      )}

      {!accommodation && (
        <div className="flex justify-center">
          <Button
            variant="outlined"
            size="large"
            onClick={handleOpenAccomodationModal}
          >
              {t("ui.attractions.add_accommodation", "Dodaj nocleg")}
          </Button>
        </div>
      )}

      <PlaceOrAccommodationModal
        type="accommodation"
        open={openAccomodationModal}
        handleClose={handleCloseAccomodationModal}
        disabledItems={[]}
        onItemsSelected={(items) => {

          if (items && items[0].type === "accommodation") {
            setValue("accommodation", items[0])
            setValue("accommodation_place_id", items[0].id)
          }

          handleCloseAccomodationModal()
        }}
        handleDeleteItem={() => {}}
      />
    </SectionContainer>
  )
}

export default FormSectionAccommodation
