import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Heading2} from "../../../../components/FormCommons";

const RegulaminIndex = () => {
  const { i18n, t } = useTranslation();
  const language = i18n.language;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  const pdfFile = language === "en" ? "/regulations.pdf" : "/regulamin.pdf";

  return (
      <section className="px-4">
        <Heading2 sx={{ marginTop: isMobile ? "12px" : "24px", marginBottom: isMobile ? "12px" : "24px" }}>{t("Regulations.regulations", "Regulamin")}</Heading2>

        <div style={{ width: "100%", height: isMobile ? "100%" : "100vh" }}>
          {isMobile ? (
              <p>
                {t("Regulations.mobile_pdf_warning", "Przeglądanie PDF na urządzeniach mobilnych może być ograniczone.")}{" "}
                <a href={pdfFile} target="_blank" rel="noopener noreferrer">
              <span className="text-blue-500 underline">
                {t('Regulations.click_to_open_pdf', "Kliknij tutaj, aby otworzyć PDF")}
              </span>
                </a>
              </p>
          ) : (
              <iframe
                  src={pdfFile}
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
              />
          )}
        </div>
      </section>
  );
};

export default RegulaminIndex;
