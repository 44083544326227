import { SelectableDiv } from "../../components/ui/SelectableDiv"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { InputField } from "../../components/FormCommons"
import { Box } from "@mui/system"

const SelectAddressDialog = ({ onClose, open, order, onSave }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      delivery_address: order.delivery_address,
      delivery_postcode: order.delivery_postcode,
      delivery_city: order.delivery_city,
    },
  })
  const onFormSubmit = async (data: any) => {
    onSave(data)
  }

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle id="simple-dialog-title">
        Ustaw adres wysyłki plakatów
      </DialogTitle>
      <DialogContent style={{ width: "100%", minWidth: "500px" }}>
        <div className="w-full">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div>
              <InputField
                label="Adres dostawy"
                {...register("delivery_address")}
              />
            </div>
            <div>
              <InputField
                label="Kod pocztowy"
                {...register("delivery_postcode")}
              />
            </div>
            <div>
              <InputField label="Miasto" {...register("delivery_city")} />
            </div>
            <div className="flex flex-row">
              <Button
                variant="outlined"
                onClick={() => {
                  onClose(false)
                }}
              >
                Anuluj
              </Button>
              <Box flex={1} />
              <Button color="primary" variant="contained" type="submit">
                Zapisz
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export const PosterSectionDelivery = ({
  order,
  onUpdate,
}: {
  order
  onUpdate
}) => {
  const [selectAddres, setSelectAddress] = useState(false)

  const handleSelectPaczkomat = () => {}
  return (
    <div className="mb-8">
      <div className="mb-4 flex flex-row text-lg">
        <div>Wybierz dostawę na adres:</div>
        <div
          className="text-blue-500 font-bold ml-1 cursor-pointer 
            hover:underline
        "
          onClick={() => setSelectAddress(true)}
        >
          {`${order.delivery_address} ${order.delivery_postcode} ${order.delivery_city}`}
        </div>
      </div>

      <div className="flex flex-row">
        <SelectableDiv
          className="w-64 h-64 mr-8"
          onClick={() => {
            onUpdate({ delivery_type: "PACZKOMAT", deliery_price: 0 })
          }}
          selected={order.delivery_type === "PACZKOMAT"}
        >
          <div>
            <div className="text-lg mb-4 font-light">Paczkomat inPost</div>
            <div className="flex flex-row">
              <div className="mr-2 font-bold text-xl my-w">0 zł</div>
              <div>
                <img
                  src="/assets/inpost-logo.png"
                  alt="Logo"
                  className="w-32"
                />
              </div>
            </div>
            <div
              className="text-blue-500 text-center underline cursor-pointer my-4 font-bold"
              onClick={handleSelectPaczkomat}
            >
              Wybierz paczkomat
            </div>
          </div>
        </SelectableDiv>

        <SelectableDiv
          className="w-64 h-64"
          onClick={() => {
            onUpdate({ delivery_type: "POST" })
          }}
          selected={order.delivery_type === "POST"}
        >
          <div className="text-lg mb-4 font-light">Przesyłka pocztowa</div>
          <div className="flex flex-row">
            <div className="mr-2 font-bold text-xl my-w">0 zł</div>
            <div>
              <img src="/assets/poczta-logo.png" alt="Logo" className="w-32" />
            </div>
          </div>
        </SelectableDiv>
      </div>
      <SelectAddressDialog
        open={selectAddres}
        onClose={() => setSelectAddress(false)}
        order={order}
        onSave={(newAddr) => {
          onUpdate({
            ...newAddr,
          })
          setSelectAddress(false)
        }}
      />
    </div>
  )
}
