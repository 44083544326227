// Represents participant registered for certain event - carries information about this 'reservation' - payment status, confirmation status, kid, parent contact etc
export type Participant = {}

export type Photo = {
  // TODO - we will have more info here to use urls for different resolutions
  url: string
}

// child that will be participant of events
export type Kid = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  birth_date: string
  pesel: string // pesel is a local ID number
  parent_user_id: string // user (parent) that created this kid
}

export enum BookingStatus {
  NEW = "NEW", //
  RESERVED = "RESERVED", // RESERVATION MADE
  ADVANCE_PAID = "ADVANCE_PAID", // ADVANCE PAYMENT MADE
  FULLY_PAID = "FULLY_PAID", // TOTAL PAYMENT MADE
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  RESIGNED = "RESIGNED",
  REJECTED = "REJECTED",
  ACTIVE = "ACTIVE",
  DRAFT = "DRAFT",

  // Not real status - only used internally for colors
  REQUEST = "REQUEST"
}

// system user (parent)
export type User = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  phone: string
  email: string
  address: Location
}

export type ReservationParticipant = {
  kid: Kid
}

export enum ReservationStatus {
  NEW = "NEW",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED"
}
export enum PaymentStatus {
  NOT_PAID,
  PARTIALLY_PAID,
  FULLY_PAID
}

// Represents reservation - from single user to a
export type Reservation = {
  id: string
  status: ReservationStatus
  paymentStatus: PaymentStatus
  // Participants for this reservation - one reservation can have two kids
  user: User // user (parent) who made it
  participants: Array<ReservationParticipant>
  product: Product // camp definition
  trip?: Trip // Specific term for this
  days?: Array<any> // for 'Open' reservations that will be list of days
}
// Term represent a specific 'edition' of the Camp
export type Trip = {
  // Data that are publicly visible (also used on user page)
  id: string
  name: string
  start_date: string
  end_date: string

  start_hour: string
  end_hour: string

  available_spots: number // How many places are available

  // Data only used by admin panel
  reservations: Array<Reservation>

  // pricing - store all prices as cents
  full_price: number
  payment_due_date: string
  advance_price: number

  //
  min_age?: number
  max_age?: number
  min_count?: number
  max_count?: number
  reservation_min_days?: number
  reservation_price?: number
  discounts_description?: string
  product_id: string
  currency: string
}

// Represents product Category
export type ProductCategory = {
  id: string
  name: string
}
export type AttributeType = {
  id: string
  name: string
}
export type Attribute = {
  id: string // needs unique id to remove
  type: string // used to group attributes
  value: string
}

export type ProductAttribute = {
  type: string
  value: string
}
// Where
export type Location = {
  //   id: string
  city: string
  province: string
  post_code: string
  street: string
  house_number: string
  room_number: string
}

// Product represents the definition of a Camp
export type Product = {
  id: string // Id - we use UUIs so that's a string
  name: string // Main name
  description: string // long text description
  type: string
  slug?: string

  //
  additional_info_text: string
  conditions_text_short: string
  conditions_text_long: string
  category: ProductCategory

  min_age: number
  max_age: number
  // Where event takes place
  location: Location

  // terms - specific dates of event
  trips: Array<Trip>
  //
  attributes: Array<ProductAttribute>

  //
  photos: Array<Photo>
  company?: { name: string; operation_types: Array<string> }
  company_id?: string
  pickup_route?: any
  country_code?: string
  country_name?: string
  transport_type?: string
  pickup_route_address?: string
  pickup_route_price?: number
  //
  status: string
}
export interface SendInquiryRequest {
  user_id: string | null
  company_id: string
  product_id: string
  start_date: string
  end_date: string
  content: string
  participants_count: number | null
  created_at: string | null
  type: string | null
  user_name: string | null
  user_mail: string | null
  user_phone: string | null
  pickup_place: string | null
  pickup_cost?: string
}
