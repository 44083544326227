import {
  Routes,
  Route,
  Link,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { BackButton } from "../../../components/commons/button"
import { useProduct } from "../events/api/useProductApi"
import { Button, LinearProgress } from "@mui/material"
import { PageTitle } from "../../../components/typography"
import { ProductTripsPage } from "./ProductTripsPage"
import { PRODUCT_TYPE_SCHOOL_TRIP } from "wakacje-web/src/utils/ProductUtils"
import { ProductTypeChip } from "../../../components/events/ProductTypeChip"
import {TFunction, useTranslation} from "react-i18next"
import { SchoolTripBookingsPage } from "../../../domains/offer/components/SchoolTripBookingsPage"
import { SchoolTripOfferPage } from "../../../domains/offer/components/SchoolTripOfferPage"
import { ProductSummaryPageJoinedForms } from "./ProductSummaryPageJoinedForms"
import axios from "axios"
import { getLocalizedKey } from "../../../utils/getLocalizedKey"

type TabData = { label: string; path: string; icon?: React.ReactNode }

const campTabs: TabData[] = [
  {
    label: "Edytuj ofertę",
    path: "details"
  },
  { label: "Lista terminów", path: "turnusy" }
]

const schoolTripTabs = (t: TFunction): TabData[] => [
  {
    label: t("ui.products.edit_offer", "Edytuj ofertę"),
    path: "details",
    icon: <img src="/svg/edit-icon.svg" alt="edit" width="21" height="21" />
  }
  // {
  //   label: t("ui.products.bookings", "Rezerwacje"),
  //   path: "bookings",
  //   icon: <img src="/svg/edit-icon.svg" alt="edit" width="21" height="21" />
  // }
]

function ProductPageTabs({ activeIndex, children, tabs }: any) {
  const { t } = useTranslation()

  return (
    <Box>
      <Tabs
        value={activeIndex}
        aria-label="trip page tab"
        sx={{
          borderBottom: 1,
          borderColor: "#E6EEFD",
          marginBottom: 4
        }}
      >
        {tabs.map((tab: TabData) => (
          <Tab
            component={Link}
            to={`../${tab.path}`}
            label={
              <div className="flex justify-center items-center gap-2">
                {tab.icon && <div>{tab.icon}</div>}
                <p>{tab.path === "details" ? t("ui.school_trips.tab_path_details", tab.label) : t("ui.school_trips.tab_path_turnusy", tab.label)}</p>
              </div>
            }
            key={tab.path}
          />
        ))}
      </Tabs>
      {children}
    </Box>
  )
}

const ProductPageInner = ({
  product,
  refetch
}: {
  product: any
  refetch: Function
}) => {
  const navigate = useNavigate()

  const refetchTrips = () => {
    refetch()
  }
  const refetchBookings = () => {
    refetch()
  }

  const { t } = useTranslation()
  const tabs = schoolTripTabs(t)
  const nameKey = getLocalizedKey("name")

  return (
    <Box p={4}>
      {/* Page header */}
      <Box>
        <BackButton destination={product.type} />
        {product && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <Box flex={1} display="flex" flexDirection="row" alignItems="top">
              <Box>
                <PageTitle gutterBottom>
                  {product[nameKey] ? product[nameKey] : product?.name}
                </PageTitle>
                <Box>{product.product?.name}</Box>
              </Box>
              <Box ml={2}>
                <div className="whitespace-nowrap">
                  <ProductTypeChip type={product.type} />
                </div>
              </Box>
            </Box>
            <Box mx={1}>
              {product.company?.fc_website_url && (
                <>
                  <a
                    href={`https://www.fajnyczas.pl/${product.company?.fc_website_prefix}/${product.id}`}
                    title="Otwórz na stronie FajnyCzas.pl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      className="mx-4"
                      variant="outlined"
                      color="primary"
                      size="large"
                      // onClick={() => navigate("turnusy/add")}
                    >
                      {t("preview", "Podgląd")}
                    </Button>
                    {/* <WebIcon /> */}
                  </a>
                  <Button
                    className="mx-4"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginLeft: "1vw" }}
                    onClick={async () => {
                      try {
                        await axios.post(
                          process.env.REACT_APP_WAKACJE_API_URL +
                            "/products-admin/v1.1/products/duplicate",
                          {
                            product_id: product.id
                          }
                        )
                        window.alert("Produkt został domyślnie zduplikowany")
                      } catch (e) {
                        window.alert("Nie można zduplikować produktu")
                      }
                    }}
                  >
                    {t("duplicate", "Duplikuj")}
                  </Button>
                </>
              )}
            </Box>
            {/* {product.type !== PRODUCT_TYPE_SCHOOL_TRIP.value && (
              <Box display="flex" flexDirection="row">
                <Box mr={1}>
                  <Button
                    className="mx-4"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => navigate("turnusy/dodaj")}
                  >
                    Dodaj Termin
                  </Button>
                </Box>
              </Box>
            )} */}
          </Box>
        )}
      </Box>

      {/* School trips will have different tabs so easiest way is to have different structure */}
      {product.type === PRODUCT_TYPE_SCHOOL_TRIP.value ? (
        <Routes>
          <Route
            path="details"
            // element={
            //   <ProductPageTabs activeIndex={0} tabs={tabs}>
            //     <ProductSummaryPage product={product} refetch={refetch} />
            //   </ProductPageTabs>
            // }
            element={
              <ProductPageTabs activeIndex={0} tabs={tabs}>
                <ProductSummaryPageJoinedForms
                  product={product}
                  refetch={refetch}
                />
              </ProductPageTabs>
            }
          />
          <Route
            path="bookings/:id"
            element={
              <ProductPageTabs activeIndex={1} tabs={tabs}>
                <SchoolTripOfferPage
                  product={product}
                  // onSaved={() => {
                  //   refetch()
                  //   navigate("bookings")
                  // }}
                  // refetch={refetchBookings}
                />
              </ProductPageTabs>
            }
          />
          <Route
            path="bookings"
            element={
              <ProductPageTabs activeIndex={1} tabs={tabs}>
                <SchoolTripBookingsPage
                  product={product}
                  onSaved={() => {
                    refetch()
                    navigate("bookings")
                  }}
                  refetch={refetchBookings}
                />
              </ProductPageTabs>
            }
          />
          <Route index element={<Navigate to="details" />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="details"
            // element={
            //   <ProductPageTabs activeIndex={0} tabs={campTabs}>
            //     <ProductSummaryPage product={product} refetch={refetch} />
            //   </ProductPageTabs>
            // }
            element={
              <ProductPageTabs activeIndex={0} tabs={campTabs}>
                <ProductSummaryPageJoinedForms
                  product={product}
                  refetch={refetch}
                />
              </ProductPageTabs>
            }
          />
          <Route
            path="turnusy/*"
            element={
              <ProductPageTabs activeIndex={1} tabs={campTabs}>
                <ProductTripsPage
                  product={product}
                  onSaved={() => {
                    refetch()
                    navigate("turnusy")
                  }}
                  refetch={refetchTrips}
                />
              </ProductPageTabs>
            }
          />

          <Route index element={<Navigate to="details" />} />
        </Routes>
      )}
    </Box>
  )
}
export const ProductPage = (props: any) => {
  const { productId } = useParams()
  const { data, loading, refetch } = useProduct(productId ?? "")
  return (
    <div>
      {loading && <LinearProgress />}
      {data && <ProductPageInner product={data} refetch={refetch} />}
    </div>
  )
}
