import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { createContext, useContext, useRef, useState } from 'react'

type OpenPopupLoginOptions = { redirectUrl: string }

export const LoginPopupContext = createContext({
  openLogin: false,
  openSignIn: false,
  openRegister: false,
  redirectUrl: undefined,
  openCurrentUser: false,
  openMainNav: false,
  openPopupLogin: (options?: OpenPopupLoginOptions) => {},
  closePopupLogin: () => {},
  openPopupSignIn: (options?: OpenPopupLoginOptions) => {},
  closePopupSignIn: () => {},
  openPopupRegister: () => {},
  closePopupRegister: () => {},
  closePopupCurrentUser: () => {},
  openPopupCurrentUser: () => {},
  redirectLogIn: () => {},
  redirectRegister: () => {},
  openPopupMainNav: () => {},
  closePopupMainNav: () => {}
})

export const LoginPopupContextProvider = ({ children }: { children: any }) => {
  const router = useRouter()

  const [openLogin, setOpenLogin] = useState(false)
  const latestOpen = useRef(false)
  const [openRegister, setOpenRegister] = useState(false)
  const [openSignIn, setOpenSignIn] = useState(false)
  const [openCurrentUser, setOpenCurrentUser] = useState(false)
  const [openMainNav, setOpenMainNav] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined)

  const openPopupLogin = (options?: OpenPopupLoginOptions) => {
    const { redirectUrl } = options ?? {}
    document.body.style.overflow = 'hidden'
    setOpenLogin(true)
    setRedirectUrl(redirectUrl)
  }

  const closePopupLogin = () => {
    document.body.style.overflow = 'auto'
    setOpenLogin(false)
  }

  const openPopupSignIn = (options?: OpenPopupLoginOptions) => {
    latestOpen.current = openLogin

    const { redirectUrl } = options ?? {}

    setRedirectUrl(redirectUrl)
    setOpenSignIn(true)
    setOpenLogin(false)
  }

  const closePopupSignIn = () => {
    setOpenSignIn(false)
    // Remove this line to fix issue => after clicking outside the pop-up box, it should disappear and not return to the previous view
    // setOpenLogin(latestOpen.current)
  }

  const openPopupRegister = () => {
    latestOpen.current = openLogin
    setOpenRegister(true)
    setOpenLogin(false)
  }

  const closePopupRegister = () => {
    setOpenRegister(false)
    // setOpenLogin(latestOpen.current)
  }

  const redirectLogIn = () => {
    setOpenRegister(false)
    setOpenSignIn(true)
    setOpenLogin(false)
  }

  const redirectRegister = () => {
    setOpenRegister(true)
    setOpenSignIn(false)
    setOpenLogin(false)
  }

  const closePopupCurrentUser = () => {
    setOpenCurrentUser(false)
  }

  const openPopupCurrentUser = () => {
    setOpenCurrentUser(true)
  }

  const closePopupMainNav = () => {
    setOpenMainNav(false)
  }

  const openPopupMainNav = () => {
    setOpenMainNav(true)
  }

  useEffect(() => {
    setOpenLogin(false)
  }, [router.asPath])

  return (
    <LoginPopupContext.Provider
      value={{
        openLogin,
        openSignIn,
        openRegister,
        openCurrentUser,
        openMainNav,
        redirectUrl,
        openPopupLogin,
        closePopupLogin,
        openPopupSignIn,
        closePopupSignIn,
        openPopupRegister,
        closePopupRegister,
        redirectLogIn,
        redirectRegister,
        closePopupCurrentUser,
        openPopupCurrentUser,
        openPopupMainNav,
        closePopupMainNav
      }}
    >
      {children}
    </LoginPopupContext.Provider>
  )
}

export const useLoginPopupContext = () => useContext(LoginPopupContext)
