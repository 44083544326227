import { Box } from "@mui/system"

import { Routes, Route } from "react-router-dom"
import Toolbar from "../Toolbar/Toolbar"
import { useSearchText } from "../useSearchText"
import { SuperAdminPlacesTable } from "../places/SuperAdminPlacesTable"

export const SuperAdminAttractions = () => {
  const listAttractions: any = []
  const { setSearchTextDebounced, queryParams } = useSearchText()

  return (
    <div className="mt-10">
      <Box width="100%">
        <Toolbar
          setSearchTextDebounced={setSearchTextDebounced}
          api={listAttractions}
        />
        <Routes>
          <Route
            index
            element={
              <SuperAdminPlacesTable queryParams={queryParams} type="place" />
            }
          />
        </Routes>
      </Box>
    </div>
  )
}
