import {
  FormHelperText,
  Heading3,
  InputField
} from '../../../../components/FormCommons'
import { EditableFormSection } from '../../../../components/forms/EditableFormSection'
import { useProductApi } from '../../events/api/useProductApi'
import {
  ProductTypes,
  PRODUCT_TYPE_SCHOOL_TRIP
} from 'wakacje-web/src/utils/ProductUtils'
import { ProductGeneralInfoPreview } from './ProductGeneralInfoPreview'
import * as Yup from 'yup'
import { useCategories } from '../../../../components/useCategories'
import {
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  ListSubheader
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { ProductSectionAgeForm } from './ProductSectionAge'
import { ProductFormAvailability } from './ProductFormAvailability'
import { Tag } from '../../../../components/Tag/Tag'
import { ProductSectionPhotosForm } from './ProductSectionPhotos'
import { FieldError } from '../../../../components/FieldError'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { getLocalizedKey } from '../../../../utils/getLocalizedKey'
import ParticipantLanguages from '../../../../components/ParticipantsLanguages/ParticipantsLanguages'

export const ProductDropdownCategories = ({ register, setValue }) => {
  const { t: t1 } = useTranslation('common')
  const { categoriesByCode, categories } = useCategories(t1)

  const { watch } = useFormContext();
  const { t } = useTranslation();

  const rootCategories: any = categories
    ? Object.values(
        categories
          .filter(c => c.id.indexOf('lokalizacja') === -1)
          .reduce((a, i) => {
            const code = i.id.split('.')[0]
            return { ...a, [i.category]: { code, name: i.category } }
          }, {})
      )
    : [];

  const regCategories = register('categories', { required: false })
  const selectedCategories = watch('categories')

  const options = [];
  for (let i = 0; i < rootCategories.length; i++) {
    var c = rootCategories[i];
    const subCategories: any = categories.filter(
      (s: any) => s.id?.indexOf(c.code) === 0
    );
    options.push(<ListSubheader key={c.name}>{c.name}</ListSubheader>);
    for (var j = 0; j < subCategories.length; j++) {
      var s = subCategories[j];
      options.push(
        <MenuItem value={s.id} key={s.id}>
          <Checkbox checked={selectedCategories?.indexOf(s.id) > -1} />
          <ListItemText primary={s.subcategory} />
        </MenuItem>
      );
    }
  }

  const handleRemoveCategory = selectedCategory => {
    const newCategoriesArr = selectedCategories.filter(
      c => c !== selectedCategory
    )
    setValue('categories', newCategoriesArr)
  }

  return (
    <>
      {selectedCategories && selectedCategories.length > 0 && (
        <div className='flex gap-3 mb-6 flex-wrap'>
          {selectedCategories.map((selectedCategory, index) => {
            if (!categoriesByCode[selectedCategory]) {
              return <span />;
            }
            return (
              <Tag
                key={index}
                label={categoriesByCode[selectedCategory]}
                onClick={() => handleRemoveCategory(selectedCategory)}
              />
            );
          })}
        </div>
      )}

      <FormControl sx={{ minWidth: 120 }} fullWidth>
        <InputLabel id='selectCategoryLabel' htmlFor='grouped-native-select'>
          {t('product.categories', 'Kategorie')}*
        </InputLabel>
        <Select
          fullWidth
          labelId='selectCategoryLabel'
          multiple
          label='Kategorie'
          {...regCategories}
          value={
            selectedCategories && Array.isArray(selectedCategories)
              ? selectedCategories
              : []
          }
          renderValue={() => {
            return t('product.categories', 'Kategorie') + '*'
          }}
        >
          {options}
        </Select>
      </FormControl>
    </>
  );
};

export const productGeneralInfoSchema = {
  [getLocalizedKey('name')]: Yup.string().required(
    'Nazwa oferty jest wymagana'
  ),
  [getLocalizedKey('description')]: Yup.string().required(
    'Opis oferty jest wymagany'
  ),
  categories: Yup.array().nullable().required('Kategorie są wymagane'),
  min_age: Yup.number().required('Minimalny wiek jest wymagany'),
  max_age: Yup.number().required('Maksymalny wiek jest wymagany')
}

export const productGeneralInfoSchoolTripSchema = {
  [getLocalizedKey('name')]: Yup.string().required(
    'Nazwa oferty jest wymagana'
  ),
  [getLocalizedKey('description')]: Yup.string().required(
    'Opis oferty jest wymagany'
  ),
  trip_type: Yup.string().required('Rodzaj wycieczki jest wymagany'),
  duration_days: Yup.mixed().when('trip_type', {
    is: 'wielodniowa',
    then: Yup.number().required('Liczba dni jest wymagany')
  }),
  categories: Yup.array().nullable().required('Kategorie są wymagane'),
  min_age: Yup.number().required('Minimalny wiek jest wymagany'),
  max_age: Yup.number().required('Maksymalny wiek jest wymagany'),
  availability: Yup.array().required('Dostępność jest wymagany')
}

export const ProductGeneralInfoForm = ({ values, errors }: any) => {
  const { watch, setValue, register, control } = useFormContext()
  const { type } = values
  const { t } = useTranslation()
  const nameKey = getLocalizedKey('name')
  const descriptionKey = getLocalizedKey('description')

  const duration_days_options = [];
  for (let i = 2; i <= 20; i++) {
    duration_days_options.push(i);
  }
  const trip_type = watch('trip_type')

  useEffect(() => {
    if (trip_type === 'jednodniowa') {
      setValue('duration_days', null)
    }
  }, [trip_type]);

  return (
    <>
      <InputField
        value={watch(nameKey)}
        label={
          type === PRODUCT_TYPE_SCHOOL_TRIP.value
            ? '*' + t('product.trip_name', 'Nazwa wycieczki')
            : '*' + t('product.product_name', 'Nazwa oferty')
        }
        {...register(nameKey)}
      />

      <FieldError label={errors?.[nameKey]?.message} />
      <InputField
        multiline
        minRows={4}
        value={watch(descriptionKey)}
        label={
          type === PRODUCT_TYPE_SCHOOL_TRIP.value
            ? `*${t('product.trip_description', 'Opis wycieczki')}`
            : `*${t('product.product_description', 'Opis oferty')}`
        }
        {...register(descriptionKey, {
          required: true,
        })}
      />
      <FieldError label={errors?.[descriptionKey]?.message} />
      <div className='mb-8' />

      {type === PRODUCT_TYPE_SCHOOL_TRIP.value && (
        <>
          {' '}
          <Heading3 id='h3-1'>
            {t('product.trip_type', 'Rodzaj wycieczki')}*
          </Heading3>
          <FieldError label={errors?.trip_type?.message} />
          <Controller
            name='trip_type'
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  label={t("ui.school_trips.one_day_trip", "Wycieczka jednodniowa")}
                  value='jednodniowa'
                  control={<Radio />}
                />
                <div className='flex gap-4 items-center'>
                  <FormControlLabel
                    label={t("ui.school_trips.multi_day_trip_with_accommodation", "Wycieczka wielodniowa z noclegiem (zielona szkoła)")}
                    value='wielodniowa'
                    control={<Radio />}
                  />

                  {trip_type === 'wielodniowa' && (
                    <FormControl className='w-[150px] '>
                      <InputLabel id='duration_days'>Liczba dni*</InputLabel>
                      <Select
                        defaultValue={values.duration_days}
                        autoWidth
                        labelId='duration_days'
                        id='duration_days'
                        label={`${t("product.days_count", "Liczba dni")}*`}
                        {...register('duration_days')}
                      >
                        {duration_days_options.map(d => (
                          <MenuItem key={d} value={d}>
                            {d}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </RadioGroup>
            )}
          />
        </>
      )}

      <div className='mb-8' />

      <Heading3 id='h3-1' className='mb-4'>
        {t('product.trip_categories', 'Tematyka zajęć')}
      </Heading3>

      <div className='mb-4'>
        <FormHelperText>{t("product.select_more_categories", "Wybierz więcej niż jedną kategorię")}</FormHelperText>
        <FieldError label={errors?.categories?.message} />
      </div>

      <div className='mb-4'>
        <ProductDropdownCategories register={register} setValue={setValue} />
      </div>

      <div className='mb-8' />

      <ParticipantLanguages
        register={register}
        values={values}
        setValue={setValue}
        errors={errors}
      />

      <div className='mb-8' />
      <Heading3 id='h3-1'>
        {t('product.participants_age', 'Wiek uczestników')}
      </Heading3>
      <div className='mb-4'>
        <FormHelperText>{t("product.participants_age_helper", "Wyjazd dedykowany uczestnikom")}</FormHelperText>
      </div>
      <ProductSectionAgeForm register={register} showMaxParticipants={false} />
      <FieldError label={errors?.min_age?.message} />
      <FieldError label={errors?.max_age?.message} />
      <div className='mb-8' />

      {type === PRODUCT_TYPE_SCHOOL_TRIP.value && (
        <>
          <ProductFormAvailability />
          <FieldError label={errors?.availability?.message} />
        </>
      )}

      <div className='mb-8' />
      <ProductSectionPhotosForm photosNumber={4} />
      <div className='mb-8' />
    </>
  );
};

export const ProductGeneralInfo = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()
  const nameKey = getLocalizedKey('name')
  const descriptionKey = getLocalizedKey('description')

  const saveHandler = async (data: any) => {

    var productData: any = {
      id: values.id,
      name: data[nameKey],
      description: data[descriptionKey],
      category_code: data.category_code,
      subcategory_code: data.subcategory_code,
      categories: data.categories,
      media: data.media,
      min_age: data.min_age,
      max_age: data.max_age,
      participants_languages: data.participants_languages,
      own_transport_details: data.own_transport_details
    }

    if (
      data.type === PRODUCT_TYPE_SCHOOL_TRIP.value ||
      data.type === ProductTypes.CAMP
    ) {
      productData.trip_type = data.trip_type;
      productData.duration_days = data.duration_days;
      productData.availability = data.availability;
    }

    return updateProduct(productData);
  };
  const handleRefresh = () => {
    refetch();
  };

  const initialValues = {
    ...values,
    categories: values.categories ?? [],
    participants_languages: values.participants_languages ?? [],
  };
  const { t } = useTranslation();

  var schema =
    values.type === PRODUCT_TYPE_SCHOOL_TRIP.value
      ? productGeneralInfoSchoolTripSchema
      : productGeneralInfoSchema;

  return (
    <EditableFormSection
      title={t('product.description', 'Opis')}
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
      validationSchema={schema}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductGeneralInfoForm
                values={initialValues}
                {...builderProps}
              />
            )}
            {!builderProps.isEditing && (
              <ProductGeneralInfoPreview values={values} jumpTo={() => {}} />
            )}
          </div>
        );
      }}
    </EditableFormSection>
  );
};
