import * as yup from "yup"
import { DescriptionList } from "../../../../components/FormCommons"
import { useFormContext, useFormState } from "react-hook-form"
import { useEffect, useState } from "react"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useCompanyApi } from "../../events/api/useCompanyApi"
import { MojeTextInput } from "../../../../components/ui/MojeTextInput"
import {useTranslation} from "react-i18next";

export const companyAdminContactDetailsSchema = {
  admin_first_name: yup.string().required("Imię administratora jest wymagane"),
  admin_last_name: yup
    .string()
    .required("Nazwisko administratora jest wymagane"),
  admin_title: yup.string(),
  admin_phone: yup.string().required("Numer telefonu jest wymagany"),
  admin_email: yup
    .string()
    .email("Błędny format email")
    .required("Adres email jest wymagany"),
}

export const CompanySectionAdminContactDetailsForm = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()
  const { errors } = useFormState()
  return (
    <>
      <MojeTextInput
        label={`${t("Booking.first_name", "Imię")}*`}
        {...register("admin_first_name")}
        error={errors.admin_first_name?.message}
        fullWidth
      />
      <MojeTextInput
        label={`${t("Booking.last_name", "Nazwisko")}*`}
        {...register("admin_last_name")}
        fullWidth
        error={errors.admin_last_name?.message}
      />
      <MojeTextInput
        label={t("Booking.position", "Stanowisko")}
        {...register("admin_title")}
        fullWidth
        error={errors.admin_title?.message}
      />
      <MojeTextInput
        label={`${t("Booking.phone", "Numer telefonu")}*`}
        {...register("admin_phone")}
        fullWidth
        error={errors.admin_phone?.message}
      />
      <MojeTextInput
        label={`${t("Booking.email", "Adres e-mail")}*`}
        {...register("admin_email")}
        fullWidth
        error={errors.admin_email?.message}
      />
    </>
  )
}

export const CompanySectionAdminContactDetailsPreview = ({ values }: any) => {
  const { t } = useTranslation()

  return (
    <div>
      <DescriptionList
        data={{
          [t("Booking.first_name", "Imię")]: values.admin_first_name,
          [t("Booking.last_name", "Nazwisko")]: values.admin_last_name,
          [t("Booking.position", "Stanowisko")]: values.admin_title,
          [t("Booking.phone", "Numer telefonu")]: values.admin_phone,
          [t("Booking.email", "Adres e-mail")]: values.admin_email,
        }}
      />
    </div>
  )
}

export const CompanySectionAdminContactDetails = ({
  values,
  refetch,
  onSave,
}: {
  onSave?: Function
  values: any
  refetch: Function
}) => {
  const { t } = useTranslation()
  const { updateCompany } = useCompanyApi()
  const [val, setValue] = useState(values)

  const saveHandler = async (data: any) => {
    var updateData: any = {
      id: values.id,
      admin_first_name: data.admin_first_name,
      admin_last_name: data.admin_last_name,
      admin_title: data.admin_title,
      admin_phone: data.admin_phone,
      admin_email: data.admin_email,
    }
    if (onSave) {
      return onSave(updateData)
    }
    return updateCompany(updateData)
  }
  const handleRefresh = () => {
    refetch()
  }
  useEffect(() => {
    if (val.id !== values.id) {
      setValue(values)
    }
  }, [values])

  const update = (data: object) => {
    setValue(data)
  }

  return (
    <EditableFormSection
      title={t("superAdminBookings.admin_contact_info", "Dane kontaktowe - dla administratora")}
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      onUpdate={update}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <CompanySectionAdminContactDetailsForm
                values={val}
                {...builderProps}
              />
            )}
            {!builderProps.isEditing && (
              <CompanySectionAdminContactDetailsPreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
