import axios from "axios"

export class FileUtils {
  static async openS3File(file) {
    console.log(
      "openS3File ",
      file,
      "window.fajnyczas_api_url",
      (window as any).fajnyczas_api_url
    )

    var url = (window as any).fajnyczas_api_url + `/v1/getFileAccessLink`
    const body = {
      key: file.key
    }
    var res = await axios.post(url, body)
    if (res.status === 200 && res.data.signedUrl) {
      window.open(res.data.signedUrl)
      //   return res.data.signedUrl;
    }
    return
    // if (file.bucket && file.key) {
    //   return (
    //     "https://s3.eu-central-1.amazonaws.com/" + file.bucket + "/" + file.key
    //   );
    //   // https://s3.eu-central-1.amazonaws.com/upload-dev.fajnyczas.pl/companies/91e40b4f-e0b3-4aac-8fa4-19656f2c9e3b/products/PR66COKFFJAQ/k9m0mt_image+7.png
    // }
  }
}
