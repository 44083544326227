import { Control, Controller } from 'react-hook-form'
import { InputField } from '../../../../components/FormCommons'
import { useEffect } from 'react'

export const PromoCodesProductId = ({
  control,
  setValue,
  product_id
}: {
  control: Control
  setValue
  product_id: string
}) => {
  useEffect(() => {
    if (product_id && product_id.trim() !== '') {
      setValue('product_id', product_id)
    } else {
      setValue('product_id', '')
    }
  }, [product_id, setValue])

  return (
    <>
      <Controller
        name={'product_id'}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <InputField
              onChange={onChange}
              value={value}
              label={'Id produktu'}
              id={`product_id`}
            />
          )
        }}
      />
    </>
  )
}
