import { useState } from 'react'
import { FCPackage } from '../../pages/organizer/packages/PackageUtils'
import { MojeButton } from '../ui/MojeButton'
import { useTranslation } from 'react-i18next'
import { useCurrency } from '../../hooks/useCurrency'

export interface IPackageItemProps {
  packageName?: string
  price?: number
  currency: string
  offerVisibility?: string
  description?: string
  features?: string[]
  bestChoice?: boolean
  onClick?: () => void
  savings?: number
  pkg: FCPackage
}

export function PackageItem ({
  packageName,
  price,
  currency,
  offerVisibility,
  description,
  features,
  bestChoice,
  onClick,
  savings,
  pkg
}: IPackageItemProps) {
  const [isSaving, setIsSaving] = useState(false)
  const { selectedCurrency } = useCurrency()

  const { t } = useTranslation()

  return (
    <div
      className='
      px-6 
      py-10 
      rounded-lg 
      w-[80%]
      md:w-[260px]
      lg:4/12 
      h-auto 
      relative 
      mx-0 lg:mx-2
      lg:max-w-[260px]
      '
      style={{
        // background: bestChoice ? "#F3F6FD" : "none",
        background: pkg.bgColor,
        border: packageName === 'Pakiet mini' ? '1px solid #F3F6FD' : 'none'
      }}
    >
      {bestChoice && (
        <div className='absolute left-0 top-2 px-2 pr-4 py-1 text-xs font-bold bg-fc-color-orange clip-style text-white'>
          Najlepszy wybór
        </div>
      )}
      <h4 className='font-sans text-center text-2xl font-bold mb-3 mt-3'>
        {packageName}
      </h4>
      <div className='h-6 mb-9 flex justify-center'>
        {savings && (
          <div
            className='font-lato 
            text-white
            p-1 rounded
           text-center 
           text-xs
           inline-block
           '
            style={{
              background: 'var(--fc-color-3)'
            }}
          >
            oszczędzasz {savings} zł{' '}
          </div>
        )}
      </div>
      <h2
        className='font-lato text-center text-fc-accent-3 font-normal 
        text-6xl'
      >
        {price} {selectedCurrency?.symbol ?? currency === 'USD' ? '$' : 'zł'}
      </h2>
      <p className='font-lato text-center text-sm  text-fc-gray-4'>
        {offerVisibility}
      </p>
      <p className='font-sans mt-7 leading-5 text-center text-sm  text-fc-black min-h-[60px]'>
        {description}
      </p>
      <div className='flex justify-center'>
        <MojeButton
          isSaving={isSaving}
          className='px-8 py-2 bg-fc-accent-3 text-white rounded-lg'
          onClick={async (ev: any) => {
            setIsSaving(true)
            await onClick()
            setIsSaving(false)
          }}
          variant='contained'
          color='primary'
        >
          {t('main_header.buy', 'Wykup')}
        </MojeButton>
      </div>
      <ul className='list-disc mt-8 text-xs font-lato text-[#0B0B0B] space-y-3 px-3'>
        {features?.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  )
}
