import { FcTabPanel, FcTabsHeader } from "../../../components/FcTabs/FcTabs";
import { SuperAdminPackageOrders } from "../SuperAdminPackageOrders";
import { useTranslation } from "react-i18next";
import { useTabs } from "../useTabs";
import { SuperAdminCustomerFee } from "./SuperAdminCustomerFee";
import { SuperAdminLicenses } from "./SuperAdminLicenses";

export const SuperAdminPayments = () => {
  const { selectedTab, handleTabChange } = useTabs();
  const { t } = useTranslation();
  return (
    <>
      <h1 className="text-[32px] font-bold">
        {t("SuperAdmin.Payments", "Płatności")}
      </h1>
      <FcTabsHeader
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        tabs={["Licencje", "Wpłaty"]}
      />

      <div className="w-full">
        <FcTabPanel selectedTab={selectedTab} index={0}>
          {/* <SuperAdminLicenses />  */}
          <SuperAdminPackageOrders />
        </FcTabPanel>

        <FcTabPanel selectedTab={selectedTab} index={1}>
          <SuperAdminCustomerFee />
        </FcTabPanel>
      </div>
    </>
  );
};
