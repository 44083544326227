import { Box } from '@mui/system'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { CompanySummary } from '../organizer/company/CompanySummary'
import { useCompany } from '../organizer/events/api/useCompanyApi'

export function SuperAdminCompanyPage () {
  const { id } = useParams<any>()
  const { company, loading, refetch } = useCompany(id ?? '')
  return (
    <Box>
      {loading ? (
        <Box width='100%' textAlign='center'>
          <LoadingSpinner />
        </Box>
      ) : (
        <>
          <CompanySummary values={company} refetch={refetch} />
        </>
      )}
    </Box>
  )
}
