import {
  DescriptionList,
  Heading3,
  InputField
} from "../../../../components/FormCommons"
import MenuItem from "@mui/material/MenuItem"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../events/api/useProductApi"
import { useFormContext } from "react-hook-form"
import {useTranslation} from "react-i18next";

export const ProductSectionAgeForm = ({
  register,
  showMaxParticipants
}: any) => {
  const { t } = useTranslation()

  const { watch } = useFormContext<any>()
  const minAge = watch("min_age")
  const maxAge = watch("max_age")

  const minParticipants = watch("min_participants")
  const maxParticipants = watch("max_participants")

  const participantsOptions = Array.from(Array(30).keys())
    .filter((n) => (minAge ? n > minAge - 2 : true))
    .map((n) => (
      <MenuItem value={n + 1} key={n}>
        {n + 1}
      </MenuItem>
    ))
  return (
    <>
      <div className="flex gap-4 flex-row">
        <div className="w-36 mr-4">
          <InputField
            type="number"
            className="w-1/2 md:w-1/4"
            label={t("product.age_from", "Od lat*")}
            {...register("min_age")}
          />
        </div>
        <div className="w-36 mr-4">
          <InputField
            className="w-1/2 md:w-1/4"
            label={t("product.age_till", "Do lat*")}
            {...register("max_age")}
          />
        </div>
      </div>
      {showMaxParticipants && (
        <>
          <div className="mt-4">
            <Heading3 id="h3-2">Ilość uczestników</Heading3>
          </div>
          <div>
            <div className="flex gap-4 flex-row">
              <div className="w-36 mr-4">
                <InputField
                  className="w-1/2 md:w-1/4"
                  select
                  label="Minimum"
                  SelectProps={{
                    defaultValue: minParticipants
                  }}
                  {...register("min_participants")}
                >
                  {participantsOptions}
                </InputField>
              </div>
              <div className="w-36 mr-4">
                <InputField
                  className="w-1/2 md:w-1/4"
                  select
                  label="Maksimum"
                  SelectProps={{
                    defaultValue: maxParticipants
                  }}
                  {...register("max_participants")}
                >
                  {participantsOptions}
                </InputField>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const ProductSectionAgePreview = ({ values }: any) => {
  return (
    <div>
      <DescriptionList
        data={{
          Wyżywienie: values.age_type,
          Opis: values.age_description
        }}
      />
    </div>
  )
}

export const ProductSectionAgeView = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()

  const saveHandler = async (data: any) => {
    var productData: any = {
      ...values,
      ...data
    }
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  return (
    <EditableFormSection
      title="Wiek Uczestników"
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionAgeForm values={values} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <ProductSectionAgePreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
