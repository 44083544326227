import {
  DescriptionList,
  InputField,
  InputCheck
} from "../../../../components/FormCommons"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../events/api/useProductApi"
import { useIntl } from "react-intl"
import { Controller } from "react-hook-form"
import { format } from "date-fns"
import {useTranslation} from "react-i18next";

export const ProductSectionSuperAdminForm = ({
  register,
  control,
  watch
}: any) => {
  const { t } = useTranslation()

  const admin_approved_at = watch("admin_approved_at")
  return (
    <>
      <Controller
        name={"admin_approved_at"}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          var val =
            value != null && value.length > 10 ? value.substr(0, 10) : value
          return (
            <InputField
              type="date"
              onChange={onChange}
              value={val}
              InputLabelProps={{ shrink: true }}
              label={t("ui.products.admin_acceptance_date", "Data akceptacji")}
              id={`admin_approved_at`}
            />
          )
        }}
      />
      <InputCheck
        control={control}
        label={t("ui.products.show_on_homepage", "Pokaż na stronie głównej")}
        name="admin_show_on_home_page"
      />
      <InputCheck
        control={control}
        label={t("ui.products.promote_on_homepage", "Promuj na stronie głównej")}
        name="admin_promote_on_home_page"
      />
      <InputField
        {...register("admin_comment")}
        InputLabelProps={{ shrink: true }}
        label={t("ui.products.admin_comments", "Uwagi administratora")}
        id={`admin_comment`}
      />
    </>
  )
}

export const ProductSectionSuperAdminPreview = ({ values }: any) => {
  const { t } = useTranslation()

  const intl = useIntl()
  return (
    <div>
        <DescriptionList
            data={{
                [t("ui.products.admin_acceptance_date", "Data Akceptacji Admina")]:
                    values.admin_approved_at ? intl.formatDate(values.admin_approved_at) : "",

                [t("ui.products.show_on_homepage", "Pokaż na stronie głównej")]:
                    values.admin_show_on_home_page ? t("ui.groups.yes", "Tak") : t("ui.groups.no", "Nie"),

                [t("ui.products.promote_on_homepage", "Promuj na stronie głównej")]:
                    values.admin_promote_on_home_page ? t("ui.groups.yes", "Tak") : t("ui.groups.no", "Nie"),

                [t("ui.products.admin_comments", "Uwagi administratora")]:
                values.admin_comment
            }}
        />
    </div>
  )
}

export const ProductSectionSuperAdminView = ({ values, refetch }: any) => {
  const { t } = useTranslation()

  const { updateProduct } = useProductApi()

  const saveHandler = async (data: any) => {
    var productData: any = {
      id: data.id,
      admin_approved_at: data.admin_approved_at,
      admin_show_on_home_page: data.admin_show_on_home_page,
      admin_promote_on_home_page: data.admin_promote_on_home_page,
      admin_comment: data.admin_comment
    }
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  //   const val
  const val = {
    ...values
    // admin_approved_at: values.admin_approved_at
    //   ? parseISO(values.admin_approved_at)
    //   : undefined,
  }
  return (
    <EditableFormSection
      title={t("ui.products.admin_options_offer", "Opcje administratora (Oferta)")}
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      styles={"shadow rounded-lg"}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionSuperAdminForm values={val} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <ProductSectionSuperAdminPreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}
