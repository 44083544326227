import {
  useParams,
  useLocation,
  useNavigate,
  resolvePath
} from 'react-router-dom'
import { Box, Button } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PlacesTable from './PlacesTable'
import { PageTitle } from '../../../components/typography'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import LivingOutlinedIcon from '@mui/icons-material/LivingOutlined'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type TabData = {
  path: string
  label?: string
  element?: React.ReactNode
  icon?: React.ReactNode
}

const tabs: TabData[] = [
  {
    path: 'place',
    label: 'Baza atrakcji',
    element: <PlacesTable type='place' />,
    icon: <MapOutlinedIcon />
  },
  {
    path: 'accommodation',
    label: 'Baza noclegów',
    element: <PlacesTable type='accommodation' />,
    icon: <LivingOutlinedIcon />
  }
]

function PlacesHeader () {
  const { t } = useTranslation()
  const { tab } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const tabIndex = tabs.findIndex((tabData: TabData) => tabData['path'] === tab)

  useEffect(() => {
    if (tabIndex < 0) navigate(resolvePath(`../place`, pathname))
  }, [])

  return (
    <Box p={4}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        my={2}
      >
        <Box flex={1}>
          <PageTitle gutterBottom>
            <div className='pl-8'>{t('ui.attractions.header')}</div>
          </PageTitle>
          <div className='pl-8'>
            {tabIndex === 0 && <>{t('ui.attractions.follow_up0')}</>}
            {tabIndex === 1 && <>{t('ui.attractions.follow_up1')}</>}
          </div>
        </Box>
      </Box>
      <div className='p-8'>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} display='flex'>
          <Box flex={1}>
            {tabIndex >= 0 && (
              <Tabs
                value={tabIndex}
                aria-label='Places tab'
                variant='scrollable'
              >
                {tabs.map(tab => (
                  <Tab
                    onClick={() =>
                      navigate(resolvePath(`../${tab['path']}`, pathname))
                    }
                    label={
                      <div className='flex justify-center items-center'>
                        <div className='mr-1'>{tab['icon']}</div>
                        {t(`ui.attractions.tab_path_${tab['path']}`, tab['label'])}
                      </div>
                    }
                    key={tab['path']}
                  />
                ))}
              </Tabs>
            )}
          </Box>
          <Box mr={1}>
            <Button
              className='mx-4 mb-2'
              variant='contained'
              color='primary'
              size='large'
              onClick={() => navigate(`/places/dodaj?type=${tab}`)}
            >
              {tabIndex === 0 ? t("ui.attractions.add_attraction", "Dodaj atrakcje") : t("ui.attractions.add_accommodation", "Dodaj nocleg")}
            </Button>
          </Box>
        </Box>
        {tabIndex >= 0 && tabs[tabIndex]['element']}
      </div>
    </Box>
  )
}

export default PlacesHeader
