import {
  FieldPath, FormProvider, get, RegisterOptions,
  useForm
} from "react-hook-form"
import {
  FormSectionSeparator,
  Heading3
} from "../../../../components/FormCommons"
import {
  ProductGeneralInfoForm,
  productGeneralInfoSchoolTripSchema
} from "./ProductGeneralInfo"

import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import { BlueBackground } from "wakacje-web/src/shared/components/BlueBackground"
import * as Yup from "yup"
import { NewProductWizardButtonsBar } from "./NewProductWizardButtonsBar"
import {
  productLocationSchema,
  SchoolTripSectionLocationForm
} from "./SchoolTripSectionLocation"

const productFirstPageSchema = Yup.object({
  ...productGeneralInfoSchoolTripSchema,
  ...productLocationSchema
})

export const NewSchoolTripPageDetails = ({
  values,
  onCancel,
  onSubmit,
  isSubmitting
}: any) => {
  const { t } = useTranslation()

  var defaultValues = values
    ? {
        ...values
      }
    : {}

  const formMethods = useForm<any>({
    mode: "onTouched",
    resolver: yupResolver(productFirstPageSchema),
    defaultValues
  })

  const {
    handleSubmit,
    formState: { errors, isValid }
  } = formMethods

  const register = (name: FieldPath<any>, options?: RegisterOptions) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false))
  })

  function onFormSubmit(data: any) {
    onSubmit(data)
  }

  //)

  return (
    <section>
      <FormProvider {...formMethods}>
        <form className="my-8" onSubmit={handleSubmit(onFormSubmit)}>
          <BlueBackground>
            <Heading3 id="h3-1">{t("Booking.Description", "Opis")}</Heading3>
          </BlueBackground>
          <ProductGeneralInfoForm
            register={register}
            values={values}
            errors={errors}
          />

          <BlueBackground>
            <Heading3 id="h3-1">Lokalizacja</Heading3>
          </BlueBackground>

          <SchoolTripSectionLocationForm
            register={register}
            values={values}
            watch={formMethods.watch}
            errors={errors}
          />
          <FormSectionSeparator />

          <NewProductWizardButtonsBar
            firstStep
            onCancel={onCancel}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </section>
  )
}
