import axios from 'axios'
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'

export enum PaymentStep {
  IDLE,
  GETTING_LINK,
  LINK_RECEIVED
}

export interface PaymentContextType {
  step: PaymentStep
  initPayment: Function
  payment?: any
  showWindow?: boolean
  closePopup: Function
  popupBlocked?: boolean
}

export const PaymentContext = createContext<PaymentContextType>({
  step: PaymentStep.IDLE,
  initPayment: doc => {},
  closePopup: () => {}
})

const initialState = {
  step: PaymentStep.IDLE
}

function reducer (state, action) {
  switch (action.type) {
    case 'gettingLink':
      return {
        ...state,
        step: PaymentStep.GETTING_LINK,
        showWindow: true,
        merchant: action.payload.merchant
      }
    case 'linkReceived':
      return {
        ...state,
        step: PaymentStep.LINK_RECEIVED,
        showWindow: true,
        payment: action.payload.payment
      }
    case 'closePopup': {
      return {
        ...state,
        showWindow: false
      }
    }
    default:
      throw new Error()
  }
}

export const PaymentContextProvider = ({
  children,
  rootUrl,
  accessToken,
  enqueueSnackbar
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [popupBlocked, setPopupBlocked] = useState(false)
  const [popupBlockedLink, setPopupBlockedLink] = useState<any>()
  const initialize = async () => {}

  useEffect(() => {
    initialize()
  }, [])

  //   const getPaymentLink = async ({ amount, description }) => {
  //     const order = {
  //       amount: 12350,
  //       description: "Abonament MojeUrodziny.pl",
  //       email: authContext.cognitoUser.attributes.email,
  //       merchantId: merchants[0].id,
  //       type: "MERCHANT_SUBSCRIPTION",
  //       isRecurrent: true
  //     }

  //     setIsGettingLink(true)
  //     try {
  //       const url = `${process.env.REACT_APP_API_URL}/v1/payments/initMerchantPayment`
  //       const res = await axios({ url, method: "POST", data: order })
  //
  //       if (res.status === 200 && res.data?.link) {
  //         setIsGettingLink(false)
  //         setPendingTransactionToken(res.data.token)
  //         window.open(res.data?.link, "_blank").focus()
  //       }
  //     } catch (err) {
  //       setIsGettingLink(false)
  //     }
  //   }

  const initPayment = async paymentRequest => {
    setPopupBlocked(false)

    const url = `${rootUrl}/v1/payments/new`

    const command = {
      aggregateId: 'new',
      type: 'Payment.Init',
      payload: {
        ...paymentRequest
      }
    }
    try {
      dispatch({
        type: 'gettingLink',
        payload: {}
      })
      var token =
        accessToken && typeof accessToken === 'function'
          ? await accessToken()
          : accessToken
      const res = await axios({
        url,
        method: 'POST',
        data: { command },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (res.status === 200 && res.data) {
        const payment = res.data
        dispatch({
          type: 'linkReceived',
          payload: {
            payment
          }
        })
        // setIsGettingLink(false)
        // setPendingTransactionToken(payment.token)
        var w = window.open(payment.url, '_self') // 2023-01-29 replaced _blank with _self
        // if w is null then the popup was blocked
        setPopupBlockedLink(payment.url)
        if (w) {
          setPopupBlocked(false)
          w.focus()
        } else {
          setPopupBlocked(true)
        }
        //
        return payment
      }
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Nie udało się utworzyć linku do płatności')
      //   , {
      //     message: (err as any).response?.data?.message
      //   })
    }
    // const link = await getPaymentLink({amount: document.amount, })
  }

  const closePopup = () =>
    dispatch({
      type: 'closePopup'
    })

  const contextValue: PaymentContextType = {
    step: state.step,
    payment: state.payment,
    showWindow: state.showWindow,
    initPayment,
    closePopup,
    popupBlocked
  }
  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  )
}

export const usePaymentContext = (): PaymentContextType =>
  useContext(PaymentContext) as PaymentContextType
